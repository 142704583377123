<mat-card class="bg-transparent" style="width: 320px; height: 300px;">
  <div (click)="viewTopic();" class="div-card-pages border-radius-10 cursor-pointer">
    <!--cover image-->
    <img [src]="topic_object.thumbnail ? topic_object.thumbnail : 'assets/images/Topics_Cover_Image.png'"
         [style.object-position]="topic_object.object_position" class="w-100"
         style="object-fit: cover; aspect-ratio: 50/9; border-top-right-radius: 10px;border-top-left-radius: 10px;">

    <!--loader 3 line-->
    <div class="skeleton-loader">
      <div *ngIf="show_language_label" class="video-block-duration" style="left: 80%;z-index: 1;">
        <mat-icon class="h-auto w-auto mr-1" style="font-size: initial;">language</mat-icon>
        <span>{{ topic_object.language?.slice(0, 2).toUpperCase() }}</span>
      </div>

      <div class="line"></div>
      <div class="line"></div>
      <div class="line"></div>
    </div>

    <!--user image-->
    <div class="iconoverlay">
      <div class="page-avatar">
        <div [style.background-image]="'url(' + topic_object.uploader.userprofile.profile_pic + ')'"
             [title]="topic_object.uploader.email" mat-card-avatar
             matTooltip="{{ topic_object.uploader.first_name }} {{ topic_object.uploader.last_name }}"
             matTooltipPosition="right" style="background-size: cover;">
        </div>
      </div>
    </div>

    <!--emoji box-->
    <div class="emoji-box">
      <span style="font-size: 28px;">{{ topic_object.emoji }}</span>
    </div>
  </div>

  <mat-card-footer>
    <mat-card-subtitle [title]="topic_object.title" class="title fontStyle">
      <mat-icon *ngIf="topic_object.is_featured" class="material-icons-outlined"
                style="color: gold; vertical-align: middle;">star
      </mat-icon>
      {{ topic_object.title }}
    </mat-card-subtitle>
    <mat-card-subtitle class="text-nunito" style="font-size: 16px;">
      <span class="badge mr-1 text-nunito font-medium"
            hidden
            style="background-color: #F8F8F8; color: #7A7A7D;border: 2px solid #e0dfdf;" translate>
        {{ topic_object.n_sections }}
        <span>
          {{ topic_object.n_sections > 1 ? ('sections' | translate) : ('section' | translate) }}
        </span>
      </span>
      <span title="{{'Erstellt am'|translate}} {{topic_object.added_on | date: 'dd.MM.yyyy'}}">
        {{ 'Updated' | translate }}
        {{ utilityService.timeSince(topic_object.last_edited_on, "ago", false) |async }}
      </span>
      <span style="vertical-align: middle; height: 30px;">
        <mat-icon *ngIf="topic_object.is_held_for_review" mat-list-icon title="{{ 'Wird überprüft' | translate }}">
          pending_actions
        </mat-icon>
        <mat-icon *ngIf="topic_object.is_external && !topic_object.is_access_key_needed" mat-list-icon
                  title="{{'Public' | translate}}">
          public
        </mat-icon>
        <mat-icon *ngIf="topic_object.is_external && topic_object.is_access_key_needed" mat-list-icon
                  title="{{'Unlisted' | translate}}">
          vpn_lock
        </mat-icon>
        <mat-icon *ngIf="topic_object.is_restricted" mat-list-icon
                  title="{{ 'Restricted visibility inside the workspace' | translate }}">
          network_locked
        </mat-icon>
      </span>
    </mat-card-subtitle>
  </mat-card-footer>
</mat-card>
