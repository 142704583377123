import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {MatSnackBar} from "@angular/material/snack-bar";
import {DataService} from "../services/data.service";
import {ActivatedRoute, Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {AuthService} from "../services/auth.service";
import {TopicView, Section, Topic, MiniDetails} from '../models/video/video.interface';
import {environment} from "../../environments/environment";
import {CdkDragDrop, CdkDragMove, moveItemInArray} from '@angular/cdk/drag-drop';
import {Observable, Subscription} from 'rxjs';
import {HttpErrorResponse,} from "@angular/common/http";
import {QuillModules} from "ngx-quill/lib/quill-editor.interfaces";
import * as mime from "mime";
import {MatDialog} from "@angular/material/dialog";
import {SelectVideoPopupComponent} from "../select-video-popup/select-video-popup.component";
import {ContentChange, QuillEditorComponent, SelectionChange} from "ngx-quill";
import {PopupData, SharePopupComponent} from "../shared/share-popup/share-popup.component";
import { map, startWith } from 'rxjs/operators';
import { COMMA } from '@angular/cdk/keycodes';
import { FormControl } from '@angular/forms';
import {SelectOnedrivePopupComponent} from "../select-onedrive-popup/select-onedrive-popup.component";
import {
  TopicTranslationPopupComponent,
  TopicTranslationsPopupData
} from "./topic-translation-popup/topic-translation-popup.component";
import {TopicTranslation} from "../models/topic.interface";
import {NavbarService} from "../services/navbar.service";
import {CompletionPopupComponent} from "../completion-popup/completion-popup.component";
import { QuizzComponent } from "./quizz-popup/quiz-popup.component";
import {Question} from "../models/question_answer_option";

interface SectionEditor extends Section {
  changed: boolean;
}


@Component({
  selector: 'app-edit-topics',
  templateUrl: './edit-topics.component.html',
  styleUrls: ['./edit-topics.component.scss']
})
export class EditTopicsComponent implements OnInit {

  topic_id: string = "";
  is_title_editor_visible: boolean = false;
  topic_obj: TopicView = null;
  title: string = "";
  sections: SectionEditor[] = [];
  questions: Question[] = [];
  is_published: boolean = false;
  quillConfig: QuillModules = {};
  showEmojiPicker: boolean = false;
  emoji: string = '😀';
  routeSubscription: Subscription = null;
  snackbarConfig = {duration: 2000};
  is_external_sharing_disabled: boolean = true;
  is_viewer_only: boolean = true;  // this block controls the fields if user has a creator license or not
  enableDrag: boolean = false;
  object_position: string = '50% 50%';
  primary_color: string = "black";
  auto_save_interval: any = null;  // save all sections every 5 seconds
  currently_selected_section_id = -1;  // keep track of which section has cursor
  current_cursor_position = -1;  // keep track of where to insert link

  constructor(
    private snackBar: MatSnackBar,
    private dataService: DataService,
    private route: ActivatedRoute,
    private navbarService: NavbarService,
    private router: Router,
    private translateService: TranslateService,
    public authService: AuthService,
    private dialog: MatDialog,
  ) {
    this.routeSubscription = this.route.paramMap.subscribe(async (map) => {
      this.topic_id = map.get('id');
    });

    this.quillConfig = {
      toolbar: {
        container: [
          ['bold', 'italic', 'underline', 'strike', 'link'],        // toggled buttons
          ['blockquote', 'code-block'],
          [{'header': 1}, {'header': 2}],               // custom button values
          [{'list': 'ordered'}, {'list': 'bullet'}],
          [{'color': []}, {'background': []}],          // dropdown with defaults from theme
          [{'align': []}],                                       // remove formatting button
          ['clean'],  // remove formatting
        ],
      }
    };
  }

  separatorKeysCodes: number[] = [COMMA];
  tagCtrl = new FormControl('');
  selected_tags: MiniDetails[] = [];
  filtered_tags: Observable<MiniDetails[]> = null;
  all_tags: MiniDetails[] = [];
  translations_local_copy: TopicTranslation[] = [];
  @ViewChild('tagInput') tag_input_element: ElementRef<HTMLInputElement>;

  ngOnInit(): void {
    this.loadTopic();

    this.primary_color = localStorage.getItem('primary_color');

    // initiate observer after a second
    const timeout = setTimeout(() => {
      this.is_viewer_only = !this.authService.userDetails.is_creator;
      this.is_external_sharing_disabled = this.authService.company.is_external_sharing_disabled;
      clearTimeout(timeout);
    }, 500);

    // initiate an interval which saves content every 5 seconds
    this.auto_save_interval = setInterval(() => {
      this.doneEditing(false);
    }, (5*1000));

    // ensure that user does not refresh w/o saving
    window.addEventListener('beforeunload', (event) => {
      const pending_sections = this.sections.filter(s => s.changed).length;
      clearInterval(this.auto_save_interval);
      if (pending_sections > 0) {
        event.returnValue = "Sure?";
      }
    });
  }

  // when user pastes something in a section, check if it is a file
  filePastedInSection(section: SectionEditor, event: ClipboardEvent){
    // check when user pastes something
    // insert text if any
    const pasted_text: string = event.clipboardData.getData('text/plain');
    if (pasted_text.length) {
      // simplay paste it
    } else if(event.clipboardData.files.length > 0) {
      // if there is no text but there are files
      event.preventDefault();
      // get section id
      if (section.attachment){
        // tell user
        const message = this.translateService.instant("Please add this file in a new section");
        this.snackBar.open(message, '', this.snackbarConfig);
      } else {
        // attach file
        this.attachFileToSection(section.id, event.clipboardData.files.item(0));
      }
    }
  }

  // save all sections if needed
  doneEditing(navigate: boolean = true){
    const changedSections = this.sections.filter(s => s.changed);
    for (let section of changedSections) {
      this.saveSectionContent(section);
    }
    // todo: only go after all save calls are performed
    if (navigate) {
      this.router.navigate(['pages', 'view', this.topic_id]);
    }
  }

  loadTopic(): void {
    // load the topic
    this.dataService.getURL(`user/topics/${this.topic_id}/`, {
      observe: 'body',
      responseType: 'json'
    }).subscribe((res: TopicView) => {
      this.topic_obj = res;
      this.object_position = this.topic_obj.object_position;
      this.is_published = !res.is_draft;
      this.title = res.title;
      this.emoji = res.emoji;
      this.translations_local_copy = res.translations;
      // if user is not topic creator, take them back!
      if (res.uploader.id != this.authService.userDetails.user) {
        // todo test
        window.alert('Unauthorised');
        this.router.navigate(['pages', 'view', this.topic_id]);
        return;
      }

      // download tag data
      this.initiateTags();

      // sort sections
      let currentId: number | null = null;
      if (res.sections.length) {
        while (true) {
          const prev_section = res.sections.find((item) => item.next_section === currentId);
          if (!prev_section) {
            // no prev section found, this is the first one
            break;
          }
          this.sections.unshift({
            ...prev_section,
            changed: false,
          });
          currentId = prev_section.id;
        }
        this.loadQuestions();
      }
    }, (err: HttpErrorResponse) => {
      console.error(err);
      window.alert(err.error);
    });
  }

  // clear the ques array and load questions
  loadQuestions() {
    this.dataService.getURL(`user/questions/?topic_id=${this.topic_id}`, {
      observe: 'body',
      responseType: 'json'
    }).subscribe((res: Question[]) => {
      this.questions = [];
      this.questions = res;
    });
  }

  // this method is used in html to check if there is a ques or not
  doesQuestionExist(section_id: number): boolean {
    if (this.questions.find(e => e.section === section_id)) {
      // found
      return true;
    }
    // else, undefined
    return false;
  }

  // save the title and hide it
  hideTitleEditor(save: boolean = true, event: KeyboardEvent = null) {
    if (event) {
      event.preventDefault();
    }
    this.is_title_editor_visible = false;
    if (!save) {
      // revert changes
      this.title = this.topic_obj.title;
      return;
    }

    this.title = this.title.trim();

    if (this.title.length == 0) {
      // empty
      window.alert(this.translateService.instant("Please provide a title"));
      this.title = this.topic_obj.title;
      return;
    }

    if (this.title == this.topic_obj.title) {
      // no changes
      return;
    } else {
      // save it
      const form = {
        title: this.title
      };
      this.saveTopic(form);
    }
  }

  // performs PUT call
  saveTopic(form: any) {
    this.dataService.putURL(`user/topics/${this.topic_id}/`, form,
      {observe: 'body', responseType: 'json'}).subscribe((res: TopicView) => {
      this.topic_obj = res;
      this.is_published = !res.is_draft;
      this.snackBar.open(this.translateService.instant('Gespeichert'), '', this.snackbarConfig);
    }, (err: HttpErrorResponse) => {
      console.error(err);
      // todo: revert changes
      if(err.status == 0){
        // no internet
        this.snackBar.open('No Internet', '', this.snackbarConfig)
      } else {
        this.snackBar.open(err.error, '', this.snackbarConfig)
      }
    });
  }

  addSection() {
    this.dataService.postURL(`user/topics/${this.topic_id}/`, {content: ""},
      {observe: 'body', responseType: 'json'}).subscribe((res: Section) => {
      // find last one and update it
      const i = this.sections.findIndex(e => e.next_section == null);
      if (i > -1) {
        this.sections[i].next_section = res.id;
      }
      // now push this one
      this.sections.push({
        ...res,
        changed: false,
      });
      this.snackBar.open(
        this.translateService.instant('Hinzugefügt'),
        this.translateService.instant('Undo'),
        {duration: 2000, horizontalPosition: 'left'}).onAction().subscribe(() => {
        // delete the section
        this.deleteSection(res.id);
      });
      // scroll to add button after some time
      const timeout = setTimeout(() => {
        this.scrollToItem(res.id);
        clearTimeout(timeout);
      }, 500);
    }, (err: HttpErrorResponse) => {
      this.snackBar.open(err.error, '', this.snackbarConfig)
      console.error(err);
    });
  }

  addClypp(section: SectionEditor, quill1: QuillEditorComponent) {
    // quill1 is passed as html component, needed to figure out the index of current selection
    // open select clypp popup and embed as iframe
    this.dialog.open(SelectVideoPopupComponent, {
      maxWidth: "700px",
      width: "50%",
      minWidth: "350px",
      disableClose: false,
    }).afterClosed().subscribe(res => {
      if (res) {
        // res is a video id, create iframe here
        const embed_link = environment.baseURL + 'embed/video/' + res.id;
        const view_link = environment.baseURL + 'publicVideoView/' + res.id;
        // const block = `<a href="${view_link}" target="_blank">${res.title}</a>` +
        //   `<iframe allowfullscreen src="${embed_link}" ` +
        //   `title="${res.title}" `+
        //   `style="aspect-ratio: 16/9;width: 100%; min-width: 200px;"></iframe><br>`;
        // section.content += block;

        const current_index: number = quill1.quillEditor.selection.savedRange.index;
        // first insert embed
        // https://quilljs.com/docs/api#insertembed
        quill1.quillEditor.insertEmbed(current_index, 'video', embed_link, 'silent');
        // https://quilljs.com/docs/api#inserttext
        // then at the same index, insert the link
        quill1.quillEditor.insertText(current_index, res.title, {link: view_link}, 'silent');
        // then at the same index, insert a new line
        if (current_index > 0) {
          // only if it is not empty
          quill1.quillEditor.insertText(current_index, '\n', 'silent');
        }

        section.content = quill1.quillEditor.root.innerHTML;
        section.changed = true;
      }
    });
  }

  predictSection(section: SectionEditor, type: string) {
    const system_prompt: string = this.translateService.instant("Generate with AI");
    let user_prompt: string = section.content as string;
    let assistant_prompt: string = this.title;
    let auto_close: boolean = true;

    // for assistant prompt, we try to find previous section's content
    const current_index = this.sections.findIndex(e => e.id == section.id);
    if (current_index > 0) {
      // this is not the 0th section
      assistant_prompt = this.sections[current_index-1].content as string;
    }

    switch (type) {
      case 'predict':
        // include current section's existing content
        assistant_prompt += user_prompt;
        // clear user prompt and allow them to write more
        user_prompt = '';
        auto_close = false;
        break;
      case 'ideas':
        // include current section's existing content
        assistant_prompt += user_prompt;
        // clear user prompt and allow them to write more
        user_prompt = this.translateService.instant("Brainstorm ideas on");
        auto_close = false;
        break;
      case 'agenda':
        // include current section's existing content
        assistant_prompt += user_prompt;
        // clear user prompt and allow them to write more
        user_prompt = this.translateService.instant("Generate an agenda for");
        auto_close = false;
        break;
      case 'list':
        // include current section's existing content
        assistant_prompt += user_prompt;
        // clear user prompt and allow them to write more
        user_prompt = this.translateService.instant("Create a list about");
        auto_close = false;
        break;
      case 'improve':
        assistant_prompt = user_prompt;
        user_prompt = this.translateService.instant("Convert this input into language that is easy to understand in ");
        user_prompt += this.topic_obj.language;
        break;
      case 'compact':
        assistant_prompt = user_prompt;
        user_prompt = this.translateService.instant("Make this input more concise in ");
        user_prompt += this.topic_obj.language;
        break;
      case 'summary':
        assistant_prompt = user_prompt;
        user_prompt = this.translateService.instant("Summarize it in ");
        user_prompt += this.topic_obj.language;
        break;

      default:
        return;
    }

    // open AI popup
    this.dialog.open(CompletionPopupComponent, {
      width: '70vw',
      minWidth: '400px',
      maxWidth: '700px',
      maxHeight: '90vh',
      autoFocus: true,
      disableClose: false,
      hasBackdrop: true,
      data: {
        system: system_prompt,
        user: user_prompt,
        assistant: assistant_prompt,
        auto_close: auto_close,
        file_search: null,
      }
    }).afterClosed().subscribe(res => {
      // this boolean will tell if we need to reload the profiles
      if (res) {
        section.changed = true;
        // append to content
        const res_html = res.replace(/\n/g, "<br>");
        section.content += `<p>${res_html}</p>`;
      }
    });
  }

  // open one drive popup, login is handled there itself
  openOneDrivePopup(section: SectionEditor) {
    // popup returns iframe code, just append it to the end
    const dialogRef = this.dialog.open(SelectOnedrivePopupComponent, {
      width: '80%'  // max width is 80vw
    });
    dialogRef.afterClosed().subscribe((res: string) => {
      if (res) {
        // embed it
        section.content += res;
        section.changed = true;
      }
    });
  }

  openQuizPopup(question: Question) {
    this.dialog.open(QuizzComponent, {
      width: "70%",
      minWidth: 400,
      maxWidth: 1000,
      disableClose: true,
      data: question,  // pass the reference so that changes there are reflected here too
    }).afterClosed().subscribe((res: Question | undefined) => {
       if (res) {
        // find and update array
        this.questions.splice(this.questions.findIndex(x => x.id == question.id), 1);
        setTimeout(() => this.questions.push(res), 200);
        // reload view-question component as options may change
      } else if (res === null) {
         // delete question
         // user has deleted the question already, remove it from the array
         this.questions.splice(this.questions.findIndex(x => x.id == question.id), 1);
      } else {
        // undefined
        // do nothing, user clicked on backdrop
      }
    });
  }


  // for html, get the ques
  getQuestion(section_id: number): Question {
    return this.questions.find(e => e.section == section_id);
  }

  // add a new question
  addQuizPopup(section: number, type: 'SS'|'MS'|'ST'|'LT'|'FU') {
    // step 1: send a post call
    const title: string = this.translateService.instant("Please provide a title");
    this.dataService.postURL('user/questions/', {title, type, section}, {
      observe: 'body',
      responseType: 'json'
    }).subscribe((quesRes: Question) => {
      if (['SS', 'MS'].includes(type)) {
        // add two options, one by one
        let request = {
          title: 'Option 1',
          reason: '',
          is_correct: true,
        };
        this.dataService.postURL(`user/${quesRes.id}/options/`, request, {
          responseType: 'json',
          observe: 'body'
        }).subscribe(resp => {
          // add another one
          request['title'] = 'Option 2';
          request['is_correct'] = false;
          this.dataService.postURL(`user/${quesRes.id}/options/`, request, {
            responseType: 'json',
            observe: 'body'
          }).subscribe(resp => {
            this.questions.unshift(quesRes);
            this.openQuizPopup(this.questions[0]);
          });  // end of option call 2
        });  // end of option call 1
      } else {
        this.questions.unshift(quesRes);
        this.openQuizPopup(this.questions[0]);
      }
    }, (err) => {
      console.error(err);
      this.snackBar.open(this.translateService.instant('Ein Fehler ist aufgetreten'), '', this.snackbarConfig);
    });  // end of question call
  }

  // insert any https link as ql video
  insertLink(section: SectionEditor, quill1: QuillEditorComponent, link: string = '') {
    // if link is provided, then don't ask for input
    let message = this.translateService.instant("Please provide an embed link:");
    message += '\n\n';
    // not an iframe, just a simple link
    message += this.translateService.instant("Example: ");
    message += "https://clypp.app/embed/video/…";
    if (link == '') {
      // if link is not provided, then ask user
      link = window.prompt(message);
    }
    if (link) {
      // not undefined
      link = link.trim();
      if (!link.startsWith('https://')) {
        window.alert(this.translateService.instant('The link should start with https://'));
        return;
      }
      // now embed it at particular index
      const current_index: number = quill1.quillEditor.selection.savedRange.index;
      // first insert embed
      // https://quilljs.com/docs/api#insertembed
      quill1.quillEditor.insertEmbed(current_index, 'video', link, 'silent');
      // https://quilljs.com/docs/api#inserttext
      // then at the same index, insert the link
      quill1.quillEditor.insertText(current_index, 'Embed:', {link: link}, 'silent');
      // then at the same index, insert a new line
      if (current_index > 0) {
        quill1.quillEditor.insertText(current_index, '\n', 'silent');
      }

      section.content = quill1.quillEditor.root.innerHTML;
      section.changed = true;
    }
  }

  insertIframe(section: SectionEditor, quill1: QuillEditorComponent) {
    let message = this.translateService.instant("Please provide an embed code:");
    message += '\n\n';
    // user is proving with iframe
    message += this.translateService.instant("Example: ");
    message += "<iframe>…</iframe>"

    let input = window.prompt(message);
    if (input) {
      // not undefined
      input = input.trim();

      // iframe, check that src must start with https://
      if (!input.startsWith('<iframe ')) {
        window.alert(this.translateService.instant("The content should start with <iframe …"));
        return;
      }
      if (input.includes('srcdoc="')) {
        window.alert("Please provide an iframe with src, not srcdoc");
        return;
      }
      const src_index = input.indexOf(' src="');
      const src_start_index: number = input.indexOf('"', src_index);
      const src_end_index: number = input.indexOf('"', src_start_index+1);
      const link = input.slice(src_start_index+1, src_end_index);
      console.log(link);
      this.insertLink(section, quill1, link);
    }
  }

  getAttachedFilename(url) {
    let attachFileName = url.split('/').pop();
    return attachFileName.split("?")[0];
  }

  sectionDropped(event: CdkDragDrop<string[]>) {
    if (event.previousIndex == event.currentIndex) {
      // no need to do anything
      return;
    }

    moveItemInArray(this.sections, event.previousIndex, event.currentIndex);

    // now that the order is as expected, update all section in the array
    for (let i = 0; i < this.sections.length - 1; i++) {
      // go from first to second last
      this.sections[i].next_section = this.sections[i+1].id;
    }
    // update last one
    this.sections[this.sections.length-1].next_section = null;

    // let nextSection = null;
    // if (event.currentIndex < this.sections.length - 1) {
    //     nextSection = this.sections[event.currentIndex + 1].id;
    // }
    // now send the data to BE
    const current_section = this.sections[event.currentIndex];
    this.dataService.patchURL(`user/sections/${current_section.id}/`,
      {next_section: current_section.next_section},
      {observe: 'body', responseType: 'json'}).subscribe((res: any) => {
        // res is just status code, without body
        this.snackBar.open(this.translateService.instant('Erfolgreich'), '',
          {duration: 2000, horizontalPosition: 'right'});
    }, (err) => {
        console.error(err);
        this.snackBar.open(this.translateService.instant('Ein Fehler ist aufgetreten'), '', this.snackbarConfig);
    });
  }

  // delete a section
  deleteSection(id: number) {
    let message = this.translateService.instant("Bist du sicher?");
    if (window.confirm(message)) {
      this.dataService.deleteURL<any>(`user/sections/${id}/`, {observe: 'body'})
        .subscribe((res) => {
          // success
          const index = this.sections.findIndex(e => e.id == id);
          // deleting section is not enough, we have to update next sections too!
          if (index == 0) {
            // fist section, no action needed
          } else if (index + 1 == this.sections.length) {
            // last section, make second last one null
            this.sections[index - 1].next_section = null;
          } else {
            // in between section, update the number
            this.sections[index - 1].next_section = this.sections[index + 1].id;
          }
          this.sections.splice(index, 1);
          this.snackBar.open(this.translateService.instant('Gelöscht'), '',
            {duration: 2000, horizontalPosition: 'left'});
        }, (err) => {
          // failed to delete, show alert
          console.error(err);
        });
    }
  }

  // this method tracks changes when quill content is changed
  contentChanged(section: SectionEditor, event: ContentChange){
    try {
      if(event.source == 'api') {
        // problem: on loading, quill editor always fire this event
        // do not show save button
      } else {
        // event.editor.insertText(0, 'a', 'api')
        // event.editor.scrollSelectionIntoView();
        // show save button
        section.changed = true;
      }
    } catch {
      // show save button
      section.changed = true;
    }
  }

  // this method adds/removes a border to quill editor
  showHideSectionBorder(section_id: number, focus: boolean){
    if (focus) {
      // selected
      document.getElementById(`section-div-${section_id}`).style.borderColor = this.primary_color;
      this.currently_selected_section_id = section_id;
    } else {
      // blur
      document.getElementById(`section-div-${section_id}`).style.borderColor = 'lightgray';
    }
  }

  updateCursorPosition(event: SelectionChange) {
    if (event.range) {
      this.current_cursor_position = event.range.index;
    }
  }

  saveSectionContent(section: SectionEditor) {
    // perform the save call
    section.changed = false;

    if (section.content == null) {
      // content can be empty, but not null
      section.content = "";
    }
    // check if section content is over set limit (2MB)
    const temp_string: string = section.content as string;
    if (temp_string.length > environment.bodySize) {
      // can not refer directly, as it is also a safeHtml
      const message = this.translateService.instant("The provided input is too large. Try deleting some content.");
      const action = this.translateService.instant('View');
      const snackbarRef = this.snackBar.open(message, action);
      snackbarRef.onAction()
        .subscribe(() => {
          this.scrollToItem(section.id);
          snackbarRef.dismiss();
        });
      return;
    }
    const form_body = {
      content: encodeURIComponent(section.content as string)  // BE will now decode content before saving
    }
    this.saveSectionCall(section.id, form_body);
  }

  getTableOfContentLabel(section: SectionEditor, n_characters: number = 20) {
    if (section.content == null) {
      // content becomes null when clearing all content
      section.content = "";
    }

    if (section.content != '') {
      // section is not empty
      const divElement = document.createElement('div');
      divElement.innerHTML = section.content as string;

      let name: string = divElement.innerText;
      name = name.trim();

      if (name.length == 0) {
        // check if it has title from an iframe
        if (divElement.innerHTML.includes('title=')) {
          name = divElement.innerHTML.split('title="')[1]?.split('"')[0];
        } else {
          // no innerText, no title, probably empty
          name = this.translateService.instant('Empty section');
        }
      }
      divElement.remove();
      if (name.length > n_characters) {
        name = name.substring(0, n_characters) + '…';
      }
      return name;
    } else if (section.attachment) {
      let attachFileName = section.attachment.split('/').pop();
      return attachFileName.split("?")[0].substring(0, n_characters);
    } else {
      return this.translateService.instant('Empty section');
    }
  }

  scrollToItem(id: number) {
    const element = document.getElementById(`section-div-${id}`);
    element.scrollIntoView({behavior: 'smooth'});
  }

  ngOnDestroy() {
    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
    }
    // this.doneEditing(false);
    clearInterval(this.auto_save_interval);
  }

  coverFileSelected(event) {
    let formData = new FormData();
    let thumbnail = event.target.files[0];

    if (thumbnail.size > 2000000) {
      const message = this.translateService.instant("Limit Exceeded: ") +
        `${Math.floor(thumbnail.size / 1000000)} MB / 2 MB`;
      window.alert(message);
      return;
    }
    // check for file ext
    // take the last 99 characters of file name, because backend limit is 100 characters
    let file_name = thumbnail.name.slice(-99);
    let validExtension: Array<string> = ['jpg', 'png', 'jpeg'];
    const fileExtension = file_name.split('.').pop().toLowerCase();
    if (validExtension.indexOf(fileExtension) == -1) {
      this.snackBar.open(this.translateService.instant('File not supported'), '', this.snackbarConfig);
      return;
    }

    // add the data to the form
    formData.append("thumbnail", thumbnail, file_name);
    this.saveTopic(formData);
  }

  deleteAttachment(section: SectionEditor) {
    let message = this.translateService.instant("Bist du sicher?");
    if (window.confirm(message)) {
      section.attachment = null;
      this.saveSectionCall(section.id, {attachment: null});
    }
  }

  sectionFileSelected(section_id: number, event) {
    let file_obj = event.target.files[0];
    this.attachFileToSection(section_id, file_obj);
  }

  attachFileToSection(section_id: number, file_obj: File) {
    let formData = new FormData();
    // depending upon plan size should vary
    let max_file_size_bytes = 2000000;  // 2 MB for free user
    let max_file_size_string = '2 MB';
    switch (this.authService.company.max_upload_video_height) {
      case '1440':
        max_file_size_bytes = 10000000;
        max_file_size_string = '10 MB';
        break;
      case '1080':
        max_file_size_bytes = 5000000;
        max_file_size_string = '5 MB';
        break;
      case '720':
        max_file_size_bytes = 2000000;
        max_file_size_string = '2 MB';
        break;
      default:
        max_file_size_bytes = 2000000;
        max_file_size_string = '2 MB';
    }

    if (file_obj.size > max_file_size_bytes) {
      const message = this.translateService.instant("Limit Exceeded: ") +
        `${Math.floor(file_obj.size / 1000000)} MB / ${max_file_size_string}`;
      window.alert(message);
      return;
    }
    // check for file ext
    // take the last 99 characters of file name, because backend limit is 100 characters
    let file_name = file_obj.name.slice(-99);
    // do not check for valid options as application gateway firewall will block it
    // add the data to the form
    formData.append("attachment", file_obj, file_name);
    this.saveSectionCall(section_id, formData, true);

    // ask user if they additionally want to summarize the file?
    if (this.authService.company.is_transcription_service_enabled) {
      if (file_obj.type.includes('pdf') || file_obj.type.includes('image')) {
        const confirm_message = this.translateService.instant("Would you like to summarize this file?");
        // show snackbar
        this.snackBar.open(confirm_message, this.translateService.instant('Ja'),
          {duration: 5000, horizontalPosition: 'left'}).onAction().subscribe(() => {
          // summarize the image/pdf
          let system = this.translateService.instant("Complete the tasks based on the file provided to you in the original language");
          let user = this.translateService.instant("Briefly explain each page in this file");
          if (file_obj.type.includes('image')) {
            user = this.translateService.instant("Briefly explain this image");
            system = this.translateService.instant("Complete the tasks based on the image provided to you");
          }

          // open AI popup
          this.dialog.open(CompletionPopupComponent, {
            width: '70vw',
            minWidth: '400px',
            maxWidth: '700px',
            maxHeight: '90vh',
            autoFocus: false,
            disableClose: true,
            hasBackdrop: true,
            data: {
              system: system,
              user: user,
              assistant: "",
              auto_close: true,
              file_search: file_obj,
            }
          }).afterClosed().subscribe(res => {
            // this boolean will tell if we need to reload the profiles
            if (res) {
              // find section
              const current_section = this.sections.find(e => e.id == section_id);
              current_section.changed = true;
              // append to content
              const res_html = res.replace(/\n/g, "<br>");
              current_section.content += `<p>${res_html}</p>`;
            }
          });
        });
      }
    }
  }

  // this called when: adding attachment, deleting attachment and saving section content
  saveSectionCall(section_id: number, form: any, update_attachment: boolean = false) {
    // show progress bar
    document.getElementById(`progress-bar-${section_id}`).hidden = false;
    // put call to save content, add/remove attachment
    this.dataService.putURL(`user/sections/${section_id}/`, form,
      {observe: 'body', responseType: 'json'}).subscribe((res: Section) => {
      // also update topic's updated on time
      this.topic_obj.last_edited_on = new Date().toString();
      // in case of attachment, update section
      if (update_attachment) {
        this.sections.find(e => e.id == section_id).attachment = res.attachment;
      }
    }, (err: HttpErrorResponse) => {
      // todo: revert changes
      console.error(err);
      if (err.status == 0) {
        // no internet
        this.snackBar.open('No Internet', '', this.snackbarConfig);
      } else if (err.status == 403) {
        // WAF rejected the body
        const message = this.translateService.instant("The provided input is too large. Try deleting some content.");
        this.snackBar.open(message, '', this.snackbarConfig);
      } else {
        this.snackBar.open(err.error, '', this.snackbarConfig);
      }
    }, () => {
      // hide progress bar
      document.getElementById(`progress-bar-${section_id}`).hidden = true;
    });
  }

  toggleEmojiPicker() {
    this.showEmojiPicker = !this.showEmojiPicker;
  }

  addEmoji(emoji: string) {
    if(emoji.length>4){
      const message = this.translateService.instant("Please keep it short");
      window.alert(message);
      return;
    }
    this.emoji = emoji;
    this.showEmojiPicker = false;
    const form = {
      emoji: emoji
    };
    this.saveTopic(form);
  }

  // to display attachment inline
  getMimeType(url: string): string {
    let attachmentUrl = this.getAttachedFilename(url);
    const mimeType = mime.getType(attachmentUrl);

    if (mimeType == null){
      // can be null if not found anything
      return 'other';
    }

    if (mimeType.includes('video')) {
      return 'video';
    } else if (mimeType.includes('image')) {
      return 'image';
    } else if (mimeType.includes('audio')) {
      return 'audio';
    } else {
      return 'other';
    }
  }

  // deletes the cover image
  deleteCover() {
    if (this.topic_obj.thumbnail){
      const message = this.translateService.instant("Bist du sicher?");
      if (window.confirm(message)) {
        this.saveTopic({
          thumbnail: ''
        });
      }
    }
  }

  shareTopic() {
    const data: PopupData = {
      ...this.topic_obj,
      type: "topic",
    }
    // open popup
    const dialogRef = this.dialog.open(SharePopupComponent, {
      disableClose: true,
      data: data
    });
    // update existing topic if it has changed
    dialogRef.afterClosed().subscribe((res: Topic) => {
      if (res) {
        // find topic and update it
        this.topic_obj = {
          ...res,
          sections: [],  // we don't care about sections as they are already initialised
          translations: [],
        };
      }
    });
    return;
  }

  openTranslationsPopup(){
    const dialog_data: TopicTranslationsPopupData = {
      // we need to pass current topic id, topic language, topic title, topic emoji, sections and translations
      id: this.topic_id,
      language: this.topic_obj.language,
      title: this.title,
      emoji: this.emoji,
      section_ids: this.sections.map(e => e.id),
      last_edited_on: this.topic_obj.last_edited_on,
      translations: this.translations_local_copy
    };

    const popup_width = document.getElementById('parent-div').clientWidth;
    this.dialog.open(TopicTranslationPopupComponent, {
      // height: "90vh",
      // width: "80vw",
      width: `${popup_width}px`,
      maxWidth: "95vw",  // because default max is 80vw
      disableClose: false,
      data: dialog_data,
      // panelClass: ['col-lg-7', 'col-md-7', 'col-sm-12', 'col-12']
    }).afterClosed().subscribe((res: string) => {
      if (res) {
        // language is the only thing which may have changed, rest all is taken care by array reference
        this.topic_obj.language = res;
      }
    });
  }

  deleteTopic(){
    const message = this.translateService.instant('Delete_topic', {val: this.topic_obj.title});
    if (window.confirm(message)){
      this.dataService.deleteURL(`user/topics/${this.topic_id}`).subscribe(()=> {
        this.snackBar.open(this.translateService.instant('Erfolgreich gelöscht'), '', this.snackbarConfig);
        this.router.navigate(['library', 'pages']);
      }, (err) => {
        console.error(err);
        window.alert(err.error);
      });
    }
  }

  dragMove = (event: CdkDragMove<any>): void => {
    // considering only Y (height)
    const deltaY = (event.distance.y / 50);
    // event.source.element.nativeElement.parentElement.clientHeight
    // removed above and added / 50 to slow down the scroll
    //capturing the Y value from object_position BE response
    const currentY = parseFloat(this.object_position.split(' ')[1]);
    // X value remains same, added Math expression where Y should not be less than 0 and max 99 and also rounding off
    this.object_position = `50% ${Math.min(99, Math.max(0, Math.round(currentY - deltaY)))}%`;
  }

  // save and go back to topic overview page
  goBack(){
    this.hideTitleEditor();  // before going back, save the title too!
    this.doneEditing(false);
    this.router.navigate(['library', 'pages']);
  }


  // get all tags
  initiateTags() {
    this.all_tags = this.authService.tag_data;
    this.filtered_tags = this.tagCtrl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value || ''))
    );
    // load tags
    this.selected_tags = this.all_tags.filter(e => this.topic_obj.tags.includes(e.id));
    // now remove those tags from all tags
    this.all_tags = this.all_tags.filter(e => !this.selected_tags.includes(e));
  }

  // when user inputs tag name and presses comma or enter
  addTagByName(name: string) {
    // empty the field
    this.tag_input_element.nativeElement.value = "";

    // trim the name
    const trimmed_name: string = name.trim().slice(0, 49); // BE limit is 50
    if (trimmed_name.length == 0) {
      return;
    }

    // find tag by name in selected tags
    let temp_tag = this.selected_tags.find(e => e.name.toLowerCase() == trimmed_name.toLowerCase());
    if (temp_tag) {
      // already there, return
      return;
    }

    // find tag by name in remaining tags
    temp_tag = this.all_tags.find(e => e.name.toLowerCase() == trimmed_name.toLowerCase());
    if (temp_tag) {
      // tag found, add it
      this.addTagByObject(temp_tag);
    }
    else {
      // push it to BE
      this.navbarService.createTag(trimmed_name).then(res => {
        this.addTagByObject(res);
        // reload global checklist
        this.authService.loadTagsData().then();
      }).catch(e => {
        console.error(e);
        this.snackBar.open(this.translateService.instant('Already exists'), '', {duration: 2500});
      });
    }
  }

  addTagByObject(tag: MiniDetails) {
    // empty the field
    this.tag_input_element.nativeElement.value = "";
    this.selected_tags.push(tag);
    // remove from all tags
    const index = this.all_tags.findIndex(e => e.id == tag.id);
    if (index > -1) {
      this.all_tags.splice(index, 1);
    }
  }

  removeTag(tag: MiniDetails) {
    const index = this.selected_tags.findIndex(e => e.id == tag.id);
    this.selected_tags.splice(index, 1);
    // add back to the main list
    this.all_tags.push(tag);
  }

  saveTagInTopics() {
    const new_tags: number[] = this.selected_tags.map(element => element.id);
    const existing_tags: number[] = this.topic_obj.tags;

    // check if anything changed:
    const added_items = new_tags.filter(e => !existing_tags.includes(e));
    const removed_items = existing_tags.filter(e => !new_tags.includes(e));
    // save if anything is changed
    if (added_items || removed_items) {
      const formData = {
        tags: new_tags
      };

      this.saveTopic(formData);
    }
    // console.log(added_items)
    // console.log(removed_items)
  }

  private _filter(value: string): MiniDetails[] {
    return this.all_tags.filter(element => element.name.toLowerCase().includes(value));
  }

  // make the drag drop div visible or hidden
  showHideDropDiv(section_id: number, hidden: boolean = false){
    document.getElementById(`drop-div-${section_id}`).hidden = hidden;
  }

  // when user drops a file into a section
  dropHandler(section: Section, event) {
    event.preventDefault();
    this.showHideDropDiv(section.id, true);
    try {
      const file = event.dataTransfer.files[0];
      if (section.attachment){
        // tell user
        const message = this.translateService.instant("Please add this file in a new section");
        this.snackBar.open(message, '', this.snackbarConfig);
      } else {
        // attach file
        this.attachFileToSection(section.id, file);
      }
    } finally {
      // do nothing
    }
  }

  protected readonly environment = environment;
}
