import {Component, Input, OnInit} from '@angular/core';
import { environment } from '../../environments/environment';
import { DataService } from '../services/data.service';
import { ActivatedRoute } from '@angular/router';
import {VideoCard, TagWiseContent, TopicCard} from "../models/video/video.interface";

@Component({
  selector: 'app-tags-view',
  templateUrl: './tags-view.component.html',
  styleUrls: ['./tags-view.component.scss']
})
export class TagsViewComponent implements OnInit {
  @Input('tag_id') id: number = 0;
  tagVideos: VideoCard[] = [];
  tagTopics: TopicCard[] = [];
  tagName: string = '';
  backendUrl: string = '';
  mode: 'popup' | 'view' = 'view';

  constructor(public dataService: DataService,
    public route: ActivatedRoute) { }

  ngOnInit(): void {
    this.backendUrl = environment.backendURL;
    if (this.id) {
      this.mode = 'popup';
      this.getTagVideo();
    } else {
      this.route.params.subscribe(params => {
        this.id = params['id'];
        this.getTagVideo();
      });
    }
  }

  getTagVideo(): void {
    let tagUrl = `user/tags/${this.id}/`;
    this.dataService.getURL(tagUrl, { observe: 'body', responseType: 'json' }).subscribe((resp: TagWiseContent) => {
      this.tagName = resp.name;
      this.tagVideos = resp.videos;
      this.tagTopics = resp.topics;
    }, (err) => {
      window.alert(err.error);
    });
  }

  backTo() {
    window.history.back();
  }
}
