import {Component, Input, OnInit, ViewChild} from '@angular/core';
import { MatGridList } from '@angular/material/grid-list';
import { environment } from '../../environments/environment';
import { AuthService } from '../services/auth.service';
import { DataService } from '../services/data.service';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import {VideoCard} from "../models/video/video.interface";

@Component({
  selector: 'app-friend-organisation-view',
  templateUrl: './friend-organisation-view.component.html',
  styleUrls: ['./friend-organisation-view.component.scss']
})
export class FriendOrganisationViewComponent implements OnInit {
  @Input('partnership_id') id: number = undefined;
  friendVideos: VideoCard[] = [];
  @ViewChild('grid') grid: MatGridList;
  cols: number;
  backendUrl: string;
  sortValue: any = 'added_on';
  page: number = 1;
  maxPages: number = 0;
  sharing_company: string = "";
  search_text: string = "";
  showSpinner: boolean = false;
  mode: 'popup' | 'view' = 'view';

  constructor(public dataService: DataService,
    public route: ActivatedRoute,
    private router: Router,
    public authService: AuthService) {
  }

  ngOnInit(): void {
    this.backendUrl = environment.backendURL;
    this.resize(window.innerWidth);
    if (this.id) {
      this.mode = 'popup';
    } else {
        this.route.params.subscribe(params => {
        this.id = +params['id'];
      });
    }
    // load the videos from page 1
    this.getFriendVideo();
  }

  getFriendVideo(): void {
    let queryConstruct = `user/friends/${this.id}/?sort_by=${this.sortValue}&page=${this.page}&search_query=${this.search_text}`;
    this.showSpinner = true;
    this.dataService.getURL(queryConstruct, { observe: 'body', responseType: 'json' }).subscribe((resp: any) => {
      if (this.page == 1) {
        // fresh click case, clear old results
        this.friendVideos = resp['data'];
        this.sharing_company = resp.sharing_company;
        this.page = resp.pageIndex;
        this.maxPages = Math.ceil(resp.length / resp.pageSize);
        // in case of 1080p, no scroll is present
        if(this.maxPages > 1){
          // this condition will load enough videos by triggering scroll
          this.onScrollDown();
        }
      } else {
        // scroll down case, append to existing results
        this.friendVideos.push(...resp['data']);
      }
      this.showSpinner = false;
    }, (err) => {
      window.alert(err.error);
      this.router.navigate(['/groups']);
      this.showSpinner = false;
    });
  }

  applyFilter(): void {
    this.page = 1;
    this.getFriendVideo();
  }

  sortedBy(event) {
    this.page = 1;
    this.sortValue = event.value;
    // search query remains unaffected
    this.getFriendVideo();
  }

  resize(innerWidth) {
    let cw = 320; // card width
    let gs = 20;  // gutter size
    let sn = 50;  // small side nav width = 50 approx
    let px = 50;  // paddind x
    this.cols = Math.floor((innerWidth - sn - px) / (cw + gs));
  }

  onResize(event) {
    this.resize(event.target.innerWidth);
  }

  onScrollDown() {
    this.page += 1;
    if (this.page <= this.maxPages) {
      this.getFriendVideo();
    }
  }

  backTo() {
    window.history.back();
  }
}
