<div class="mx-5 mt-2">
  <div class="ml-2 py-3 d-flex align-items-center bg-primary-555" style="flex-flow: wrap;">
    <mat-icon class="mr-1 material-icons-outlined" color="primary">
      person
    </mat-icon>
    <span class="fontStyle mr-4" style="font-size: x-large;" *ngIf="userName">{{ userName }}</span>
    <button (click)="back();" class="border-radius-20" mat-stroked-button *ngIf="mode == 'view'">
      <mat-icon class="mr-1">
        chevron_left
      </mat-icon>
      {{ "Back" | translate }}
    </button>
  </div>

  <mat-card-subtitle class="px-3 text-nunito">{{ 'n_items from full_name' | translate:{'n_items': all_user_content.length, 'full_name': userName} }}</mat-card-subtitle>

  <!--30 days data with primary bf-->
  <div *ngIf="newest_user_content.length" class="p-2 bg-primary-555 border-radius-10">
    <div class="d-flex justify-content-between align-items-center py-1">
      <h2 class="fontStyle d-flex align-items-center m-0">
        <mat-icon class="material-icons-outlined mr-2" color="primary">grade</mat-icon>
        {{'Newly added'|translate }}&nbsp;{{'in the last 30 days'|translate}}
      </h2>
    </div>

    <!--newest content scroll-->
    <div class="d-flex justify-content-start left-right-div" id="mixed-user">
      <ng-container *ngFor="let card of newest_user_content">
        <app-video-card *ngIf="card.type == 'video'" [videoObject]="card.data" [mode]="mode"
                        style="margin-right: 20px; max-width: 320px;">
        </app-video-card>

        <app-topic-card *ngIf="card.type == 'topic'" [topic_object]="card.data" [mode]="mode"
                        style="max-width: 320px; margin-right: 20px;">
        </app-topic-card>
      </ng-container>

      <!--button 1 with absolute position to the left-->
      <button (click)="scrollToLeftRight('mixed-user',true);" class="left-right-button left-scroll-button" mat-button>
        <mat-icon class="text-white">
          chevron_left
        </mat-icon>
      </button>
      <!--button 2 with absolute position to the left-->
      <button (click)="scrollToLeftRight('mixed-user');" class="left-right-button right-scroll-button" mat-button>
        <mat-icon class="text-white">
          chevron_right
        </mat-icon>
      </button>
    </div>
  </div>

  <!--all content-->
  <div class="d-flex justify-content-between px-3 flex-wrap bg-white position-sticky" id="discover"
       style="top: 0; z-index: 11;">
    <!--z-index 11 because of app video card gifs-->
    <div class="d-flex justify-content-start align-items-center mr-3">
      <mat-icon class="material-icons-outlined mr-2" color="primary">video_library</mat-icon>
      <h2 class="fontStyle m-0">
        {{ 'All content from full_name' | translate: {'full_name': userName} }}
      </h2>
    </div>

    <div class="d-flex justify-content-start flex-wrap">
      <mat-form-field appearance="outline" class="mr-2">
        <mat-label translate>Search for…</mat-label>
        <input (keyup)="applySearchFilter();" [(ngModel)]="searchText" autocomplete="off" matInput
               placeholder="{{'Titel' | translate}}">
      </mat-form-field>

      <mat-form-field appearance="outline" class="mr-2">
        <mat-select (selectionChange)="applySearchFilter();" [(ngModel)]="sort_option">
          <mat-option value="added_on">{{ 'Newest'|translate }}</mat-option>
          <mat-option value="last_edited_on">{{ 'Last edited'|translate }}</mat-option>
          <mat-option value="views">{{ 'Most viewed'|translate }}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-select (selectionChange)="applySearchFilter();" [(ngModel)]="filter_option">
          <mat-option value="all">{{ 'Alle'|translate }}</mat-option>
          <mat-option value="featured">{{ 'Hervorgehoben'|translate }}</mat-option>
          <mat-option value="external">{{ 'Public'|translate }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <div *ngIf="filtered_user_content.length" class="d-flex justify-content-start flex-wrap mb-3 pl-2" id="filtered_content">
    <ng-container *ngFor="let card of filtered_user_content">
      <app-video-card *ngIf="card.type == 'video'" [videoObject]="card.data" [mode]="mode"
                      style="margin-right: 20px; max-width: 320px;">
      </app-video-card>

      <app-topic-card *ngIf="card.type == 'topic'" [topic_object]="card.data" [mode]="mode"
                      style="margin-right: 20px; max-width: 320px;">
      </app-topic-card>
    </ng-container>
  </div>

  <div *ngIf="filtered_user_content.length == 0" style="display: flex;
    align-items: center;
    height: 200px;
    position: relative;
    justify-content: center;">
    <h3>
      {{'No results. Search in other categories or'|translate}}
      <u (click)="navbarService.openVideoRequestDialog();" class="pointer">
        {{'ask your colleagues'|translate}}
      </u>
    </h3>
  </div>
</div>
