import {Component, Input, OnInit} from '@angular/core';
import {environment} from 'src/environments/environment';
import {DataService} from '../services/data.service';
import {ActivatedRoute} from '@angular/router';
import {TopicCard, VideoCard} from '../models/video/video.interface';
import {ProgressSpinnerDialogComponent} from "../shared/progress-spinner-dialog/progress-spinner-dialog.component";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {NavbarService} from "../services/navbar.service";
import {HttpErrorResponse} from "@angular/common/http";

interface MixedCard {
  type: 'video' | 'topic';
  data: VideoCard | TopicCard;
}

@Component({
  selector: 'app-user-view',
  templateUrl: './user-view.component.html',
  styleUrls: ['./user-view.component.scss']
})
export class UserViewComponent implements OnInit {
  @Input('user_id') id: number = undefined;
  backendUrl: string = '';
  userName: string = "";
  all_user_content: MixedCard[] = [];
  filtered_user_content: MixedCard[] = [];
  newest_user_content: MixedCard[] = [];  // stores the last 30 days items
  filter_option: string = 'all';
  searchText: string = '';
  sort_option: string = 'views';
  mode: 'popup' | 'view' = 'view';

  constructor(public dataService: DataService,
              public navbarService: NavbarService,
              private dialog: MatDialog,
              private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.backendUrl = environment.backendURL;
    if (this.id) {
      // popup mode
      this.getuserContent();
      this.mode = 'popup';
    } else {
      this.route.params.subscribe(params => {
        this.id = +params['id'];
        this.getuserContent();
      });
    }
  }

  getuserContent() {
    const dialogRef: MatDialogRef<ProgressSpinnerDialogComponent> =
      this.dialog.open(ProgressSpinnerDialogComponent, {
        panelClass: 'transparent',
        disableClose: true,
        data: "Bitte warten...",
      });

    const userUrl = `user/${this.id}/content/`;
    this.dataService.getURL(userUrl, {observe: 'body', responseType: 'json'}).subscribe((resp: any) => {
      // update data
      this.userName = resp.name;
      // add videos
      for (let i of resp.videos as VideoCard[]) {
        this.all_user_content.push({type: "video", data: i});
      }
      // add topics
      for (let i of resp.topics as TopicCard[]) {
        this.all_user_content.push({type: "topic", data: i});
      }

      // sort content first, so that newest content is sorted too
      this.applySearchFilter();

      // filter newest 30 days data
      const today = new Date();
      const priorDate = new Date(new Date().setDate(today.getDate() - 30));  // 30 days ago
      this.newest_user_content = this.filtered_user_content.filter(el => new Date(el.data.added_on).getTime() > priorDate.getTime());

      // close spinner
      dialogRef.close();
    }, (err: HttpErrorResponse) => {
      if (err.status == 429) {
        window.alert(err.error.detail);
      } else {
        window.alert(err.error);
      }
      dialogRef.close();
    });
  }

  scrollToLeftRight(id: string, left: boolean = false) {
    const element = document.getElementById(id);
    // element has 2+x children, first and last children are buttons
    // scroll distance = width of each child * number of children visible
    const child_width = element.firstElementChild.clientWidth;
    const viewport_width = element.clientWidth;
    const number_of_visible_children = Math.max(Math.floor(viewport_width / child_width), 1);
    // above number may become 0 for very large zoom levels, hence using max

    let scroll_distance = (child_width + 20) * number_of_visible_children;
    // margin-right is added
    if (left) {
      scroll_distance = -scroll_distance;
    }

    element.scrollBy({
      behavior: "smooth",
      left: scroll_distance,
      top: 0
    });
  }

  applySearchFilter() {
    // first search
    this.searchText = this.searchText.trim();
    this.filtered_user_content = this.all_user_content.filter(el =>
      el.data.title.toLowerCase().includes(this.searchText.toLowerCase())
    );

    // then filter
    switch (this.filter_option) {
      case 'all':
        // do nothing
        break;
      case 'featured':
        this.filtered_user_content = this.filtered_user_content.filter(el => el.data.is_featured);
        break;
      case 'external':
        this.filtered_user_content = this.filtered_user_content.filter(el => el.data.is_external);
        break;
    }

    // then sort
    switch (this.sort_option) {
      case 'added_on':
        this.filtered_user_content = this.filtered_user_content.sort((a, b) => new Date(b.data.added_on).getTime() - new Date(a.data.added_on).getTime());
        break;
      case 'last_edited_on':
        this.filtered_user_content = this.filtered_user_content.sort((a, b) => new Date(b.data.last_edited_on).getTime() - new Date(a.data.last_edited_on).getTime());
        break;
      case 'views':
        this.filtered_user_content = this.filtered_user_content.sort((a, b) => b.data.views - a.data.views);
        break;
    }

    document.getElementById('discover').scrollIntoView({behavior: 'smooth', block: "start", inline: "nearest"});
    if (this.filtered_user_content.length) {
      // element may not be ready
      document.getElementById('filtered_content')?.scrollIntoView({behavior: 'smooth'});
    }
  }

  back() {
    window.history.back();
  }
}
