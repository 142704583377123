<div class="d-flex justify-content-center">
  <mat-card (mouseout)="mouseouted()" (mouseout)="mouseouted()" (mouseover)="mouseovered()" (mouseover)="mouseovered()"
    class="card-mv-style-s1 cursor-pointer"
    style="width: 320px; height: 300px;">
    <a [routerLink]="disableClick ? [] : ['/view', videoObject.video.id]" class="text-decoration-none">
      <div class="div-card-thumbnail" style="background-image: url({{thumbnail}})">
        <div class="iconoverlay">
          <div class="video-avatar">
            <div mat-card-avatar
                 [title]="videoObject.video.uploader.email"
                 matTooltip="{{ videoObject.video.uploader.first_name }}&nbsp;{{ videoObject.video.uploader.last_name }}"
                 matTooltipPosition="right"
                 [style.background-image]="'url(' + videoObject.video.uploader.userprofile.profile_pic + ')'"
                 style="background-size: cover;">
            </div>
          </div>
          <div *ngIf="videoObject?.video?.duration" class="video-block-duration">
            <span>{{ videoObject.video.duration | vidDurationFormat }}</span>
          </div>
        </div>
        <mat-progress-bar mode="determinate" [value]="percent_viewed" color="primary"></mat-progress-bar>
      </div>
      <mat-card-header>
        <!-- <div mat-card-avatar style="background-image: url({{
            videoObject.video.uploader.userprofile.profile_pic
          }}); background-size: cover;"></div> -->
        <mat-card-subtitle class="title fontStyle" [title]="videoObject.video.title">
          <b>{{ videoObject.video.title }}</b>
        </mat-card-subtitle>
        <mat-card-subtitle [title]="videoObject.viewed_on" class="text-nunito"
                           style="font-size: 16px;">
          {{ 'Viewed on' | translate }}&nbsp;{{ videoObject.viewed_on | date: "MMM d, y, HH:mm" }}
        </mat-card-subtitle>
      </mat-card-header>
    </a>
  </mat-card>
</div>
