<div class="d-flex justify-content-between flex-wrap align-items-center">
  <!--video title-->
  <div class="d-flex flex-column overflow-hidden col-md-7 col-sm-12">
    <mat-card-title class="fontStyle line-height-initial" translate>
      Edit translations
    </mat-card-title>
    <mat-card-subtitle class="fontStyle font-medium" matTooltipPosition="left"
                       matTooltip="{{'Updated on' | translate}}&nbsp;{{data.last_edited_on | date: 'dd.MM.yyyy HH:mm'}}">
      {{data.emoji}}
      {{data.title}}
      <a href="pages/view/{{data.id}}" mat-icon-button target="_blank">
        <mat-icon>
          open_in_new
        </mat-icon>
      </a>
    </mat-card-subtitle>
  </div>

  <!--language selector-->
  <div class="d-flex justify-content-between flex-wrap align-items-center col-md-5 col-sm-12">
    <mat-form-field appearance="outline" class="mr-3 mt-2 border-radius-10 text-nunito">
      <mat-label class="text-nunito" translate>Original language</mat-label>
      <mat-select (selectionChange)="saveTopicLanguage($event);"
                  [value]="data.language">
        <mat-option *ngFor="let option of language_map" [value]="option[0]">
          {{option[1]}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <button (click)="closeDialog();" mat-icon-button>
      <mat-icon>
        close
      </mat-icon>
    </button>
  </div>
</div>

<div class="d-flex justify-content-between align-items-center">
  <!--new language block-->
  <div class="d-flex align-items-baseline">
    <mat-form-field appearance="outline" class="mr-3 mt-2 border-radius-10 text-nunito">
      <mat-label class="text-nunito" translate>Add translation</mat-label>
      <mat-select [(ngModel)]="new_language_value">
        <mat-option *ngFor="let option of language_map" [value]="option[0]">
          {{ option[1] }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <button (click)="addLanguage();" [disabled]="new_language_value == null"
            class="border-radius-20"
            mat-stroked-button>
      <mat-icon class="mr-1">add</mat-icon>
      <span translate>Add</span>
    </button>
  </div>

  <!-- update all translations button-->
  <button (click)="updateAllTranslations();" *ngIf="data.translations.length"
          [disabled]="is_ai_service_disabled" mat-icon-button
          matTooltip="{{'Update all'|translate}}" matTooltipPosition="left">
    <mat-icon class="text-gray">update</mat-icon>
  </button>
</div>


<div mat-dialog-content>
  <mat-accordion>
    <mat-expansion-panel (closed)="sectionClosed();"
                         (opened)="loadTranslation(tl.id);"
                         *ngFor="let tl of data.translations"
                         style="border: 1px solid lightgray;"
                         class="mb-3 mat-elevation-z0">
      <!--header-->
      <mat-expansion-panel-header>
        <mat-panel-title class="text-nunito" matTooltipPosition="left"
                         matTooltip="{{'Updated on' | translate}}&nbsp;{{tl.last_edited_on | date: 'dd.MM.yyyy HH:mm'}}">
          {{authService.languages.get(tl.language)}}
        </mat-panel-title>
        <mat-panel-description>
          <!--update and delete buttons-->
          <button (click)="$event.stopPropagation(); autoTranslateEachSection(tl.id, true);"
                  [disabled]="is_ai_service_disabled"
                  mat-icon-button
                  matTooltip="{{'Update translation'|translate}}" matTooltipPosition="above">
            <mat-icon>update</mat-icon>
          </button>
          <button (click)="$event.stopPropagation(); deleteTranslation(tl.id);" mat-icon-button
                  matTooltip="{{'Delete translation'|translate}}" matTooltipPosition="above">
            <mat-icon>delete_outline</mat-icon>
          </button>
        </mat-panel-description>
      </mat-expansion-panel-header>

      <!--title, emoji, delete, update-->
      <div class="d-flex justify-content-between">
        <!--translation emoji picker-->
        <button (click)="toggleEmojiPicker();"
                mat-icon-button
                matTooltip="{{'Bearbeiten'|translate}}" matTooltipPosition="above" style="font-size: x-large;">
          {{tl.emoji}}
        </button>
        <emoji-mart (emojiClick)="addEmoji(tl, $event.emoji.native);"
                    *ngIf="showEmojiPicker" [hideObsolete]="false"
                    [isNative]="false"
                    emoji="point_up" style="position: relative;">
        </emoji-mart>

        <!--translation title editor-->
        <button (click)="editTitle(tl);"
                mat-menu-item
                matTooltip="{{'Bearbeiten'|translate}}" matTooltipPosition="above">
          <span class="text-nunito font-medium">
            {{tl.title}}
          </span>
        </button>
      </div>

      <!--sections-->
      <div *ngFor="let sec of currently_opened_sections"
           class="my-3 position-relative border-radius-10"
           id="section-div-{{sec.id}}"
           style="border-width: 1px; border-style: solid; border-color: transparent;">
        <quill-editor (onBlur)="showHideSectionBorder(sec.id, false);"
                      (onContentChanged)="contentChanged(sec, $event);"
                      (onFocus)="showHideSectionBorder(sec.id, true);"
                      [(ngModel)]="sec.content"
                      [modules]="quillConfig"
                      placeholder="{{'Start writing…'|translate}}" theme="snow">
        </quill-editor>
        <!--save button in the toolbar-->
        <button (click)="saveOneSection(sec);" [disabled]="is_ai_service_disabled" class="position-absolute"
                [hidden]="!sec.changed" id="save-section-button-{{sec.id}}"
                mat-icon-button matTooltip="{{'Speichern'|translate}}"
                matTooltipPosition="left" style="top: 0; right: 40px;">
          <mat-icon class="material-icons-outlined">save</mat-icon>
        </button>
        <!--update or auto translate button in the toolbar-->
        <button (click)="autoTranslateOneSection(sec, true);" [disabled]="is_ai_service_disabled" class="position-absolute"
                mat-icon-button matTooltip="{{'Translate section again'|translate}}"
                matTooltipPosition="left" style="top: 0; right: 0;">
          <mat-icon>update</mat-icon>
        </button>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</div>

<div mat-dialog-actions>
  <mat-progress-bar *ngIf="showSpinner" mode="indeterminate"></mat-progress-bar>
</div>
