<mat-progress-bar mode="indeterminate" *ngIf="show_spinner"></mat-progress-bar>
<div class="m-3 d-flex align-items-center" style="flex-flow: wrap;">
  <mat-icon class="mr-1" color="primary">
    group
  </mat-icon>
  <span class="fontStyle mr-4" style="font-size: x-large;">{{department_or_team?.title}}</span>
  <button (click)="backToGroups()" class="border-radius-20" mat-stroked-button>
    <mat-icon class="mr-1">
      chevron_left
    </mat-icon>
    {{ "Back" | translate }}
  </button>
</div>

<mat-tab-group>
    <mat-tab label="{{'Content from members'|translate}}" class="fontStyle">
        <mat-grid-list #grid (window:resize)="onResize($event)" [cols]="cols" [gutterSize]="'1rem'" class="mt-2 "
            rowHeight="320px">
            <mat-grid-tile *ngFor="let video of department_or_team.unrestricted_videos">
                <app-video-card [videoObject]="video" class="VideoCardStyle"></app-video-card>
            </mat-grid-tile>
        </mat-grid-list>
    </mat-tab>
    <mat-tab label="{{'Restricted content'|translate}}" class="fontStyle">
        <mat-grid-list #grid (window:resize)="onResize($event)" [cols]="cols" [gutterSize]="'1rem'" class="mt-2 "
            rowHeight="320px">
            <mat-grid-tile *ngFor="let video of department_or_team.restricted_videos">
                <app-video-card [videoObject]="video" class="VideoCardStyle"></app-video-card>
            </mat-grid-tile>
        </mat-grid-list>
    </mat-tab>
    <mat-tab label="{{'Mitwirkende'|translate}}" class="fontStyle">
        <mat-grid-list #grid *ngIf="department_or_team" [cols]="member_cols" [gutterSize]="'1rem'" class="mt-2 mx-2"
                       rowHeight="150px" style="display: block;" (window:resize)="onResize($event)">
            <mat-grid-tile *ngFor="let m of department_or_team.members" class="group-tile">
                <div class="group-member">
                    <div class="avatar-block">
                        <img [src]="m.userprofile.profile_pic" class="avatar" [matTooltip]="m.email">
                        <div *ngIf="checkIfManager(m.id)" style="position: absolute; left: 50px; top:0px; color: grey;">
                          <!--user is a dept or team manager-->
                          <mat-icon class="material-icons-outlined"
                                    [matTooltip]="adminTooltip"
                                    matTooltipPosition="above">
                            admin_panel_settings
                          </mat-icon>
                        </div>
                    </div>
                    <h3>{{m.first_name}} {{m.last_name}}</h3>
                </div>
            </mat-grid-tile>
        </mat-grid-list>
    </mat-tab>
</mat-tab-group>
