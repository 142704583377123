<div class="m-3 d-flex justify-content-between flex-wrap">
  <div class="d-flex justify-content-start align-items-center">
    <mat-icon class="mr-1 material-icons-outlined" color="primary" mat-icon>
      play_circle_outline
    </mat-icon>
    <mat-card-title class="fontStyle mt-0 mb-0 mr-4" translate>
      All Clypps
    </mat-card-title>
    <button mat-stroked-button routerLink="/start" class="border-radius-20">
      <mat-icon>chevron_left</mat-icon>
      <span translate>Back</span>
    </button>
  </div>

  <div class="d-flex justify-content-end align-items-baseline">
    <mat-slide-toggle (change)="featuredToggle($event)" [checked]="isFeatured" class="mr-4">
      <span class="text-nunito" translate>
          Featured only
      </span>
    </mat-slide-toggle>

    <mat-form-field appearance="outline">
      <mat-label translate>Sort</mat-label>
      <mat-select (selectionChange)="sortedBy($event)" [value]="sortValue">
        <mat-option value="added_on"><span translate>Neueste Clypps</span></mat-option>
        <mat-option value="up_votes"><span translate>Beliebteste Clypps</span></mat-option>
        <mat-option value="views"><span translate>Most viewed</span></mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>


<div (scrolled)="getAllVideos();" [fromRoot]="true" [infiniteScrollContainer]="'mat-sidenav-content'"
     [infiniteScrollDistance]="1" [infiniteScrollThrottle]="500" [scrollWindow]="false" class="container-fluid"
     infiniteScroll>

  <div class="d-flex justify-content-center" style="flex-flow: wrap;">
    <!--video card grid-->
    <h3 translate *ngIf="videos.length == 0 && !showSpinner">
      No results. Potentially search for other terms.
    </h3>
    <app-video-card *ngFor="let video of videos" [videoObject]="video" class="mx-2"></app-video-card>
  </div>

  <div *ngIf="showSpinner" class="d-flex justify-content-center">
    <mat-spinner></mat-spinner>
  </div>

  <mat-card-subtitle *ngIf="page>maxPages" class="text-center">
    Showing all results
  </mat-card-subtitle>
</div>
