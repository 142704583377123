import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, Validators} from '@angular/forms';
import {ActivatedRoute, NavigationExtras, Router} from '@angular/router';
import {AuthService} from '../services/auth.service';
import {NavbarService} from '../services/navbar.service';
import {LoginService} from './login.service';
import {loadScript} from "../shared/share-popup/share-popup.component";
import {environment} from '../../environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit {
  public primaryColor: any = [];
  credentials = this.formBuilder.group({
    email: ['', Validators.required],
    password: ['', Validators.required]
  });

  emailValue: any;
  microsoftImageSource: string;
  isIframe: boolean = false;
  google_redirect_uri: string = "";

  constructor(private authService: AuthService,
              private formBuilder: UntypedFormBuilder,
              private loginService: LoginService,
              private router: Router,
              public route: ActivatedRoute,
              public navbarService: NavbarService
  ) {
    this.google_redirect_uri = `${environment.baseURL}api/google/success/`;
  }

  async ngOnInit(): Promise<void> {
    // this.navbarService.showSideNav = false;
    // this.navbarService.enableCreationMode();
    // this.navbarService.hide();
    // this.credentials.get('email').valueChanges.subscribe(event =>{
    //     this.credentials.get('email').setValue(event.toLowerCase(),{emitEvent:false});
    // });

    // check if the login page is being loaded in an iframe or not
    this.isIframe = inIframe();

    if (this.authService.currentUser) {
      if (!this.authService.currentUser?.is_profile_completed) {
        this.router.navigate(['user_profile']);
      } else {
        this.router.navigate(['start']);
      }
    }
    else {
      // only load the Google script if user is not logged in
      // otherwise, user see the prompt on start page too
      this.appendGoogleDiv();
      loadScript("https://accounts.google.com/gsi/client");
    }

    if (localStorage.getItem('language') == 'de') {
      this.microsoftImageSource = "assets/images/ms-symbollockup_signin_light_de.svg";
    } else {
      this.microsoftImageSource = "assets/images/ms-symbollockup_signin_light_en.svg";
    }
  }

  navigateToSignUpPage() {
    const email_input = this.credentials.get('email').value;
    if (email_input) {
      const navigationExtras: NavigationExtras = {
        state: {
          email: this.credentials.value.email,
        }
      }
      this.router.navigate(['companyRegistration'], navigationExtras);
    } else {
      this.router.navigate(['companyRegistration']);
    }
  }

  submitLogin() {
    this.emailValue = this.credentials.get('email').value;
    let convertEmail = this.emailValue.toLowerCase();
    this.credentials.get('email').setValue(convertEmail);
    this.loginService.login('user', this.credentials.get('email').value, this.credentials.get('password').value);
  }

  appendGoogleDiv() {
    // https://developers.google.com/identity/gsi/web/guides/display-button#html
    const body = <HTMLDivElement>document.body;
    let div = document.createElement('div');
    div.id = 'g_id_onload';

    // if we are in iframe, then auto_prompt will be false
    div.setAttribute("data-auto_prompt", `${!this.isIframe}`);
    // otherwise, there will be an auto_prompt

    // update style so that it does not cover language button (this is ignored on mobile phones)
    div.style.position = "absolute";
    div.style.top = "80px";
    div.style.right = "10px";

    // https://developers.google.com/identity/gsi/web/guides/change-position
    div.setAttribute("data-prompt_parent_id", "g_id_onload");

    div.setAttribute("data-client_id", "85644330081-m8qqc8fg0fivp30ot90d12jddlqhh1s9.apps.googleusercontent.com");
    div.setAttribute("data-login_uri", this.google_redirect_uri);
    body.appendChild(div);
  }

  openLoginLink(link: string) {
    const new_window = window.open(link, '_blank');
    // check after every two second if user has closed the window
    const timer = setInterval(function () {
      if (new_window.closed) {
        clearInterval(timer);
        location.reload();
      }
    }, 2000);
  }
}

export function inIframe() {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}
