<!--cover image-->
<div *ngIf="topic_obj"
     class="cover-container d-flex w-100 overflow-hidden"
     style="aspect-ratio: 50/9;">
  <div (cdkDragMoved)="dragMove($event);"
       [cdkDragDisabled]="!enableDrag" cdkDrag
       class="drag-wrapper h-100 w-100">
    <img [src]="topic_obj.thumbnail ? topic_obj.thumbnail : 'assets/images/Topics_Cover_Image.png'"
         [style.cursor]="enableDrag ? 'move !important' : 'auto'"
         [style.object-position]="object_position"
         class="w-100 h-100">
  </div>
</div>

<!--save and cancel the reposition of the cover image-->
<div *ngIf="enableDrag" class="w-100 d-flex justify-content-end btn-container position-absolute">
  <button (click)="saveTopic({object_position: object_position});enableDrag = !enableDrag"
          class="text-nunito save-position"
          mat-button>
    {{'Speichern' | translate}}
  </button>
  <button (click)="object_position = topic_obj.object_position;enableDrag = !enableDrag"
          class="text-nunito cancel-position"
          mat-button>
    {{'Cancel' | translate}}
  </button>
</div>

<!-- edit cover image-->
<button *ngIf="topic_obj"
        [disabled]="enableDrag"
        [matMenuTriggerFor]="coverMenu"
        class="float-right position-relative cover-upload-label-button" color="primary"
        mat-fab>
  <mat-icon>edit</mat-icon>
</button>

<!--Menu items in the cover-->
<mat-menu #coverMenu="matMenu">
  <label class="mb-0" for="file-upload" mat-menu-item>
    {{'Hochladen'|translate}}
  </label>
  <button (click)="enableDrag=true;"
          [disabled]="topic_obj?.thumbnail == null"
          mat-menu-item>
    {{'Customize'|translate}}
  </button>
  <button (click)="deleteCover()" [disabled]="topic_obj?.thumbnail == null" mat-menu-item>
    {{'Löschen'|translate}}
  </button>
</mat-menu>

<input (change)="coverFileSelected($event)" accept=".jpg,.png,.jpeg" hidden id="file-upload" type="file"/>

<div *ngIf="topic_obj" class="w-100 d-flex justify-content-end row mx-0">
  <!-- title, section content-->
  <div class="col-lg-7 col-md-7 col-sm-12 col-12 pt-4" id="parent-div">
    <div class="d-flex mt-3 mb-5 flex-column px-2">
      <div class="d-flex align-items-center">
        <button (click)="goBack();" class="d-none d-md-inline"
                mat-icon-button
                style="margin-left: -45px;">
          <mat-icon>arrow_back</mat-icon>
        </button>
        <button (click)="toggleEmojiPicker();"
                [disabled]="is_viewer_only"
                class="mr-3"
                mat-icon-button
                matTooltip="{{'Bearbeiten'|translate}}" matTooltipPosition="above" style="font-size: xx-large;">
          {{emoji}}
        </button>
        <emoji-mart (clickOutside)="showEmojiPicker=false" (emojiClick)="addEmoji($event.emoji.native);"
                    *ngIf="showEmojiPicker" [hideObsolete]="false"
                    [isNative]="false" [style]="{ position: 'absolute' }" emoji="point_up">
        </emoji-mart>

        <!--show hide title based on click action-->
        <mat-card-title (click)="is_title_editor_visible=true" *ngIf="!is_title_editor_visible"
                        class="fontStyle line-height-initial"
                        style="font-size: 28px;">
          {{title}}
          <mat-icon class="material-icons-outlined" matSuffix>edit</mat-icon>
        </mat-card-title>

        <!--title editor-->
        <mat-form-field (clickOutside)="hideTitleEditor(true);"
                        *ngIf="is_title_editor_visible" appearance="outline"
                        class="w-100 line-height-initial">
        <textarea (keydown.enter)="hideTitleEditor(true, $event);"
                  (keyup.escape)="hideTitleEditor(false);"
                  [(ngModel)]="title"
                  [disabled]="is_viewer_only"
                  autocapitalize="on" autocomplete="off" class="fontStyle line-height-initial" id="title-editor"
                  matInput
                  maxlength="100" required rows="2" style="font-size: 28px;" type="text">
        </textarea>
          <mat-error *ngIf="title.length==0" translate>Please provide a title</mat-error>
        </mat-form-field>
      </div>
      <!--last updated row-->
      <mat-card-subtitle class="text-nunito my-2 font-medium"
                         style="color:#8E8E93"
                         title="{{'Erstellt am'|translate}}&nbsp;{{topic_obj.added_on | date:'dd.MM.y'}}">
        {{'Updated on' | translate}}
        {{topic_obj.last_edited_on | date:'dd.MM.yyyy HH:mm'}}
      </mat-card-subtitle>

      <!--done, share Button row-->
      <div class="mb-3 d-flex align-items-center" style="flex-flow: wrap;">
        <button (click)="openTranslationsPopup();" mat-button
                matBadgePosition="before"
                [matBadgeHidden]="translations_local_copy.length == 0"
                [matBadge]="translations_local_copy.length"
                [disabled]="is_viewer_only">
          <mat-icon class="mr-1" mat-list-icon>
            translate
          </mat-icon>
          <span class="text-nunito" translate>Translations</span>
        </button>

        <!--published toggle-->
        <mat-slide-toggle (change)="saveTopic({is_draft: !is_published});"
                          [(ngModel)]="is_published"
                          [disabled]="is_viewer_only">
          <span class="text-nunito" style="font-size: 14px;">
            {{is_published ? ('Veröffentlicht' | translate) : ('Draft' | translate)}}
          </span>
        </mat-slide-toggle>

        <button (click)="shareTopic()" mat-button>
          <mat-icon class="mr-1" mat-list-icon>share</mat-icon>
          <span class="text-nunito" translate>Teilen</span>
        </button>

        <!-- done editing button -->
        <button (click)="doneEditing();" class="border-radius-20 mr-1" mat-stroked-button
                matTooltip="{{ 'Done' | translate }}">
          <mat-icon class="mr-1">done</mat-icon>
          {{topic_obj.is_draft ? ("Preview" | translate) : ("Done" | translate)}}
        </button>

        <button [matMenuTriggerFor]="moreMenu" aria-label="more options" mat-icon-button>
          <mat-icon>more_horiz</mat-icon>
        </button>
      </div>

      <mat-menu #moreMenu="matMenu">
        <!--todo: duplicate topic button-->
        <button (click)="deleteTopic()" mat-menu-item>
          <mat-icon class="mr-3 material-icons-outlined" mat-list-icon>delete</mat-icon>
          <span class="text-nunito" translate>Löschen</span>
        </button>
      </mat-menu>

      <!--sections as quill blocks-->
      <div class="w-100 d-flex flex-column mt-3" id="target">
        <div *ngFor="let section of sections"
             id="section-div-{{section.id}}"
             class="my-3 p-3 border-radius-15 position-relative"
             style="border-width: 1px; border-style: solid; border-color: lightgray;"
             (paste)="filePastedInSection(section, $event);"
             (dragenter)="showHideDropDiv(section.id, false);">
          <div id="drop-div-{{section.id}}"
               class="position-absolute w-100 h-100 bg-dark border-radius-15 align-items-center justify-content-center"
               style="top: 0; left: 0; z-index: 4; display: flex;"
               hidden
               (dragover)="$event.preventDefault();"
               (dragleave)="showHideDropDiv(section.id, true);"
               (drop)="dropHandler(section, $event);">
            <!--We can not use d-flex because it becomes important and over rides hidden-->
            <mat-card-title translate class="text-white">Drag and drop files to upload</mat-card-title>
          </div>
          <quill-editor (onBlur)="showHideSectionBorder(section.id, false);"
                        (onContentChanged)="contentChanged(section, $event);"
                        (onFocus)="showHideSectionBorder(section.id, true);"
                        (onSelectionChanged)="updateCursorPosition($event);"
                        [(ngModel)]="section.content"
                        [modules]="quillConfig"
                        #quill1
                        placeholder="{{'Start writing…'|translate}}" theme="bubble">
          </quill-editor>
          <mat-error class="mt-2 text-nunito" translate
                     *ngIf="section.content?.length > environment.bodySize">
            The provided input is too large. Try deleting some content.
          </mat-error>

          <!--add attachment preview-->
          <!--There are 2 ways to display an attachment: video or image-->
          <ng-container *ngIf="section.attachment">
            <!--Line break-->
            <hr class="mx-0 my-2">
            <!-- Always show attachment link, because user may want to download it -->
            <div>
              <a [href]="section.attachment" [title]="section.attachment" target="_blank">
                {{getAttachedFilename(section.attachment)}}
              </a>
              <!--delete attachment-->
              <button (click)="deleteAttachment(section)" mat-icon-button
                      matTooltip="{{'Delete attachment'|translate}}" matTooltipPosition="below">
                <mat-icon>
                  remove_circle_outline
                </mat-icon>
              </button>
            </div>

            <!-- image attachment -->
            <img *ngIf="getMimeType(section.attachment) == 'image'" [src]="section.attachment"
                 class="w-100 border-radius-10 max-height-75"
                 style="object-fit: contain;">
            <!-- video attachment -->
            <video *ngIf="getMimeType(section.attachment) == 'video'" [src]="section.attachment"
                   class="show-border w-100 max-height-75" controls
                   controlsList="nodownload"
                   crossorigin="anonymous" playsinline preload="metadata"
                   type="video/mp4">
              <!--User can only upload mp4 files as other files are not playable in browser-->
              This browser does not support video files
            </video>
            <audio *ngIf="getMimeType(section.attachment) == 'audio'" class="w-100" controls controlsList="nodownload">
              <source [src]="section.attachment">
              This browser does not support audio files
            </audio>
            <br>
            <!--Line break if attachment exists-->
          </ng-container>

          <!--insert question if any-->
          <div *ngIf="getQuestion(section.id) as ques" class="quizContainer mb-2">
            <!-- edit quiz overlay-->
            <div class="edit-view-quiz cursor-pointer" (click)="openQuizPopup(ques);">
              <button mat-button>
                <mat-icon class="mr-1 material-icons-outlined text-white">edit</mat-icon>
                <span class="text-white text-nunito" translate="Bearbeiten"></span>
              </button>
            </div>
            <view-question [isTopicCreator]="true" [ques]="ques"></view-question>
          </div>

          <!--delete section-->
          <button (click)="deleteSection(section.id);" class="deleteCircle" mat-icon-button
                  matTooltip="{{'Löschen'|translate}}" matTooltipPosition="below">
            <mat-icon>
              delete_outline
            </mat-icon>
          </button>

          <!--toolbar buttons-->
          <div class="w-100 d-flex align-items-center bg-light position-sticky bg-white section-bottom-toolbar">
            <!--section ai-->
            <button mat-icon-button
                    [disabled]="!authService.company.is_transcription_service_enabled"
                    [matMenuTriggerFor]="sectionAIMenu"
                    matTooltip="{{'Ask Clypp AI'|translate}}"
                    color="primary"
                    matTooltipPosition="below">
              <mat-icon class="material-icons-outlined">auto_fix_high</mat-icon>
            </button>

            <!--section ai menu-->
            <mat-menu #sectionAIMenu="matMenu" yPosition="below">
              <button mat-menu-item (click)="predictSection(section, 'predict');">
                <mat-icon class="material-icons-outlined">auto_awesome</mat-icon>
                <span translate>Generate with AI</span>
              </button>
              <button mat-menu-item (click)="predictSection(section, 'ideas');">
                <mat-icon class="material-icons-outlined">auto_awesome</mat-icon>
                <span translate>Brainstorm ideas on</span>
              </button>
              <button mat-menu-item (click)="predictSection(section, 'agenda');">
                <mat-icon class="material-icons-outlined">auto_awesome</mat-icon>
                <span translate>Generate an agenda for</span>
              </button>
              <button mat-menu-item (click)="predictSection(section, 'list');">
                <mat-icon class="material-icons-outlined">auto_awesome</mat-icon>
                <span translate>Create a list about</span>
              </button>
              <hr class="m-0">
              <button mat-menu-item (click)="predictSection(section, 'improve');"
                      [disabled]="section.content?.length == 0">
                <mat-icon>spellcheck</mat-icon>
                <span translate>Improve text</span>
              </button>
              <button mat-menu-item (click)="predictSection(section, 'compact');"
                      [disabled]="section.content?.length == 0">
                <mat-icon>short_text</mat-icon>
                <span translate>Make it shorter</span>
              </button>
              <button mat-menu-item (click)="predictSection(section, 'summary');"
                      [disabled]="section.content?.length == 0">
                <mat-icon>close_fullscreen</mat-icon>
                <span translate>Quick Summary</span>
              </button>
            </mat-menu>

            <!--iframe-->
            <button (click)="addClypp(section, quill1);" mat-icon-button matTooltip="{{'Insert Clypp'|translate}}"
                    matTooltipPosition="below">
              <img height="20" src="assets/images/select_clypp.png">
            </button>

            <!--add attachment-->
            <label *ngIf="section.attachment == null" class="cursor-pointer" for="file-input-{{section.id}}"
                   matTooltip="{{'Attach file'|translate}}" matTooltipPosition="below" style="margin-bottom: -5px;">
              <mat-icon>
                attach_file
              </mat-icon>
            </label>
            <!--todo: check available more options-->
            <input (change)="sectionFileSelected(section.id, $event)"
                   accept=".pdf,.jpg,.png,.ppt,.pptx,.docx,.csv,.doc,.jpeg,.xlsx,.xls,.txt,.mp4,.mp3,.wav" hidden
                   id="file-input-{{section.id}}" type="file"/>

            <!--link-->
            <button (click)="insertLink(section, quill1, '');" mat-icon-button
                    matTooltip="{{'Insert embed link'|translate}}"
                    matTooltipPosition="below">
              <mat-icon>
                add_link
              </mat-icon>
            </button>

            <!--iframe-->
            <button (click)="insertIframe(section, quill1);" mat-icon-button
                    matTooltip="{{'Insert iframe'|translate}}"
                    matTooltipPosition="below">
              <mat-icon>
                code
              </mat-icon>
            </button>

            <!--one drive button-->
            <button (click)="openOneDrivePopup(section);" mat-icon-button
                    matTooltip="OneDrive"
                    matTooltipPosition="below">
              <img height="20" src="assets/images/onedrive.svg">
            </button>

            <!-- add quiz menu-->
            <!-- only show add button if there is no question -->
            <!--disable menu button if user is not a creator-->
            <ng-container *ngIf="!doesQuestionExist(section.id)">
              <button [disabled]="is_viewer_only"
                      [matMenuTriggerFor]="quizMenu"
                      mat-icon-button
                      matTooltip="{{'Add question'|translate}}" matTooltipPosition="below">
                <mat-icon class="material-icons-outlined">
                  quiz
                </mat-icon>
              </button>
              <mat-menu #quizMenu="matMenu">
                <button mat-menu-item (click)="addQuizPopup(section.id, 'SS')">
                  <mat-icon>radio_button_checked</mat-icon>
                  <span translate>Single select</span>
                </button>
                <button mat-menu-item (click)="addQuizPopup(section.id, 'MS')">
                  <mat-icon class="material-icons-outlined">check_box</mat-icon>
                  <span translate>Multiple select</span>
                </button>
                <button mat-menu-item (click)="addQuizPopup(section.id, 'ST')">
                  <mat-icon>short_text</mat-icon>
                  <span translate>Short answer</span>
                </button>
                <button mat-menu-item (click)="addQuizPopup(section.id, 'LT')">
                  <mat-icon>subject</mat-icon>
                  <span translate>Long answer</span>
                </button>
                <button mat-menu-item (click)="addQuizPopup(section.id, 'FU')">
                  <mat-icon class="material-icons-outlined">file_upload</mat-icon>
                  <span translate>File upload</span>
                </button>
              </mat-menu>
            </ng-container>
            <!--menu-->
          </div>

          <mat-progress-bar [id]="'progress-bar-'+section.id" hidden mode="indeterminate"></mat-progress-bar>
        </div>
      </div>
      <!--end of sections block-->

      <!--add section button-->
      <div class="d-flex justify-content-center mt-3" id="add-button">
        <button (click)="addSection();" class="border-radius-20" mat-stroked-button
                style="width: fit-content;">
          <mat-icon class="mr-1">add</mat-icon>
          <span translate>Add</span>
        </button>
      </div>

      <!--tag keyword section-->
      <!--tags-->
      <div class="w-100 my-4">
        <mat-form-field appearance="standard" class="w-100 line-height-initial">
          <mat-chip-list #chipList aria-label="keyword selection">
            <mat-chip (removed)="removeTag(tag)" *ngFor="let tag of selected_tags">
              {{tag.name}}
              <button matChipRemove>
                <mat-icon>cancel</mat-icon>
              </button>
            </mat-chip>
            <input #tagInput
                   (matChipInputTokenEnd)="addTagByName($event.value)"
                   [autofocus]="false"
                   [formControl]="tagCtrl"
                   [matAutocomplete]="auto"
                   [matChipInputFor]="chipList"
                   [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                   (focusout)="saveTagInTopics();"
                   aria-label="new keyword"
                   matInput
                   placeholder="{{'New keyword…'|translate}}"
                   type="text">
          </mat-chip-list>
          <mat-hint class="text-nunito" translate>Separate keywords with a comma</mat-hint>
          <mat-autocomplete #auto="matAutocomplete">
            <mat-option (onSelectionChange)="addTagByObject(tag);" *ngFor="let tag of filtered_tags | async"
                        [value]="tag">
              {{tag.name}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
    </div>
  </div>

  <!--drop drop section content-->
  <div class="scroll-content mt-3 d-none d-md-inline col-3 pt-4">
    <div *ngIf="sections.length" class="text-nunito p-3" style="font-size: 18px;" translate>
      <!-- padding 3 is needed to align it with list items-->
      <b>{{'In this page' | translate}}</b>
    </div>
    <div (cdkDropListDropped)="sectionDropped($event)" cdkDropList>
      <div *ngFor="let section of sections;" [title]="getTableOfContentLabel(section, 100)"
           cdkDrag
           class="drag-box overflow-hidden px-3">
        <mat-icon cdkDragHandle class="material-icons-outlined"
                  style="color: grey; cursor: move; min-width: 24px;">
          <!--min width is needed to ensure button is displayed -->
          drag_indicator
        </mat-icon>
        <button (click)="scrollToItem(section.id)" class="fontNormal" mat-menu-item>
          {{getTableOfContentLabel(section, 100)}}
        </button>
      </div>
    </div>
  </div>
</div>
