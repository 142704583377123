<div (keypress)="move_img($event)" *ngIf="closeGallery"
     class="spinner-overlay justify-content-center align-items-center">
  <div class="row" tabindex="-1">
    <span class="col-12" style="text-align:end;margin-left: -1rem;margin-top: 1rem;">
      <mat-icon (click)="close_gallery()" class="cursor-pointer" mat-list-icon style="color:white;">cancel</mat-icon>
     </span>
  </div>
  <div class="row alignment">
    <div class="col-1 arrow" style="text-align: end;">
      <mat-icon (click)="move_left()" *ngIf="index" class="cursor-pointer" mat-list-icon>
        chevron_left
      </mat-icon>
    </div>
    <div class="col-10 parent-img">
      <img (click)="close_gallery()" [src]="src" alt="description image" class="image" id="img-child">
    </div>
    <div class="col-1 arrow" style="text-align: start;">
      <mat-icon (click)="move_right()" *ngIf="index != srcs.length-1" class="cursor-pointer" mat-list-icon>
        chevron_right
      </mat-icon>
    </div>
  </div>
</div>

<div #scroll class="row m-0 p-xl-4 p-2 video-view-page" style="min-width: 300px; overflow-x: hidden">
  <!--large screen: 66%, small/medium screen: 100%-->
  <div *ngIf="currentVideo" class="col-lg-8 col-md-12 col-sm-12 col-12 d-inline-block" style="min-width: 300px">
    <div>
      <!--          on context menu option will disable right click on video view container-->

      <mat-card-title class="fontStyle font-weight-bolder line-height-initial mb-3" style="font-size: 24px;">
        {{ currentVideo.title }}
        <span *ngIf="currentVideo.is_draft" style="color: darkred; border-radius: 3px; border: 1px solid;" translate>
          &nbsp;Draft&nbsp;
        </span>
      </mat-card-title>

      <div class="mb-3" id="leftVideo" oncontextmenu="return false;">
        <!--removed these two from line below:timeupdate="timeupdate()" (loadedmetadata)="getDuration()" -->
        <!--https://github.com/vidstack/examples/blob/main/player/web-components/default-theme/video-player.html -->
        <media-player (duration-change)="onPlayerReady();"
                      (ended)="videoEnded()"
                      [title]="currentVideo.title"
                      crossorigin="anonymous"
                      playsinline
                      preload="auto"
                      src="{{vidPath}}">
          <media-provider>
            <!--Poster-->
            <media-poster alt="{{currentVideo.title}}"
                          class="vds-poster"
                          src="{{currentVideo.thumbnail}}">
            </media-poster>

            <!--Subtitles or captions-->
            <ng-container *ngFor="let translation of translations">
              <track *ngIf="translation.vtt_file"
                     [label]="translation.language"
                     [src]="translation.vtt_file"
                     [srclang]="translation.language"
                     kind="subtitles"
              />
            </ng-container>

            <!--Chapter-->
            <track *ngIf="currentVideo.chapter_file"
                   [src]="currentVideo.chapter_file"
                   [srclang]="currentVideo.audio_language"
                   default
                   label="chapter"
                   kind="chapters"/>
          </media-provider>

          <!-- Gestures -->
          <media-gesture action="toggle:paused" event="pointerup"></media-gesture>
          <media-gesture action="toggle:fullscreen" event="dblpointerup"></media-gesture>
          <media-gesture action="toggle:controls" event="pointerup"></media-gesture>
          <media-gesture action="seek:-10" event="dblpointerup"></media-gesture>
          <media-gesture action="seek:10" event="dblpointerup"></media-gesture>

          <!-- Captions -->
          <media-captions class="vds-captions"></media-captions>

          <!-- Controls -->
          <media-controls class="vds-controls">
            <div class="vds-controls-spacer"></div>
            <!-- Time Slider Controls Group -->
            <media-controls-group class="vds-controls-group">
              <!-- Time Slider -->
              <media-time-slider class="vds-time-slider vds-slider">
                <media-slider-chapters class="vds-slider-chapters">
                  <template>
                    <div class="vds-slider-chapter">
                      <div class="vds-slider-track"></div>
                      <div class="vds-slider-track-fill vds-slider-track"></div>
                      <div class="vds-slider-progress vds-slider-track"></div>
                    </div>
                  </template>
                </media-slider-chapters>

                <div class="vds-slider-thumb"></div>

                <!-- Slider Preview -->
                <media-slider-preview class="vds-slider-preview bg-dark">
                  <div class="vds-slider-chapter-title" data-part="chapter-title"></div>
                  <media-slider-value class="vds-slider-value"></media-slider-value>
                </media-slider-preview>
              </media-time-slider>
            </media-controls-group>
            <!-- Lower Controls Group -->
            <media-controls-group class="vds-controls-group">
              <!-- Play Button -->
              <media-tooltip>
                <media-tooltip-trigger>
                  <media-play-button class="vds-button">
                    <media-icon class="vds-play-icon" type="play"></media-icon>
                    <media-icon class="vds-pause-icon" type="pause"></media-icon>
                    <media-icon class="vds-replay-icon" type="replay"></media-icon>
                  </media-play-button>
                </media-tooltip-trigger>
                <media-tooltip-content class="vds-tooltip-content" placement="top">
                  <span class="vds-play-tooltip-text">Play</span>
                  <span class="vds-pause-tooltip-text">Pause</span>
                </media-tooltip-content>
              </media-tooltip>

              <!-- Mute Button -->
              <media-tooltip>
                <media-tooltip-trigger>
                  <media-mute-button class="vds-button">
                    <media-icon class="vds-mute-icon" type="mute"></media-icon>
                    <media-icon class="vds-volume-low-icon" type="volume-low"></media-icon>
                    <media-icon class="vds-volume-high-icon" type="volume-high"></media-icon>
                  </media-mute-button>
                </media-tooltip-trigger>
                <media-tooltip-content class="vds-tooltip-content" placement="top">
                  <span class="vds-mute-tooltip-text">Unmute</span>
                  <span class="vds-unmute-tooltip-text">Mute</span>
                </media-tooltip-content>
              </media-tooltip>

              <!-- Volume Slider -->
              <media-volume-slider class="vds-slider">
                <div class="vds-slider-track"></div>
                <div class="vds-slider-track-fill vds-slider-track"></div>
                <media-slider-preview class="vds-slider-preview" no-clamp>
                  <media-slider-value class="vds-slider-value"></media-slider-value>
                </media-slider-preview>
                <div class="vds-slider-thumb"></div>
              </media-volume-slider>

              <!-- Time Group -->
              <div class="vds-time-group">
                <media-time class="vds-time" type="current"></media-time>
                <div class="vds-time-divider">/</div>
                <media-time class="vds-time" type="duration"></media-time>
              </div>

              <media-chapter-title class="vds-chapter-title"></media-chapter-title>

              <div class="vds-controls-spacer"></div>

              <!-- Caption Button -->
              <media-tooltip>
                <media-tooltip-trigger>
                  <media-caption-button class="vds-button">
                    <media-icon class="vds-cc-on-icon" type="closed-captions-on"></media-icon>
                    <media-icon class="vds-cc-off-icon" type="closed-captions"></media-icon>
                  </media-caption-button>
                </media-tooltip-trigger>
                <media-tooltip-content class="vds-tooltip-content" placement="top">
                  <span class="vds-cc-on-tooltip-text">Closed-Captions Off</span>
                  <span class="vds-cc-off-tooltip-text">Closed-Captions On</span>
                </media-tooltip-content>
              </media-tooltip>

              <!-- Playback speed menu -->
              <media-menu class="vds-menu">
                <!-- Settings Menu Button -->
                <media-tooltip>
                  <media-tooltip-trigger>
                    <media-menu-button class="vds-menu-button vds-button">
                      <media-icon type="playback-speed-circle"></media-icon>
                    </media-menu-button>
                  </media-tooltip-trigger>
                  <media-tooltip-content class="vds-tooltip-content" placement="top">
                    {{'Wiedergabegeschwindigkeit'|translate}}
                  </media-tooltip-content>
                </media-tooltip>

                <!-- Settings Menu Items -->
                <media-menu-items class="vds-menu-items" placement="top end">
                  <!--https://www.vidstack.io/docs/wc/player/components/menus/speed-radio-group?styling=default-theme-->
                  <media-speed-radio-group class="vds-radio-group" normal-label="Normal">
                    <template>
                      <media-radio class="vds-radio">
                        <media-icon class="vds-icon" type="check"></media-icon>
                        <span class="vds-radio-label" data-part="label"></span>
                      </media-radio>
                    </template>
                  </media-speed-radio-group>
                </media-menu-items>
              </media-menu>

              <!-- PIP Button -->
              <media-tooltip>
                <media-tooltip-trigger>
                  <media-pip-button class="vds-button">
                    <media-icon class="vds-pip-enter-icon" type="picture-in-picture"></media-icon>
                    <media-icon class="vds-pip-exit-icon" type="picture-in-picture-exit"></media-icon>
                  </media-pip-button>
                </media-tooltip-trigger>
                <media-tooltip-content class="vds-tooltip-content" placement="top">
                  <span class="vds-pip-enter-tooltip-text">Enter PIP</span>
                  <span class="vds-pip-exit-tooltip-text">Exit PIP</span>
                </media-tooltip-content>
              </media-tooltip>

              <!-- Fullscreen Button -->
              <media-tooltip>
                <media-tooltip-trigger>
                  <media-fullscreen-button class="vds-button">
                    <media-icon class="vds-fs-enter-icon" type="fullscreen"></media-icon>
                    <media-icon class="vds-fs-exit-icon" type="fullscreen-exit"></media-icon>
                  </media-fullscreen-button>
                </media-tooltip-trigger>
                <media-tooltip-content class="vds-tooltip-content" placement="top end">
                  <span class="vds-fs-enter-tooltip-text">Enter Fullscreen</span>
                  <span class="vds-fs-exit-tooltip-text">Exit Fullscreen</span>
                </media-tooltip-content>
              </media-tooltip>
            </media-controls-group>
          </media-controls>
        </media-player>

        <!--<video #videoPlayer (durationchange)="onPlayerReady()" (ended)="videoEnded()" [src]="vidPath"
               autoplay class="quickwinvideo-view" controls
               crossorigin="anonymous" id="viewVideo" preload="metadata" type="video/mp4">

          <ng-container *ngFor="let translation of translations">
            <track *ngIf="translation.vtt_file"
                   [label]="translation.language"
                   [src]="translation.vtt_file"
                   [srclang]="translation.language"
                   kind="subtitles"
            />
          </ng-container>
          Your browser does not support the video tag.
        </video>-->
        <div *ngIf="currentVideo.state == 'PR'" class="video-overlay-text text-nunito">
          <p translate>Your Clypp is being processed</p>
          <p translate>Please refresh the page after some time</p>
        </div>
      </div>

      <span *ngIf="currentVideo.is_held_for_review"
            class="badge ml-1 mb-2 text-nunito font-medium"
            matTooltip="{{'Content under review is not visible to the other users and outside the organization until approved by a manager.'|translate}}"
            matTooltipPosition="right"
            style="background-color: #E9E9E9; color: #24513E;" translate>
          Wird überprüft
      </span>

      <div class="d-flex justify-content-between" style="flex-flow: wrap;">
        <div class="d-flex align-items-center">
          <button *ngIf="is_user_creator" [disabled]="disable_save"
                  [matMenuTriggerFor]="downloadMenu"
                  class="font-size-initial"
                  color="primary" mat-button>
            <mat-icon [matTooltip]="disable_save ? translateService.instant('Upgrade to use this feature') : ''"
                      class="mr-1">
              save_alt
            </mat-icon>
            {{'Herunterladen'|translate}}
          </button>

          <mat-menu #downloadMenu="matMenu">
            <button (click)="downloadMP4()" mat-menu-item>MP4</button>
            <button (click)="downloadWAV()" mat-menu-item>WAV</button>
            <button (click)="downloadPDF(videoQR)" mat-menu-item>PDF</button>
            <button (click)="downloadZip(true);"
                    [disabled]="authService.company.max_upload_video_height != '1440'"
                    mat-menu-item>SCORM
            </button>
            <button (click)="downloadZip(false);"
                    [disabled]="authService.company.max_upload_video_height != '1440'"
                    mat-menu-item>ZIP
            </button>
          </mat-menu>

          <button (click)="editVideo(currentVideo.id)" *ngIf="is_user_creator"
                  class="font-size-initial" color="primary" mat-button>
            <mat-icon class="material-icons-outlined mr-1">edit</mat-icon>
            {{ 'Bearbeiten' | translate }}
          </button>

          <button (click)="reviewVideo();"
                  *ngIf="is_user_manager"
                  class="font-size-initial" color="primary" mat-button>
            <!--User must be a quality manager & video must belong to the same company for this button to be visible-->
            <mat-icon class="mr-1">check</mat-icon>
            {{ 'Admin Actions' | translate }}
          </button>
        </div>

        <div hidden>
          <qrcode #videoQR [qrdata]="video_view_link" allowEmptyString="false" colorDark="#000000" elementType="canvas"
                  errorCorrectionLevel="M" imageHeight="45" imageSrc="assets/images/default_overlay.png" imageWidth="45"
                  margin="2"
                  scale="1" width="250"></qrcode>
        </div>

        <div class="d-flex justify-content-end">
          <!-- Like -->
          <button (click)="likeVideo()" class="p-1" id="like-unlike-button" mat-button style="min-width: auto;">
            <!--min width auto is needed, otherwise mat-button is too wide-->
            <mat-icon *ngIf="!likeColor" class="material-icons-outlined"
                      matTooltip="{{ 'Like' | translate }}" matTooltipPosition="above">
              thumb_up_off_alt
            </mat-icon>
            <mat-icon *ngIf="likeColor"
                      matTooltip="{{ 'Liked' | translate }}" matTooltipPosition="above">
              thumb_up
            </mat-icon>

            <!--show number of likes if I am not the video creator-->
            <span *ngIf="!is_user_creator" class="ml-1">
              <span>{{ likeCounter }}</span>&nbsp;
              <span *ngIf="likeCounter == 1" translate>Like</span>
              <span *ngIf="likeCounter != 1" translate>Likes</span>
            </span>
          </button>


          <!-- Only creator can see who liked my clypp -->
          <button (click)="showLikes()" *ngIf="is_user_creator" class="p-1 font-size-initial"
                  mat-button
                  matTooltip="{{'See who liked this Clypp'|translate}}" matTooltipPosition="above">
            <span>{{ likeCounter }}</span>&nbsp;
            <span *ngIf="likeCounter == 1" translate>Like</span>
            <span *ngIf="likeCounter != 1" translate>Likes</span>
          </button>

          <!-- Share -->
          <button (click)="sharePopup()" class="font-size-initial" mat-button>
            <mat-icon class="mr-1">share</mat-icon>
            <span translate>Teilen</span>
          </button>

          <!-- Language -->
          <button *ngIf="translations.length > 1 || show_add_translation_button" [matMenuTriggerFor]="menu_trans"
                  aria-label="{{ 'Change language' | translate }}"
                  class="font-size-initial" mat-button
                  matTooltip="{{ 'Change language' | translate }}" matTooltipPosition="above">
            <mat-icon class="mr-1">language</mat-icon>
            <span>{{authService.languages.get(current_language)}}</span>
          </button>
          <mat-menu #menu_trans="matMenu">
            <!--buttons for all translations-->
            <button (click)="changeLanguage(translation);" *ngFor="let translation of translations; let index = index;"
                    mat-menu-item [title]="index == 0 ? ('Original language'|translate) : ''">
              <span>{{authService.languages.get(translation.language)}}</span>
              <mat-icon *ngIf="index == 0" class="ml-1 text-dark material-icons-outlined">verified</mat-icon>
            </button>

            <!-- additional button for viewer to add a new translation -->
            <ng-container *ngIf="show_add_translation_button">
              <!--only visible if internal video and ai services are enabled-->
              <mat-divider></mat-divider>
              <button mat-menu-item (click)="translateToUserLanguage();">
                <mat-icon class="mr-1">add</mat-icon>
                <span>{{ authService.languages.get(authService.userDetails.email_language) }}</span>
              </button>
            </ng-container>
          </mat-menu>

          <!-- Add to playlist -->
          <button (click)="addToPlaylist(currentVideo)" *ngIf="!is_external_company_video" class="font-size-initial"
                  mat-button>
            <mat-icon class="mr-1">playlist_add</mat-icon>
            <span translate>Add</span>
          </button>

        </div>
      </div>

      <mat-card-subtitle class="text-nunito ml-3 mt-2 font-size-initial">
        {{viewCount}}&nbsp;{{viewCount == 1 ? 'Aufruf' : 'Aufrufe' | translate}}&nbsp;•
        {{ "Veröffentlicht am" | translate }}:&nbsp;
        {{ currentVideo.added_on | date: "dd.MM.y" }}
      </mat-card-subtitle>

      <div *ngIf="currentVideo.attachment" class="m-3">
        <p class="fontNormal" style="color: gray; width: 100%">
          {{ "Anhang" | translate }}: <span (click)="downloadAttachment()" [matTooltip]="attachInfo"
                                            class="attachLink">{{ attachFileName }}</span>
        </p>
      </div>
      <div class="row">
        <div class="col-8 p-0">
          <mat-card style="background-color: transparent; box-shadow: 0px 0px 0px 0px">
            <mat-card-header>
              <img (click)="navigateUserPage(currentVideo.uploader.id);"
                   [src]="currentVideo.uploader.userprofile.profile_pic" alt="user image"
                   aria-label="view clypps by this user"
                   class="cursor-pointer"
                   mat-card-avatar>
              <mat-card-subtitle class="fontNormal pl-2">
                <b (click)="navigateUserPage(currentVideo.uploader.id);"
                   [title]="currentVideo.uploader.email"
                   class="text-dark cursor-pointer">
                  {{ currentVideo.uploader.first_name }}&nbsp;{{ currentVideo.uploader.last_name }}
                </b>
                <br/>{{ current_user_dept }}
                <br/>{{ current_user_team }}
              </mat-card-subtitle>
            </mat-card-header>
          </mat-card>
        </div>
      </div>

      <div class="p-2 row flex-column">
        <div (click)="getClickEvent($event);" [innerHTML]="formatted_video_desc" class="video-desc"
             style="font-family: 'nunito'; font-size: medium;">
        </div>
      </div>

      <div *ngIf="!is_external_company_video" class="row"
           style="padding-left: 16px;">
        <mat-chip-list aria-label="tags">
          <mat-chip (click)="tagSelected(tags)" *ngFor="let tags of tagsData" color="#F1F1F1">{{tags.name}}</mat-chip>
        </mat-chip-list>
      </div>

      <div *ngIf="!authService.company?.is_comment_feature_enabled"
           style="text-align-last: center; color: grey;">
        {{'Comments are disabled'|translate}}
      </div>

      <ng-container *ngIf="authService.company?.is_comment_feature_enabled">
        <mat-divider class="mt-2"></mat-divider>
        <div class="row p-2" id="comment-div">
          <div class="col-4 p-0 font-weight-bold fontNormal" style="margin-top: 10px">
            <span>{{ n_display_comments }}&nbsp;</span>
            <span *ngIf="n_display_comments == 1" translate>Kommentar</span>
            <span *ngIf="n_display_comments != 1" translate>Kommentare</span>
          </div>
          <div class="col-4 fontNormal p-0 font-weight-bold" style="width: 100%">
            <button (click)="select.open()" mat-button>
              <mat-icon class="img" style="margin-right: 10px">
                filter_list
              </mat-icon>
              Filter
              <mat-select #select class="privateSelect">
                <mat-option (click)="updateDisplayComments('mine')" class="fontNormal" translate>
                  {{ "Meine Kommentare" | translate }}
                </mat-option>
                <mat-option (click)="updateDisplayComments('all')" class="fontNormal" translate>
                  {{ "Alle Kommentare" | translate }}
                </mat-option>
                <mat-option (click)="updateDisplayComments('private')" *ngIf="is_user_creator"
                            class="fontNormal">
                  {{ "Private Kommentare" | translate }}
                </mat-option>
                <mat-option (click)="updateDisplayComments('public')" *ngIf="is_user_creator"
                            class="fontNormal">
                  {{ "Öffentliche Kommentare" | translate }}
                </mat-option>
              </mat-select>
            </button>
          </div>
          <div class="col-4 p-0 font-weight-bold" style="margin-top: 10px">
            <mat-slide-toggle [(ngModel)]="private_comment_toggle" class="fontNormal"
                              matTooltip="{{'nur für dich und den Videoautor sichtbar' | translate}}">
              {{ "Privat" | translate }}
            </mat-slide-toggle>
          </div>
        </div>

        <div class="row p-2" style="width: 99%">
          <mat-form-field appearance="outline" class="w-100 descTextbox fontNormal"
                          style="border: black; padding: 0px !important">
            <mat-label style="color: black !important" translate>Kommentar hinzufügen</mat-label>
            <textarea #emojiFieldInput [(ngModel)]="comment_text" class="fontNormal" id="comment-editor"
                      matInput rows="5"
                      style="resize: none !important" type="text"></textarea>
            <button (click)="toggleEmojiPicker()" mat-button style="float: right">
              😀
            </button>
          </mat-form-field>
          <emoji-mart (emojiClick)="addEmoji($event.emoji.native);"
                      *ngIf="showEmojiPicker"
                      [hideObsolete]="false" [isNative]="false"
                      [style]="{ position: 'absolute', 'margin-top': '11.5rem', right: '-10px' }"
                      emoji="point_up">
          </emoji-mart>

          <div *ngIf="comment_text" class="button-div">
            <button (click)="cancelComment()" class="mr-3" mat-flat-button style="background-color: lightgray;">
              <span translate>Cancel</span>
            </button>
            <button (click)="postComment()" *ngIf="update_comment_id == 0"
                    [disabled]="processingCall" color="primary" mat-flat-button>
              <!--show send button if user is not editing an existing one-->
              <span translate>Senden</span>
            </button>
            <button (click)="updateComment()" *ngIf="update_comment_id != 0"
                    [disabled]="processingCall" color="primary" mat-flat-button>
              <!--show update button if user is not editing an existing one-->
              <span translate>Update</span>
            </button>
          </div>
        </div>

        <!-- comment section -->
        <!--div to hide existing comments while editing-->
        <div *ngFor="let comment of display_comments">
          <mat-card style="
                          background-color: transparent;
                          box-shadow: 0px 0px 0px 0px;
                          padding: 0px 16px 5px 0px;
                        ">
            <mat-card-header class="styletext" style="width: 100% !important">
              <img [src]="comment.commented_by.userprofile.profile_pic" mat-card-avatar>
              <mat-card-subtitle style="padding-left: 10px">
                <div>
                <span>
                  <b class="fontStyle" style="color: black">
                    {{ comment.commented_by.first_name }}&nbsp;{{ comment.commented_by.last_name }}
                  </b>
                  &nbsp;
                  <span *ngIf="comment.commented_by.userprofile.company != currentVideo.company" class="fontNormal"
                        translate>
                    Außerhalb der Organisation
                  </span>
                  &nbsp;
                  <mat-icon *ngIf="comment.is_private"
                            matTooltip="{{'Nur Sie und der Kommentator können diesen Kommentar sehen' | translate }}"
                            style="
                                          font-size: 20px !important;
                                          bottom: -2px;
                                          position: relative;">
                    lock
                  </mat-icon>
                </span> <span class="fontNormal" style="padding-left: 5px; font-weight: normal">
                  {{utilityService.timeSince(comment.created_on, "ago") | async}}</span>

                  <!--delete comment-->
                  <button (click)="deleteComment(comment)"
                          *ngIf="comment.commented_by.id == authService.userDetails?.user || is_user_creator"
                          mat-icon-button
                          style="float: right">
                    <mat-icon class="material-icons-outlined">delete</mat-icon>
                  </button>

                  <!--edit comment-->
                  <button (click)="editComment(comment);"
                          *ngIf="comment.commented_by.id == authService.userDetails?.user"
                          mat-icon-button style="float: right">
                    <mat-icon class="material-icons-outlined">edit</mat-icon>
                  </button>
                </div>

                <!--show comments-->
                <div (click)="getClickEvent($event);"
                     [innerHTML]="comment.content"
                     class="fontNormal comment-section">
                </div>
              </mat-card-subtitle>
            </mat-card-header>
          </mat-card>
        </div>
        <mat-divider class="mb-2"></mat-divider>
      </ng-container>
    </div>
  </div>

  <!-- col-lg-4 col-md-5 col-12  -->
  <!--large screen: 33%, medium or small screen: 100%-->
  <div class="col-lg-4 col-md-12 col-12 col-sm-12">
    <div *ngIf="playlistVideos.length"
         [ngStyle]="{ height: heightVal + 'px' }"
         [style.max-width]="'400px'" class="mb-5">
      <!--playlistVideos-->
      <div style="height: 100%">
        <div class="mb-1 d-flex align-items-center justify-content-between">
          <div class="d-flex align-items-center">
            <!--show star icon if playlist is featured, else normal icon-->
            <mat-icon *ngIf="!playlist_object.is_featured">
              playlist_play
            </mat-icon>
            <mat-icon *ngIf="playlist_object.is_featured"
                      style="color: gold;"
                      matTooltip="{{'Hervorgehoben'|translate}}"
                      matTooltipPosition="left">
              star
            </mat-icon>
            <b class="fontStyle mr-1 ml-1" translate>
              {{playlist_object.title}}
            </b>
            <span *ngIf="playlist_object.is_draft" class="text-nunito" style="color: red">
              ({{'Privat'|translate}})
            </span>
          </div>

          <!--share button-->
          <div class="d-flex">
            <!--options button for playlist-->
            <button [matMenuTriggerFor]="playlist_menu" mat-icon-button
                    matTooltip="{{'Manage'|translate}}"
                    matTooltipPosition="above">
              <mat-icon>more_horiz</mat-icon>
            </button>
          </div>

          <!--manage menu-->
          <mat-menu #playlist_menu="matMenu">
            <button (click)="copyPlaylistLink();" mat-menu-item>
              <mat-icon class="mr-2">link</mat-icon>
              {{'Copy link'|translate}}
            </button>

            <!-- Download QR Code -->
            <button (click)="downloadQR(internalQR);" mat-menu-item>
              <mat-icon class="mr-2">qr_code</mat-icon>
              {{'Download QR Code'|translate}}
            </button>

            <!--quality manager can feature a playlist-->
            <button (click)="managerPlaylistAction('feature');"
                    *ngIf="!playlist_object.is_featured && authService.userDetails?.is_quality_manager"
                    mat-menu-item>
              <mat-icon class="mr-2">star_outline</mat-icon>
              <span translate>Hervorheben</span>
            </button>

            <!--quality manager can unfeature a playlist-->
            <button (click)="managerPlaylistAction('unfeature');"
                    *ngIf="playlist_object.is_featured && authService.userDetails?.is_quality_manager"
                    mat-menu-item>
              <mat-icon class="mr-2">do_disturb</mat-icon>
              <span translate>Nicht hervorheben</span>
            </button>

            <!--quality manager can draft someone else's playlist-->
            <button (click)="managerPlaylistAction('draft');"
                    *ngIf="!playlist_object.is_draft &&
                            authService.userDetails?.is_quality_manager &&
                            playlist_object.uploader.id != authService.userDetails?.user"
                    mat-menu-item>
              <mat-icon class="mr-2">cloud_off</mat-icon>
              <span translate>Save as private</span>
            </button>

            <!--user can edit their own playlist-->
            <button (click)="editPlaylist();" *ngIf="playlist_object.uploader.id == authService.userDetails?.user"
                    mat-menu-item>
              <mat-icon class="mr-2 material-icons-outlined">edit</mat-icon>
              <span translate>Bearbeiten</span>
            </button>
          </mat-menu>

          <div hidden>
            <qrcode #internalQR [qrdata]="playlist_view_link" allowEmptyString=false colorDark="#000000"
                    elementType="canvas"
                    errorCorrectionLevel="M" imageHeight="75" imageSrc="assets/images/default_overlay.png"
                    imageWidth="75"
                    margin="2" scale="1" width="500"></qrcode>
          </div>
        </div>

        <div class="overflow-auto" style="height: 84%;">
          <mat-grid-list #grid [cols]="1" [gutterSize]="'10px'" rowHeight="4:1">
            <mat-grid-tile *ngFor="let item of playlistVideos;">
              <!-- show number -->
              <span class="playlistIndex fontStyle">{{ item.index }}</span>

              <mat-card (click)="playListVideoClick(item.index)"
                        [ngClass]="videoId == item.video.id ? 'playlistBorder' : 'noBorder'"
                        class="card-mv-style-s1 cursor-pointer" style="padding: 7px; margin-left: 10px">
                <mat-card-header style="min-height: 70px; position: relative">
                  <!-- w-50 -->
                  <img [src]="item.video.thumbnail" class="w-25"
                       style="object-fit: contain; margin-top: -15px">
                  <div class="ml-2" style="width: 65%">
                    <!--show draft if video is draft-->
                    <b class="text-nunito mb-2" style="
                        display: -webkit-box;
                        overflow: hidden;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;">
                      <span *ngIf="item.video.is_draft" style="color: red">({{'Draft'|translate}})</span>
                      {{item.video.title}}
                    </b>
                    <mat-card-subtitle class="text-nunito">
                      {{ item.video.uploader.first_name }}&nbsp;{{ item.video.uploader.last_name }}
                      <br>
                      <span *ngIf="item.video.views == 1">
                        {{ item.video.views | number }}&nbsp;{{ "Aufruf" | translate }}&nbsp;{{utilityService.timeSince(item.video.added_on) | async}}
                      </span>
                      <span *ngIf="item.video.views != 1">
                        {{ item.video.views | number }}&nbsp;{{ "Aufrufe" | translate }}&nbsp;{{utilityService.timeSince(item.video.added_on) | async}}
                      </span>
                    </mat-card-subtitle>
                  </div>
                </mat-card-header>
              </mat-card>
            </mat-grid-tile>
          </mat-grid-list>
        </div>

        <div *ngIf="playlistVideos.length">
          <mat-checkbox [(ngModel)]="isAutoplay" class="w-100 text-wrap d-flex justify-content-center" color="primary">
            <span class="fontNormal" translate>
              Nächstes Video automatisch abspielen
            </span>
          </mat-checkbox>
          <hr class="hrColor"/>
        </div>
      </div>
    </div>

    <!--related videos-->
    <div class="pt-2">
      <svg fill="none" height="25" viewBox="0 0 60 30" width="25" xmlns="http://www.w3.org/2000/svg">
        <path d="M60 0H33V6H60V0Z" fill="black"/>
        <path d="M60 24H33V30H60V24Z" fill="black"/>
        <path d="M60 12H42V18H60V12Z" fill="black"/>
        <path d="M33 15L18 0V12H0V18H18V30L33 15Z" fill="black"/>
      </svg>

      <b class="fontStyle pl-2" translate>Related content</b>

      <div class="mt-2">
        <mat-card-subtitle *ngIf="related_videos.length + related_playlists.length == 0"
                           [hidden]="loading_related_content" class="text-nunito mt-2" translate>
          Nothing to see yet
        </mat-card-subtitle>

        <div class="d-flex flex-wrap justify-content-start">
          <!--show related playlists-->
          <mat-card *ngFor="let pl of related_playlists"
                    class="related-video-card align-items-baseline"
                    (click)="relatedPlaylistClicked(pl.id);">
            <mat-card-header style="display: contents;">
              <div [style.background-image]="'url(' + pl.thumbnail + ')'"
                   class="div-card-thumbnail d-flex justify-content-end">
                <div class="bg-dark d-flex flex-column justify-content-around h-100 w-25 text-white text-center"
                     style="border-top-right-radius: 10px; border-bottom-right-radius: 10px;">
                  <span>{{ pl.video_count }}</span>
                  <span><mat-icon>playlist_play</mat-icon></span>
                </div>
              </div>

              <div class="w-50">
                <mat-card-subtitle class="related-video-title fontStyle">{{ pl.title }}</mat-card-subtitle>
                <mat-card-subtitle style="padding-left: 10px">
                  {{ pl.uploader.first_name }}&nbsp;{{ pl.uploader.last_name }}<br/>
                  <span>
                    Playlist
                  </span>
                </mat-card-subtitle>
              </div>
            </mat-card-header>
          </mat-card>

          <!--show related videos-->
          <mat-card (click)="relatedVideoCLicked(video.id)"
                    (mouseout)="mouseouted(video)" (mouseover)="mouseovered(video)"
                    *ngFor="let video of related_videos"
                    class="related-video-card align-items-baseline">
            <mat-card-header>
              <div [style.background-image]="'url(' + video.thumbnail + ')'" class="div-card-thumbnail"
                   id="left">
                <div *ngIf="video.duration" class="video-block-duration">
                  <span>{{ video.duration | vidDurationFormat }}</span>
                </div>
              </div>

              <div class="w-50" id="right">
                <mat-card-subtitle class="related-video-title fontStyle">{{ video.title }}</mat-card-subtitle>
                <mat-card-subtitle style="padding-left: 10px">
                  {{ video.uploader.first_name }}&nbsp;{{ video.uploader.last_name }}<br/>
                  <span *ngIf="video.views == 1" translate>
                    {{ video.views | number }}&nbsp; {{ "Aufruf" | translate }}&nbsp; {{utilityService.timeSince(video.added_on) | async}}
                  </span>
                  <span *ngIf="video.views > 1 || video.views == 0" translate>
                    {{ video.views | number }}&nbsp; {{ "Aufrufe" | translate }}&nbsp; {{utilityService.timeSince(video.added_on) | async}}
                  </span>
                </mat-card-subtitle>
              </div>
            </mat-card-header>
          </mat-card>

          <!--related content progress card-->
          <div class="related-video-card justify-content-center" *ngIf="loading_related_content">
            <mat-spinner mode="indeterminate" diameter="30"></mat-spinner>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
