<h2 mat-dialog-title class="fontStyle">
  {{ data.length }}&nbsp;{{ data.length == 1 ? ('Response' | translate) : ('Responses' | translate) }}
</h2>

<!--name & next-->
<div class="m-2 d-flex justify-content-between flex-wrap align-items-center">
  <!--input-->
  <mat-form-field appearance="outline">
    <mat-select [(ngModel)]="step">
      <mat-option *ngFor="let answer of data; let i = index;" [value]="i">
        {{ getUploaderName(answer.uploader) }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <!--text and page buttons-->
  <div class="d-flex align-items-center">
    <span class="text-nunito">{{ step + 1 }}&nbsp;{{ 'von'|translate }}&nbsp;{{ data.length }}</span>
    <button (click)="prevStep();" [disabled]="step==0" mat-icon-button>
      <mat-icon>navigate_before</mat-icon>
    </button>
    <button (click)="nextStep();" [disabled]="step==data.length - 1" mat-icon-button>
      <mat-icon>navigate_next</mat-icon>
    </button>
  </div>
</div>

<!--show response-->
<mat-expansion-panel expanded hideToggle disabled class="bg-primary-555">
  <mat-expansion-panel-header class="text-dark">
    <mat-panel-title>
      {{ getUploaderName(data[step].uploader) }}
    </mat-panel-title>
    <mat-panel-description>
      <mat-icon>
        {{ data[step].is_correct ? 'check_circle_outline' : 'highlight_off' }}
      </mat-icon>
    </mat-panel-description>

    <button (click)="deleteAnswer(data[step]);" mat-icon-button>
      <mat-icon class="mr-2">delete_outline</mat-icon>
    </button>
  </mat-expansion-panel-header>

  <div [innerHTML]="getAnswerHtml(data[step].text)" class="mt-2 text-nunito">
  </div>

  <div *ngIf="data[step].supporting_file" mat-line>
    <a [href]="data[step].supporting_file" target="_blank">
      {{ getAttachedFilename(data[step].supporting_file) }}
    </a>
  </div>
</mat-expansion-panel>
