<div class="d-flex justify-content-center">
  <mat-card (mouseout)="mouseouted()" (mouseover)="mouseovered()"
            class="card-mv-style-s1 cursor-pointer" style="width: 320px; height: 300px">
    <a (click)="viewVideo();" class="text-decoration-none">
      <div [style.background-image]="'url(' + thumbnail + ')'"
           class="div-card-thumbnail">
        <div class="iconoverlay">
          <div class="video-avatar">
            <div [style.background-image]="'url(' + videoObject.uploader.userprofile.profile_pic + ')'"
                 [title]="videoObject.uploader.email"
                 mat-card-avatar
                 matTooltip="{{ videoObject.uploader.first_name }} {{ videoObject.uploader.last_name }}"
                 matTooltipPosition="right"
                 style="background-size: cover;">
            </div>
          </div>

          <div *ngIf="show_language_label" class="video-block-duration" style="left: 70%;">
            <mat-icon class="h-auto w-auto mr-1" style="font-size: initial;">language</mat-icon>
            <span>{{ videoObject.audio_language.slice(0, 2).toUpperCase() }}</span>
          </div>

          <div class="video-block-duration">
            <span>{{ videoObject.duration | vidDurationFormat }}</span>
          </div>
        </div>
      </div>

      <mat-card-footer>
        <mat-card-subtitle [title]="videoObject.title" class="title fontStyle">
          <mat-icon *ngIf="videoObject.is_featured" class="material-icons-outlined"
                    style="color: gold; vertical-align: middle;">star
          </mat-icon>
          {{ videoObject.title }}
        </mat-card-subtitle>
        <mat-card-subtitle *ngIf="show_external_label" class="text-nunito" style="font-size: 16px;">
          {{ 'Partner content'|translate }}
        </mat-card-subtitle>
        <mat-card-subtitle class="fontNormal fontcolor" style="font-size: 16px;">
          <span *ngIf="is_external_views_shown"
                title="{{'Erstellt am'|translate}} {{videoObject.added_on | date: 'dd.MM.yyyy'}}">
            {{ videoObject.views | number }}
            {{ videoObject.views == 1 ? ('Aufruf'|translate) : ('Aufrufe'|translate) }}
            {{ utilityService.timeSince(videoObject.added_on) | async }}
          </span>
          <span *ngIf="!is_external_views_shown"
                title="{{'Erstellt am'|translate}} {{videoObject.added_on | date: 'dd.MM.yyyy'}}">
            {{ utilityService.timeSince(videoObject.added_on, 'ago') | async }}
          </span>
          <span style="vertical-align: middle;height: 30px;">
            <mat-icon *ngIf="videoObject.is_held_for_review" mat-list-icon
                      title="{{ 'Wird überprüft' | translate }}">
              pending_actions
            </mat-icon>&nbsp;
            <mat-icon *ngIf="videoObject.is_external && !videoObject.is_access_key_needed" mat-list-icon
                      title="{{'Public' | translate}}">
              public
            </mat-icon>
            <mat-icon *ngIf="videoObject.is_external && videoObject.is_access_key_needed" mat-list-icon
                      title="{{'Unlisted' | translate}}">
              vpn_lock
            </mat-icon>
            <mat-icon *ngIf="videoObject.is_restricted" mat-list-icon
                      title="{{ 'Restricted visibility inside the workspace' | translate }}">
              network_locked
            </mat-icon>
          </span>
        </mat-card-subtitle>
      </mat-card-footer>
    </a>
  </mat-card>
</div>
