import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit {
  toggle_value: boolean = true;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: [string, string, boolean, string],
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    ) {
    // data format: 0: title, 1: subtitle, 2: show additional checkbox or not, 3: additional checkbox string
  }

  ngOnInit(): void {
  }

  closeDialog(confirmation: boolean) {
    this.dialogRef.close([confirmation, this.toggle_value]);
  }

}
