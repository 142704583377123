<!--infinite scroll-->
<div (scrolled)="onScrollDown()" [fromRoot]="true" [infiniteScrollContainer]="'mat-sidenav-content'" [infiniteScrollDistance]="1" [infiniteScrollThrottle]="500"
     [scrollWindow]="false" class="company-wise-video" infiniteScroll>

  <section style="background: linear-gradient(180deg, #F1F2F4 0%, rgba(244, 244, 244, 0) 100%);">

    <!--title and sort-->
    <div class="pt-2 pl-4 pr-4 mb-0 d-flex justify-content-between align-items-baseline"
         style="flex-flow: wrap; min-height: 87px;">
      <!--min height is needed due to sort field-->
      <h1 class="fontStyle align-self-center" translate>
        Welcome! Explore our content
      </h1>

      <mat-form-field *ngIf="video_case" appearance="outline">
        <mat-label translate>Sort</mat-label>
        <mat-select (selectionChange)="this.getPublicVideos(1);" [(value)]="sort_option"
                    class="sort-input" style="color: #F4F4F4; line-height:1.5rem">
          <mat-option value="added_on"><span translate>Neueste Clypps</span></mat-option>
          <mat-option value="up_votes"><span translate>Beliebteste Clypps</span></mat-option>
          <mat-option value="views"><span translate>Most viewed</span></mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <!--search field-->
    <mat-form-field appearance="outline" class="w-100 pl-4 pr-4 mb-2" style="line-height: 2rem;">
      <mat-label>{{'Search public content of' | translate}}&nbsp;{{authService.company?.name}}…</mat-label>
      <input [(ngModel)]="search_text" autocomplete="off" matInput maxlength="50"
             placeholder="{{'Search for…' | translate}}" id="search_input">
      <button (click)="getPublicVideos(1);" id="search_icon" mat-icon-button matSuffix style="color: gray">
        <mat-icon>search</mat-icon>
      </button>
    </mat-form-field>
  </section>

  <!--  Chips  -->
  <div class="pl-4 pr-4">
    <mat-chip-list aria-label="tags">
      <mat-chip (click)="getPublicVideos(1)" [selected]="video_case" style="height: 35px;">
        <mat-icon style="font-size: large; place-self: baseline;">play_circle_outline</mat-icon>
        {{'All Clypps' | translate}}
      </mat-chip>

      <mat-chip (click)="getTagVideos(tag.id)" *ngFor="let tag of featured_tags; let index = index;"
                [selected]="tag.id == tag_id" style="height: 35px;">
        {{tag.name}}
      </mat-chip>
    </mat-chip-list>
  </div>

  <div class="p-3 mb-4 d-flex justify-content-center" style="flex-flow: wrap;">
    <!--video card grid-->
    <h3 translate *ngIf="videos.length == 0 && !showSpinner">
      No results. Potentially search for other terms.
    </h3>
    <app-video-card *ngFor="let video of videos"
                    [is_external_views_shown]="authService.company.is_external_views_shown"
                    [mode]="'public'"
                    routeUrl="/publicVideoView" [videoObject]="video"
                    style="max-width: 350px;">
    </app-video-card>
  </div>

  <!--spinner loader-->
  <div *ngIf="showSpinner" class="d-flex justify-content-center">
    <mat-spinner color="primary"></mat-spinner>
  </div>
</div>

<footer id="fixed-footer" class="w-100">
  <div style="display: flex; justify-content: space-evenly; max-width: 400px; color: darkgray" class="mt-2">
    <a class="mr-1 text-dark" href="{{ 'WEB_URL' | translate }}" target="_blank" translate>Website</a>
    <a class="mr-1 text-dark" href="{{ 'IMPRESSUM_URL' | translate }}" target="_blank" translate>Imprint</a>
    <a class="mr-1 text-dark" href="{{ 'POLICY_URL' | translate }}" target="_blank" translate>Privacy</a>
  </div>
  <p> © Zesavi GmbH 2024</p>
</footer>
