<div #scroll class="w-100">
  <!--top block with name, logo and search-->
  <div class="header w-100 d-flex justify-content-between position-sticky bg-white align-items-center">
    <img [src]="authService.company?.company_logo" alt="company logo" class="ml-3 d-none d-md-inline cursor-pointer p-1"
         (click)="goBack();"
         onerror="this.onerror=null; this.src='assets/logo/clypp_logo_small.png'"
         style="max-height: 60px; max-width: 200px;">

    <!--search field-->
    <div style="width: 30%;" class="px-3">
      <mat-form-field appearance="outline" class="font-size-initial w-100" style="font-size: initial; margin-top: -6px;">
        <input (keyup.enter)="searchContent();" [(ngModel)]="searchQuery" autocomplete="off" autofocus cdkFocusInitial
               matInput placeholder="{{'Search your organization\'s content…'|translate}}">
        <mat-icon (click)="searchContent();" class="cursor-pointer" matSuffix>search</mat-icon>
      </mat-form-field>
    </div>

    <!--create & user profile-->
    <div *ngIf="!userContentCase" class="d-flex inherit-height mr-3 align-items-center">
      <div *ngIf="authService.currentUser?.is_profile_completed && !userContentCase" class="d-flex justify-content-end "
           style="height: fit-content;">

        <!--Show it only in small screen when user is not a creator-->
        <button (click)="navbarService.setCreatorPopup();" *ngIf="!authService.currentUser?.is_creator"
                class="border-radius-20 text-dark fontNormal" color="primary" mat-stroked-button>
          <mat-icon class="mr-1">add</mat-icon>
          <span translate="Erstellen"></span>
        </button>

        <!--Show it only in small screen when user is a creator-->
        <button *ngIf="authService.currentUser?.is_creator" [matMenuTriggerFor]="createMenu"
                class="border-radius-20 userButton fontNormal" color="primary" mat-flat-button>
          <mat-icon class="mr-2">open_in_new</mat-icon>
          <span translate="Erstellen"></span>
        </button>

        <mat-menu #createMenu="matMenu">
          <button (click)="navbarService.createPRVideo();" mat-menu-item>
            <mat-icon class="material-icons-outlined">radio_button_checked</mat-icon>
            <span class="text-nunito" translate>New Clypp</span>
          </button>
          <button (click)="navbarService.createTopic()" mat-menu-item>
            <mat-icon class="material-icons-outlined">post_add</mat-icon>
            <span class="text-nunito" translate>New Page</span></button>
          <button (click)="navbarService.addPlaylist()" mat-menu-item>
            <mat-icon>playlist_add</mat-icon>
            <span class="text-nunito" translate>Neue Playlist</span></button>
          <button (click)="navbarService.openVideoRequestDialog()" mat-menu-item>
            <mat-icon class="material-icons-outlined">add_comment</mat-icon>
            <span class="text-nunito" translate>New Request</span></button>
        </mat-menu>
      </div>

      <div [matMenuTriggerFor]="menu" class="ml-2 avatar-fab cursor-pointer" matTooltip="{{'Mein Profil' | translate}}">
        <img [src]="authService.currentUser?.profile_pic" alt="profile picture"
             onerror="this.onerror=null; this.src='https://avatar-service-platform.personio.de/'"/>
      </div>
      <mat-menu #menu="matMenu">
        <a class="text-decoration-none" href="user_profile" mat-menu-item target="_blank">
          <mat-icon class="material-icons-outlined">manage_accounts</mat-icon>
          <span class="font-size-16" translate>My Account</span>
        </a>
        <button (click)="openFeedbackDialog();" mat-menu-item>
          <mat-icon class="material-icons-outlined">feedback</mat-icon>
          <span class="font-size-16" translate>Contact Support</span>
        </button>
        <mat-divider></mat-divider>
        <a class="text-decoration-none" href="https://clypp.app/public/537" mat-menu-item target="_blank">
          <mat-icon class="material-icons-outlined">dvr</mat-icon>
          <span class="font-size-16" translate>Video Tutorials</span>
        </a>
        <a class="text-decoration-none" href="https://clypp.app/pages/view/8d1130a3-0186-4e03-ac75-8649359666a6"
           mat-menu-item
           target="_blank">
          <mat-icon class="material-icons-outlined">live_help</mat-icon>
          <span class="font-size-16" translate>Help Center</span>
        </a>
        <a class="text-decoration-none" href="https://clypp.app/pages/view/4bfbe70d-c5b6-4cf6-a738-e51f94fd7cad"
           mat-menu-item
           target="_blank">
          <mat-icon class="material-icons-outlined">new_releases</mat-icon>
          <span class="font-size-16" translate>Neuigkeiten</span>
        </a>
      </mat-menu>
    </div>
  </div>

  <!-- show spinner -->
  <mat-progress-bar *ngIf="showSpinner" mode="indeterminate"></mat-progress-bar>

  <button (click)="goBack();" *ngIf="view_case != 'internal'" class="border-radius-20 ml-5 mt-4"
          mat-stroked-button>
    <mat-icon class="mr-1">
      chevron_left
    </mat-icon>
    {{ "Zurück" | translate }}
  </button>

  <ng-container [ngSwitch]="view_case">
    <!-- home page -->
    <ng-container *ngSwitchCase="'internal'">
      <!-- greetings -->
      <mat-card-title [title]="authService.currentUser?.email" class="fontStyle pt-4 mx-4">
        {{ greetings_text }},&nbsp;{{ authService.currentUser?.firstName }}&nbsp;👋
      </mat-card-title>

      <!-- 4 blocks -->
      <div *ngIf="userActivityResp" class="d-flex flex-wrap justify-content-around m-3">
        <!--Your activities-->
        <div class="d-flex flex-wrap justify-content-around" style="min-width: 50%;">
          <div class="simple-card" style="margin-top: 10px;">
            <p class="text-nunito mt-4" style="font-size:large;" translate>
              Your activities
            </p>
            <div class="d-flex mt-4">
              <mat-icon class="material-symbols-outlined" color="primary"
                        style="font-size: 35px;width: 35px;height: 35px;">
                sentiment_very_satisfied
              </mat-icon>
              <div class="fontStyle ml-2 align-self-center" style="font-size: xx-large;">
                {{ userActivityResp.n_7_days }}
              </div>
            </div>
            <div *ngIf="increasePercentage" class="mt-4">
              <!--hide in case of 0-->
              <div *ngIf="increasePercentage > 0" class="d-flex align-items-center" style="font-size:large;">
                <mat-icon class="mr-1" style="color: mediumseagreen;">arrow_upward</mat-icon>
                <span style="color:mediumseagreen;">{{ increasePercentage }}%</span>
                <span class="fontNormal ml-2" translate>more than last 7 days</span>
              </div>
              <div *ngIf="increasePercentage < 0" class="d-flex align-items-center" style="font-size:large;">
                <mat-icon class="mr-1" color="warn">arrow_downward</mat-icon>
                <!--todo: show absolute value-->
                <span style="color:red;">{{ increasePercentage }}%</span>
                <span class="fontNormal ml-2" translate>less than last 7 days</span>
              </div>
            </div>
          </div>

          <!-- Days Active -->
          <div class="simple-card">
            <p class="text-nunito mt-4" style="font-size:large;" translate>
              Days active
            </p>
            <span class="text-nunito mt-4">
            <mat-icon color="primary" style="vertical-align: bottom;">
              event_available
            </mat-icon>
              {{ active_days_label.length }}&nbsp;{{ 'of the last 7 days'|translate }}
          </span>
            <div class="d-flex mt-4">
              <div *ngFor="let i of seven_days_label; let index = index;"
                   class="d-flex flex-column align-items-center mx-1">
                <span class="text-nunito">{{ i|translate }}</span>
                <mat-icon *ngIf="!active_days_label.includes(i)" color="primary">radio_button_unchecked</mat-icon>
                <mat-icon *ngIf="active_days_label.includes(i) && index != 6" color="primary">check_circle</mat-icon>
                <mat-icon *ngIf="index == 6" style="color: goldenrod;">check_circle</mat-icon>
              </div>
            </div>
          </div>
        </div>

        <!-- Your Workspace & Your Content -->
        <div class="d-flex flex-wrap justify-content-around" style="min-width: 50%;">
          <div class="simple-card">
            <mat-button-toggle-group (change)="onChartChange();" [(ngModel)]="chart_button_toggle"
                                     aria-label="button toggle" class="w-100" style="border-radius: 10px 10px 0px 0px;">
              <!--if user is a creator, then show 50%, else 100% width of first button-->
              <mat-button-toggle
                [ngClass]="authService.currentUser.is_creator ? 'w-fill-available' : 'w-100 bg-transparent'"
                value="all">
                <span class="text-nunito font-medium" translate>Your workspace</span>
              </mat-button-toggle>
              <!--hidden for non creators-->
              <mat-button-toggle *ngIf="authService.currentUser.is_creator" class="w-fill-available" value="mine">
                <span class="text-nunito font-medium" translate>Your content</span>
              </mat-button-toggle>
            </mat-button-toggle-group>
            <div class="bg-white d-flex" style="height: 160px;">
              <div class="text-center position-absolute" style="width: 205px; margin-top: 63px;">
                <h2 *ngIf="chart_button_toggle=='all'" class="text-nunito m-0">
                  <b>
                    {{ userActivityResp.n_all_topics + userActivityResp.n_all_playlists + userActivityResp.n_all_videos }}
                  </b>
                </h2>
                <h2 *ngIf="chart_button_toggle=='mine'" class="text-nunito m-0">
                  <b>
                    {{ userActivityResp.n_my_topics + userActivityResp.n_my_videos + userActivityResp.n_my_playlists }}
                  </b>
                </h2>
              </div>
              <canvas [ngClass]="chart_button_toggle=='all' ? 'd-block' : 'd-none'" class="p-2" id="canvas1"
                      style="z-index: 1;"></canvas>
              <canvas [ngClass]="chart_button_toggle=='mine' ? 'd-block' : 'd-none'" class="p-2" id="canvas2"
                      style="z-index: 1;"></canvas>
            </div>
          </div>

          <!-- Explore New Content -->
          <div class="simple-card">
            <p class="text-nunito mt-4" style="font-size:large;" translate>
              Explore new content
            </p>
            <div class="w-100" style="overflow-x: hidden;">
              <button (click)="userSelected(card);" *ngFor="let card of recent_users"
                      class="my-2 d-flex align-items-center" mat-menu-item>
                <img [alt]="card.email" [src]="card.userprofile.profile_pic" aria-label="view clypps by this user"
                     class="cursor-pointer mr-3" mat-card-avatar>
                <div class="d-flex flex-column line-height-initial">
                  <mat-card-title [title]="card.email" class="fontNormal"
                                  style="text-overflow: ellipsis; overflow: hidden; white-space: nowrap;">
                    {{ card.first_name }}&nbsp;{{ card.last_name }}
                  </mat-card-title>
                  <mat-card-subtitle class="text-nunito mb-1" title="{{'in the last 30 days'|translate}}">
                    {{ card.n_videos_30_days }}
                    {{ card.n_videos_30_days == 1 ? ('new Clypp' | translate) : ('new Clypps' | translate) }}
                    <!--todo: use pages-->
                    <!--{{card.n_videos_30_days}}&nbsp;
                  {{card.n_videos_30_days == 1 ? ('Page'|translate) : ('Pages'|translate)}}-->
                  </mat-card-subtitle>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>

      <!--Tags-->
      <div class="chip-list m-3 p-3">
        <mat-chip-list aria-label="tags">
          <mat-chip (click)="partnerSelected(partner);" *ngFor="let partner of partner_companies">
            {{ partner.sharing_company.name }}
          </mat-chip>
          <mat-chip (click)="tagSelected(tag);" *ngFor="let tag of tags">
            {{ tag.name }}
          </mat-chip>
        </mat-chip-list>
      </div>

      <!-- featured -->
      <div *ngIf="mixed_featured_content.length" class="border-radius-10 m-3 p-3 bg-primary-555">
        <div class="d-flex justify-content-between align-items-center">
          <h2 class="fontStyle d-flex align-items-center m-0">
            <mat-icon class="material-icons-outlined mr-2" color="primary">grade</mat-icon>
            {{ 'Hervorgehoben'|translate }}
          </h2>
        </div>

        <div class="left-right-div" id="featured-content">
          <ng-container *ngFor="let card of mixed_featured_content">
            <app-video-card *ngIf="card.type == 'video'" [mode]="'popup'" [videoObject]="card.data"
                            style="margin-right: 20px; max-width: 320px;">
            </app-video-card>
            <app-topic-card *ngIf="card.type == 'topic'" [topic_object]="card.data"
                            mode="popup" style="margin-right: 20px; max-width: 320px;">
            </app-topic-card>
          </ng-container>

          <!--button 1 with absolute position to the left-->
          <button (click)="scrollToLeftRight('featured-content',true);" class="left-right-button left-scroll-button"
                  mat-button>
            <mat-icon class="text-white">
              chevron_left
            </mat-icon>
          </button>
          <!--button 2 with absolute position to the left-->
          <button (click)="scrollToLeftRight('featured-content');" class="left-right-button right-scroll-button"
                  mat-button>
            <mat-icon class="text-white">
              chevron_right
            </mat-icon>
          </button>
        </div>
      </div>

      <!-- continue watching-->
      <div *ngIf="history_videos.length"
           class="border-radius-10 m-3 p-3 bg-primary-555">
        <div class="d-flex justify-content-between align-items-center">
          <h2 class="fontStyle d-flex align-items-center m-0">
            <mat-icon class="material-icons-outlined mr-2" color="primary">history</mat-icon>
            {{ 'Continue watching'|translate }}
          </h2>
        </div>

        <div class="left-right-div" id="history">
          <app-video-card-history (click)="playVideo(video.video['id'])" *ngFor="let video of history_videos;"
                                  [backendUrl]="backendUrl" [disableClick]="true" [videoObject]="video"
                                  style="margin-right: 20px; max-width: 320px;">
          </app-video-card-history>

          <!--button 1 with absolute position to the left-->
          <button (click)="scrollToLeftRight('history',true);" *ngIf="history_videos.length > 4"
                  class="left-right-button left-scroll-button" mat-button>
            <mat-icon class="text-white">
              chevron_left
            </mat-icon>
          </button>

          <!--button 2 with absolute position to the left-->
          <button (click)="scrollToLeftRight('history');" *ngIf="history_videos.length > 4"
                  class="left-right-button right-scroll-button" mat-button>
            <mat-icon class="text-white">
              chevron_right
            </mat-icon>
          </button>
        </div>
      </div>

      <!--Latest Clypps -->
      <div *ngIf="latest_videos.length" class="border-radius-10 m-3 p-3 bg-primary-555">
        <div class="d-flex justify-content-between align-items-center">
          <h2 class="fontStyle d-flex align-items-center m-0">
            <mat-icon class="material-icons-outlined mr-2" color="primary">play_circle</mat-icon>
            {{ 'Latest Clypps for you'|translate }}
          </h2>
        </div>

        <!--latest clypps-->
        <div class="left-right-div" id="latest-clypps">
          <app-video-card *ngFor="let video of latest_videos" [mode]="'popup'"
                          [videoObject]="video" style="margin-right: 20px; max-width: 320px;">
          </app-video-card>

          <!--button 1 with absolute position to the left-->
          <button (click)="scrollToLeftRight('latest-clypps',true);" *ngIf="latest_videos.length > 4"
                  class="left-right-button left-scroll-button" mat-button>
            <mat-icon class="text-white">
              chevron_left
            </mat-icon>
          </button>
          <!--button 2 with absolute position to the left-->
          <button (click)="scrollToLeftRight('latest-clypps');" class="left-right-button right-scroll-button"
                  mat-button>
            <mat-icon class="text-white">
              chevron_right
            </mat-icon>
          </button>
        </div>
      </div>

      <!-- explore pages -->
      <div *ngIf="all_topics.length" class="border-radius-10 m-3 p-3 bg-primary-555">
        <div class="d-flex justify-content-between align-items-center">
          <h2 class="fontStyle d-flex align-items-center m-0">
            <mat-icon class="material-icons-outlined mr-2" color="primary">article</mat-icon>
            {{ 'Explore pages from others'|translate }}
          </h2>
        </div>
        <div class="d-flex justify-content-start left-right-div" id="newest-topics">
          <app-topic-card *ngFor="let topic of all_topics;"
                          [topic_object]="topic" mode="popup" style="margin-right: 20px; max-width: 320px;">
          </app-topic-card>

          <!--button 1 with absolute position to the left-->
          <button (click)="scrollToLeftRight('newest-topics', true);" class="left-right-button left-scroll-button"
                  mat-button>
            <mat-icon class="text-white">
              chevron_left
            </mat-icon>
          </button>

          <!--button 2 with absolute position to the left-->
          <button (click)="scrollToLeftRight('newest-topics');" class="left-right-button right-scroll-button"
                  mat-button>
            <mat-icon class="text-white">
              chevron_right
            </mat-icon>
          </button>
        </div>
      </div>
    </ng-container>

    <!--search case-->
    <ng-container *ngSwitchCase="'search'">
      <app-searched-content [search_text]="searchQuery" mode="popup"></app-searched-content>
    </ng-container>

    <ng-container *ngSwitchCase="'user'">
      <app-user-view [user_id]="user_id"></app-user-view>
    </ng-container>

    <ng-container *ngSwitchCase="'tag'">
      <app-tags-view [tag_id]="tag_id"></app-tags-view>
    </ng-container>

    <ng-container *ngSwitchCase="'friend'">
      <app-friend-organisation-view [partnership_id]="partnership_id"></app-friend-organisation-view>
    </ng-container>

  </ng-container>
</div>

<!--scroll top button-->
<div *ngIf="mixed_tags_content.length" class="scroll-to-top">
  <button (click)="scrollTop()" mat-fab>
    <mat-icon>vertical_align_top</mat-icon>
  </button>
</div>
