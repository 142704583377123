import {Component, Input, OnInit, ViewChild,} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {DataService} from '../../services/data.service';
import {MatDialog} from '@angular/material/dialog';
import {NavbarService} from '../../services/navbar.service';
import {AuthService} from '../../services/auth.service';
import {UserDetailsDialogComponent} from 'src/app/shared/user-details-dialog/user-details-dialog.component';
import {LoadUserCsvComponent} from '../../shared/load-user-csv/load-user-csv.component';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';
import {SelectionModel} from '@angular/cdk/collections';
import {UserProfile} from "../../models/user/userprofile";
import {DomSanitizer, SafeUrl} from "@angular/platform-browser";
import {UploaderView} from "../../models/video/video.interface";
import {HttpErrorResponse} from "@angular/common/http";
import {ConfirmDialogComponent} from "../../shared/confirm-dialog/confirm-dialog.component";


export interface ManagerUser {
  department: number;
  email_language: string;
  id: number;
  is_approved: boolean;
  is_company_manager: boolean;
  is_creator: boolean;
  is_department_manager: boolean;
  is_global_manager: boolean;
  is_marketing_accepted: boolean;
  is_privacy_accepted: boolean;
  is_profile_completed: boolean;
  is_quality_manager: boolean;
  is_team_manager: boolean;
  last_edited_on: string;
  location: number;
  profile_pic: string;
  team: number;
  user_role: number;
  user: UploaderView;
}


@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.scss'],
})
export class UserManagementComponent implements OnInit {
  @Input()
  defaultSearch: any;
  @Input()
  current_user: UserProfile = null;
  dataSource: MatTableDataSource<ManagerUser>;
  selection = new SelectionModel<any>(true, []);
  displayedColumns: string[] = [
    'select',
    'profile_pic',
    'name_email',
    'dept_team',
    'is_approved',
    'is_creator',
    'is_global_manager',
    'is_quality_manager',
    'user.last_login',
  ];

  @ViewChild(MatSort) sort: MatSort;
  search_text: string = '';
  teamsManagementText: string = "";
  deptManagementText: string = "";
  allUsers: ManagerUser[] = [];
  download_users_uri: SafeUrl = "";
  authorised: boolean = false;
  showSpinner: boolean = false;
  bulkOperationDropdown: {key: string, value: string}[] = [];
  rights_display: {right_string: string, color_string: string, tooltip: string}[] = [];

  constructor(
    public dialog: MatDialog,
    private navbarService: NavbarService,
    public dataService: DataService,
    private translateService: TranslateService,
    public snackBar: MatSnackBar,
    public authService: AuthService,
    private sanitizer: DomSanitizer,
  ) {
    this.current_user = this.authService.userDetails;

    this.translateService
      .get('Dept_Management', {
        val_1: this.authService.company.dept_alias,
      })
      .subscribe((res: string) => {
        this.deptManagementText = res;
      });

    this.translateService
      .get('Teams_Management', {
        val_1: this.authService.company.team_alias,
      })
      .subscribe((res: string) => {
        this.teamsManagementText = res;
      });

    this.bulkOperationDropdown = [
      {key: 'email', value: this.translateService.instant('Email senden')},
      {key: 'unblock', value: this.translateService.instant('Unblock')},
      {key: 'block', value: this.translateService.instant('Block')},
      {key: 'logout', value: this.translateService.instant('Logout')},
      {key: 'creator', value: this.translateService.instant('Give creator rights')},
      {key: 'privacy', value: this.translateService.instant('Revoke privacy policy acceptance')},
      {key: 'delete', value: this.translateService.instant('Löschen')}
    ];
  }

  ngOnInit(): void {
    this.navbarService.getUserProfileDetails();
    this.authorised = this.current_user.is_team_manager || this.current_user.is_department_manager ||
      this.current_user.is_company_manager || this.current_user.is_global_manager;
    if(this.authorised){
      this.loadUsers();
    }

    // initiate the labels
    if (this.current_user.is_global_manager){
      this.rights_display.push({
        right_string: this.translateService.instant('Globale Administration'),
        color_string: 'lightslategrey',
        tooltip: this.translateService.instant('Du kannst alle Einstellungen für die gesamte Organisation ändern.')
      });
    } else if (this.current_user.is_company_manager){
      this.rights_display.push({
        right_string: this.translateService.instant('Organisationsverwaltung'),
        color_string: 'burlywood',
        tooltip: this.translateService.instant('Du kannst alle Einstellungen in Bezug auf Nutzer/innen ändern.')
      });
    } else if (this.current_user.is_department_manager){
      this.rights_display.push({
        right_string: this.deptManagementText,
        color_string: 'burlywood',
        tooltip: this.translateService.instant('Du kannst alle Einstellungen in Bezug auf Nutzer/innen in deiner Abteilung ändern.')
      });
    } else if (this.current_user.is_team_manager){
      this.rights_display.push({
        right_string: this.teamsManagementText,
        color_string: 'burlywood',
        tooltip: this.translateService.instant('Du kannst alle Einstellungen in Bezug auf Nutzer/innen in deinem Team ändern.')
      });
    }

    if (this.current_user.is_quality_manager){
      this.rights_display.push({
        right_string: this.translateService.instant('Videoverwaltung'),
        color_string: 'mediumseagreen',
        tooltip: this.translateService.instant('Du kannst Videos entsprechend deiner Verwaltungsrechte überprüfen und freigeben.')
      });
    }
  }

  getPropertyByPath(obj: Object, pathString: string) {
    let cellValue = pathString
      .split('.')
      .reduce((o, i) => (o ? o[i] : null), obj);
    //Catering for empty ones and sending them to last
    if (cellValue === '' || cellValue === null) {
      return this.sort.direction === 'asc' ? '3' : '1';
    }
    if (typeof cellValue === 'object') {
      return '2' + Date.parse(cellValue);
    }
    //Making the cellValue to low case to make it case-insensv
    return (
      '2' +
      (typeof cellValue == 'string' ? cellValue.toLocaleLowerCase() : cellValue)
    );
  }

  userDetailPopup(userDetail: ManagerUser) {
    let dialogRef = this.dialog.open(UserDetailsDialogComponent, {
      width: "85%",
      disableClose: false,
      autoFocus: false,
      data: userDetail,
    });

    dialogRef.afterClosed().subscribe((dialogResponse: boolean) => {
      if (dialogResponse) {
        this.loadUsers();
      }
    });
  }

  importFromAzure() {
    // this method will fetch an url from the BE and will redirect it to that url
    // user will then authorise Zesavi to access data on their behalf
    let message =
      'You may be asked to authorise Clypp on the next step.\nGuest and external users will not be imported.\n\nWould you like to continue?';
    if (window.confirm(this.translateService.instant(message))) {
      this.dataService
        .getURL<any>(`manager/import/`, {
          observe: 'body',
          responseType: 'text',
        })
        .subscribe((res) => {
          window.location.href = res;
        });
    }
  }

  loadCSV() {
    let popupWidth = '50%';
    if (window.innerWidth < 999) {
      popupWidth = '85%';
    }
    const dialogRef = this.dialog.open(LoadUserCsvComponent, {
      width: popupWidth,
      disableClose: false,
      autoFocus: false,
    });

    dialogRef.afterClosed().subscribe((csvUploaded: boolean) => {
      if (csvUploaded) {
        this.loadUsers();
      }
    });

    dialogRef.backdropClick().subscribe((value) => {
      this.loadUsers();
    });
  }

  // Function to apply filter
  applyFilter(): void {
    const filterValue = this.search_text.trim().toLowerCase();  // clean the input
    this.dataSource.filter = filterValue;
  }

  addNewUser() {
    let message = this.translateService.instant("Add new user to this workspace");
    message +="\n\n";
    message += this.translateService.instant("Only email addresses that are not yet registered on Clypp can be added.");
    let resValue = window.prompt(message);
    // todo: check for email format
    if (resValue) {
      this.dataService
        .postURL(`manager/invite/`, {email: resValue})
        .subscribe((resp) => {
          if (resp['response'] === 'success') {
            this.snackBar.open(
              this.translateService.instant(
                'Du hast die Person erfolgreich eingeladen'
              ),
              '',
              {duration: 2500}
            );
            this.loadUsers();
          } else if (resp['response'] === 'failure') {
            window.alert(resp['reason']);
          } else {
            window.alert(resp);
          }
        });
    }
  }


  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  filter(key?){
    if(key == null || key == undefined){
      this.dataSource.data = this.allUsers;
    }else{
      let filteredUsers = this.allUsers.filter((x) => {
          if (key.split('.').length == 2){
          return x[key.split('.')[0]][key.split('.')[1]] == false
          }else{
            return x[key] == false
          }
        })
      this.dataSource.data = filteredUsers;
    }

  }
  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSource.data);
  }

  performBulkAction(action: string) {
    let message = "";
    if (action == 'email') {
      // open mail to
      const emails: string = this.selection.selected.map(e => e.user.email).join(';');
      const link: string = `mailto:${emails}?subject=Clypp`
      window.open(link, '_blank');
      return;
    } else if (action == 'delete') {
      message = "<u>";
      message += this.translateService.instant("Users with published content will be blocked but not deleted.");
      message += "</u><br><br>";
      message += this.translateService.instant("You can manually manage their content and delete their account via click on their email-address.");
    }

    const profiles: number[] = this.selection.selected.map(e => e.id);
    const title: string = this.translateService.instant("Bist du sicher?");
    const toggle_message: string = this.translateService.instant("Email senden");
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: [title, message, true, toggle_message],
      maxWidth: 600,
    });
    confirmDialog.afterClosed().subscribe((res: [boolean, boolean]) => {
      if (res == undefined) {
        // backdrop clicked
        return;
      } else if (res[0]) {
        // true, send put call
        const send_email: boolean = res[1];
        this.showSpinner = true;
        this.dataService.putURL<any>('manager/users/', {profiles, action, send_email},
          {observe: 'body', responseType: 'text'})
          .subscribe(
            (res) => {
              this.snackBar.open(res, '', {duration: 2000});
              this.selection.clear();
              this.loadUsers();
            },
            (err: HttpErrorResponse) => {
              window.alert(err.error);
              console.error(err);
              this.showSpinner = false;
            });
      }
    });
  }

  customFilterPredicate() {
    const myFilterPredicate = (data: ManagerUser, filter: string): boolean => {
      const dept_team_name = this.getTeamName(data.team).toLowerCase();
      // search in email or FN or LN or team name
      return data.user.email.includes(filter) ||
        data.user.first_name.toLowerCase().includes(filter) ||
        data.user.last_name.toLowerCase().includes(filter)  ||
        dept_team_name.includes(filter);
    }
    return myFilterPredicate;
  }

  private loadUsers(): void {
    this.showSpinner = true;

    this.dataService.getURL<any>('manager/users/').subscribe((res) => {
      this.allUsers = res;
      this.dataSource = new MatTableDataSource(res);
      this.dataSource.filterPredicate = this.customFilterPredicate();

      this.dataSource.sortingDataAccessor = (data, sortHeaderId: string) => {
        return this.getPropertyByPath(data, sortHeaderId);
      };

      this.applyFilter();
      this.dataSource.sort = this.sort;

      this.prepareCSV();

      this.showSpinner = false;
    }, () => {
      this.showSpinner = false;
    });
  }

  // prepare data to be downloaded
  prepareCSV(){
    let str = "";
    let row = "first_name,last_name,email,date_joined,last_login,is_active,is_approved,is_creator,is_global_manager," +
      "is_company_manager,is_quality_manager,is_department_manager,is_team_manager,is_privacy_accepted\r\n";
    str += row;
    for (let i of this.allUsers){
      row = i.user.first_name + ','
      row += i.user.last_name + ','
      row += i.user.email + ','
      row += i.user.date_joined + ','
      row += i.user.last_login + ','
      row += i.user.is_active + ','
      row += i.is_approved + ','
      row += i.is_creator + ','
      row += i.is_global_manager + ','
      row += i.is_company_manager + ','
      row += i.is_quality_manager + ','
      row += i.is_department_manager + ','
      row += i.is_team_manager + ','
      row += i.is_privacy_accepted + '\r\n'
      str += row;
    }

    let uri = this.sanitizer.bypassSecurityTrustUrl("data:text/csv;charset=UTF-8," +
      encodeURIComponent(str));
    this.download_users_uri = uri;
  }

  openRightsNotionLink(){
    let rights_notion_link = this.translateService.instant('RIGHTS_NOTION_LINK');
    window.open(rights_notion_link, "_blank");
  }

  getDeptName(id: number): string {
    const temp = this.authService.checklist_data.find(e => e.type == 'dept' && e.id == id);
    // if temp is found, return its name
    return temp ? temp.name : '-';
  }

  getTeamName(id: number): string {
    const temp = this.authService.checklist_data.find(e => e.type == 'team' && e.id == id);
    // if temp is found, return its name
    return temp ? temp.name : '-';
  }
}
