import {SafeHtml} from "@angular/platform-browser";
import {TopicTranslation} from "../topic.interface";

export interface VideoHistoryCard {
  readonly video: VideoCard | any;
  readonly viewed_on: string;
  readonly times_viewed: number;
  readonly viewed_till: number;
}

export interface Media {
  audio_file: string;
  id: number;
  name: string;
  overlay_video: string;
  processed_file: string;
  video_file: string;
}

// newly created interfaces
export interface MiniDetails {
  // for dept, team, tag
  id: number;
  name: string;
}

export interface UploaderDetails {
  email: string;
  first_name: string;
  id: number;
  last_name: string;
  userprofile: {
    company: number;
    profile_pic: string;
    department: number;
    team: number;
  }
}

export interface TranslationDetails {
  audio_file: string;
  created_on: string;
  desc: string;
  id: number;
  language: string;
  title: string;
  video: string;
  vtt_file: string;
}

export interface VideoView {
  access_key: string; // can be missing
  added_on: string;
  attachment: string;
  audio_file: string; // can be missing
  audio_language: string;
  chapter_file: string | null;
  ci_profile: number | null;
  company: number;
  departments: number[]; // can be missing
  desc: string;
  duration: number; // can be missing
  edit_parameters: any; // can be missing
  filmstrip_small: string;  // can be missing
  filmstrip_large: string;  // can be missing
  gif: string; // can be missing
  groups: number[]; // can be missing
  id: string;
  integration_parameters: any;
  is_access_key_needed: boolean;
  is_draft: boolean;
  is_external: boolean;
  is_featured: boolean;
  is_held_for_review: boolean;
  is_restricted: boolean;
  last_edited_on: string;
  locations: number[]; // can be missing
  overlay_video: string; // can be missing
  processed_file: string;
  project_settings: string; // can be missing
  related_videos: any[];
  script: string;
  state: string;
  // tag_data: MiniDetails[];
  tags: number[];
  teams: number[]; // can be missing
  thumbnail: string;
  title: string;
  transcription_job_id: string;
  translations: TranslationDetails[];
  type: string;
  up_votes: number;
  uploader: UploaderDetails;
  users: number[]; // can be missing
  views: number;
  video_file: string; // can be missing
  vtt_file: string;
}

export interface VideoCard {
  added_on: string;
  audio_language: string;
  duration: number;
  gif: string;
  id: string;
  is_access_key_needed: boolean;
  is_external: boolean;
  is_featured: boolean;
  is_held_for_review: boolean;
  is_restricted: boolean;
  is_draft: boolean;
  last_edited_on: string;
  state: string;
  thumbnail: string;
  title: string;
  uploader: UploaderDetails;
  views: number;
}

export interface UserProfileWithName {
  id: number;
  is_company_manager: boolean;
  is_department_manager: boolean;
  is_global_manager: boolean;
  is_quality_manager: boolean;
  is_team_manager: boolean;
  user: UploaderDetails;
}

export interface VideoProfile {
  // no field is empty
  id: number;
  name: string;  // max 50 chars
  added_on: string;  // date format, without time
  company: number;
  overlay_logo: string;
  bg_image: string;
  intro_video: string;
  outro_video: string;
  font_file: string;  // must be a ttf
  ci_video_code: 'none' | 'intro' | 'outro' | 'both';  // max length 5
  overlay_code: 'tl' | 'tr' | 'bl' | 'br' | 'no';  // max length 2
}

export interface VideoCardManager {
  added_on: string;
  duration: number;
  id: string;
  is_access_key_needed: boolean;
  is_draft: boolean;
  is_external: boolean;
  is_featured: boolean;
  is_held_for_review: boolean;
  is_restricted: boolean;
  processed_file: string;
  thumbnail: string;
  title: string;
  up_votes: number;
  uploader: UploaderView;
  views: number;
  vtt_file: string;
}

export interface TagWiseContent {
  name: string;
  videos: VideoCard[];
  topics: TopicCard[];
}

export interface UploaderView {
  id: number;
  last_login: string;
  username: string;
  first_name: string;
  last_name: string;
  email: string;
  is_active: boolean;
  date_joined: string;
}

export interface FeaturedTag {
  id: number;
  name: string;
  videos: number;
  featured: boolean;
}

export interface Topic {
  access_key: string | undefined;  // becomes undefined if not sent by backend
  added_on: string;
  company: number;
  departments: number[];
  emoji: string;
  groups: number[];
  id: string;
  is_access_key_needed: boolean;
  is_draft: boolean;
  is_external: boolean;
  is_featured: boolean;
  is_held_for_review: boolean;
  is_restricted: boolean;
  language: string;
  last_edited_on: string;
  locations: number[];
  object_position: string;  // max length 7 or '99% 99%'
  tags: number[];
  teams: number[];
  thumbnail: string;
  title: string;
  up_votes: number;
  uploader: UploaderDetails;
  users: number[];
  views: number;
}

export interface Section {
  id: number;
  content: string | SafeHtml;
  attachment: string;
  topic: string;
  next_section: number;
}

export interface TopicView extends Topic {
  translations: TopicTranslation[];
  sections: Section[];
}

export interface TopicCard {
  added_on: string;
  emoji: string;
  id: string;
  is_access_key_needed: boolean;
  is_draft: boolean;
  is_external: boolean;
  is_featured: boolean;
  is_held_for_review: boolean;
  is_restricted: boolean;
  language: string;
  last_edited_on: string;
  n_sections: number;
  object_position: string;  // max length 7 or '99% 99%'
  thumbnail: string;
  title: string;
  up_votes: number;
  uploader: UploaderDetails;
  views: number;
}

// to load translations into track tag
export interface Subtitle {
  src: string;
  label: string;
  srclang: string;
}

export interface ChecklistItem {
  id: number,
  name: string,
  string: string,
  type: string
}
