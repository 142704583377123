import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {environment} from '../../environments/environment';
import {DataService} from '../services/data.service';
import {ActivatedRoute, Router} from '@angular/router';
import {NavbarService} from "../services/navbar.service";
import {TopicCard, VideoCard} from "../models/video/video.interface";
import {PlaylistCard} from "../models/playlist/playlist";
import {Subscription} from "rxjs";
import {UtilityService} from 'src/app/services/utility.service';

@Component({
  selector: 'app-searched-content',
  templateUrl: './searched-content.component.html',
  styleUrls: ['./searched-content.component.scss']
})
export class SearchedContentComponent implements OnInit, OnDestroy {
  @Input('search_text') search_text: string = undefined;  // if it is provided, then mode must be popup
  @Input('mode') mode: 'popup' | 'view' = 'view';  // both mode & search_text should be provided
  // query params are ignored if mode is popup
  search_type: string = "all";
  video_search_results: VideoCard[] = [];
  playlist_search_results: PlaylistCard[] = [];
  topics_search_results: TopicCard[] = [];
  backendUrl: string;
  cols: number = 1;


  query_param_subscription: Subscription = null;
  data_service_subscriptions: Subscription[] = [];

  page: number = 1;
  maxPages: number = 0;
  showSpinner: boolean = false;
  n_video_results: number = 0;
  n_playlist_results: number = 0;
  n_topic_results: number = 0;
  n_results: number = 0;

  constructor(public dataService: DataService,
              private router: Router,
              private route: ActivatedRoute,
              private navbarService: NavbarService,
              public utilityService: UtilityService) {

  }

  ngOnInit(): void {
    this.backendUrl = environment.backendURL;
    this.navbarService.getUserProfileDetails();
    this.resize(window.innerWidth);

    if (this.mode == 'popup') {
      this.search_type = 'videos';
    }

    // this subscription is needed, as navbar service will just change the query parameters
    this.query_param_subscription = this.route.queryParams.subscribe(params => {
      // remove all existing subs, otherwise their data is added to current results
      this.data_service_subscriptions.forEach(i => i.unsubscribe());
      this.data_service_subscriptions = [];  // clear the queue
      this.page = 1;
      this.n_results = 0;
      this.maxPages = 0;
      this.getSearchResults();  // fetch new page 1 results
    });
  }

  getSearchResults() {
    // query params are ignored if mode is popup
    // search_text must already be there from @Input
    // don't parse the route, use variables instead
    if (this.mode == 'view') {
      this.search_text = this.route.snapshot.queryParamMap.get('search_text');
      this.search_type = this.route.snapshot.queryParamMap.get('search_type');
    }

    this.showSpinner = true;
    if (this.page == 1) {
      this.video_search_results = [];
      this.playlist_search_results = [];
      this.topics_search_results = [];
    }

    let get_url = `user/videos/?search_query=${this.search_text}&page=${this.page}`;
    switch (this.search_type) {
      case 'all':
        this.loadAllResults();  // only load first page results
        return;  // do not go ahead with other results
      case 'videos':
        // get url is unaffected
        break;
      case 'playlists':
        get_url = `user/playlists/?search_query=${this.search_text}&page=${this.page}`;
        break;
      case 'pages':
        get_url = `user/topics/?search_query=${this.search_text}&page=${this.page}`;
        break;
    }

    const data_service_sub: Subscription = this.dataService.getURL<any>(get_url, {
      observe: 'body',
      responseType: 'json'
    })
      .subscribe((res) => {
          // this.page = res.pageIndex;
          if (this.search_type == 'playlists') {
            this.playlist_search_results.push(...res.data);
            this.n_playlist_results = res['length'];
          } else if (this.search_type == 'pages') {
            this.topics_search_results.push(...res.data);
            this.n_topic_results = res['length'];
          } else {
            this.video_search_results.push(...res.data);
            this.n_video_results = res['length'];
          }
          this.maxPages = Math.ceil(res.length / res.pageSize);
          this.n_results = res.length;
          this.showSpinner = false;
          if (this.page == 1) {
            if (this.maxPages > 1) {
              this.onScrollDown();
            }
          }
        },
        (err) => {
          this.showSpinner = false;
        }
      );
    // add the above subs to list, so that we can remove it while loading new results
    this.data_service_subscriptions.push(data_service_sub);
  }

  loadAllResults() {
    let get_url: string = "";
    let data_service_sub: Subscription = null;

    // we must load the results in nested format, so that spinner is only hidden when all three are loaded

    // 1. Load videos
    get_url = `user/videos/?search_query=${this.search_text}&page=1`;
    data_service_sub = this.dataService.getURL<any>(get_url, {
      observe: 'body',
      responseType: 'json'
    }).subscribe((res) => {
      this.video_search_results.push(...res.data);
      this.n_video_results = res.length;
      this.n_results += res.length;

      // 2. Load topics
      get_url = `user/topics/?search_query=${this.search_text}&page=1`;
      const topic_sub = this.dataService.getURL<any>(get_url, {
        observe: 'body',
        responseType: 'json'
      }).subscribe((res) => {
        this.topics_search_results.push(...res.data);
        this.n_topic_results = res.length;
        this.n_results += res.length;

        // 3. Load playlists
        get_url = `user/playlists/?search_query=${this.search_text}&page=1`;
        const playlist_sub = this.dataService.getURL<any>(get_url, {
          observe: 'body',
          responseType: 'json'
        }).subscribe((res) => {
          this.playlist_search_results.push(...res.data);
          this.n_playlist_results = res.length;
          this.n_results += res.length;

          // finally, close spinner
          this.showSpinner = false;
        }, () => this.showSpinner = false);
        this.data_service_subscriptions.push(playlist_sub);

      }, () => this.showSpinner = false);
      this.data_service_subscriptions.push(topic_sub);

    }, () => this.showSpinner = false);
    this.data_service_subscriptions.push(data_service_sub);

    // finish
    return
  }

  searchTypeChanged() {
    if (this.mode == 'popup') {
      // internal page
      this.data_service_subscriptions.forEach(i => i.unsubscribe());
      this.data_service_subscriptions = [];  // clear the queue
      this.page = 1;
      this.n_results = 0;
      this.maxPages = 0;
      this.getSearchResults();
    } else {
      // start page
      this.router.navigate(['search'], {
        queryParams: {
          search_type: this.search_type,
          search_text: this.search_text
        }
      });
    }
  }

  ngOnDestroy(): void {
    this.query_param_subscription.unsubscribe();
  }


  onScrollDown() {
    this.page = this.page + 1;
    if (this.page <= this.maxPages) {
      this.getSearchResults();
    }
  }

  resize(innerWidth) {
    let cw = 320; // card width
    let gs = 20;  // gutter size
    let sn = 50; // small side nav width = 50 approx
    this.cols = Math.floor((innerWidth - sn) / (cw + gs));
  }
}
