import {Component, Input, OnInit, Inject} from '@angular/core';
import {UtilityService} from '../services/utility.service';
import {AuthService} from '../services/auth.service';
import {VideoCard, VideoView} from "../models/video/video.interface";
import {TranslateService} from "@ngx-translate/core";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {Router} from "@angular/router";
import {HttpErrorResponse} from "@angular/common/http";
import {DataService} from "../services/data.service";
import {ProgressSpinnerDialogComponent} from "../shared/progress-spinner-dialog/progress-spinner-dialog.component";

@Component({
  selector: 'app-video-card',
  templateUrl: './video-card.component.html',
  styleUrls: ['./video-card.component.scss']
})
export class VideoCardComponent implements OnInit {
  @Input('videoObject') videoObject: VideoCard;
  @Input('is_external_views_shown') is_external_views_shown: boolean = true;
  @Input('mode') mode: 'view' | 'edit' | 'none' | 'popup' | 'public' = 'view';

  thumbnail: string;
  show_external_label: boolean = false;  // to show External text if video is from another workspace
  show_language_label: boolean = false;

  constructor(public utilityService: UtilityService,
              private dialog: MatDialog,
              private dataService: DataService,
              private router: Router,
              private translateService: TranslateService,
              private authService: AuthService) {
  }

  ngOnInit(): void {
    // show external label. This will never be true for public videos
    if (this.authService.company.id != this.videoObject.uploader.userprofile.company) {
      this.show_external_label = true;
    }

    // show language label or not
    if (this.authService.userDetails) {
      // logged in case
      if (this.authService.userDetails.email_language.slice(0, 2) != this.videoObject.audio_language.slice(0, 2)) {
        this.show_language_label = true;
      }
    } else {
      // public case
      if (this.translateService.currentLang != this.videoObject.audio_language.slice(0, 2)) {
        this.show_language_label = true;
      }
    }

    this.thumbnail = this.videoObject.thumbnail;
  }

  mouseovered() {
    if (this.videoObject.gif) {
      this.thumbnail = this.videoObject.gif;
    }
  }

  mouseouted() {
    this.thumbnail = this.videoObject.thumbnail;
  }

  viewVideo() {
    // go to either topic view or edit page
    switch (this.mode) {
      case 'edit':
        switch (this.videoObject.state) {
          case 'CO' || 'PR':
            this.router.navigate(['create-video', this.videoObject.id, 'review']);
            break;
          case 'UP' || 'FA':
            this.router.navigate(['create-video', this.videoObject.id, 'trim']);
            break;
          default:
            // NO video
            this.router.navigate(['create-video', this.videoObject.id, 'record']);
            break;
        }
        break;
      case "view":
        this.router.navigate(['view', this.videoObject.id]);
        break;
      case 'public':
        this.router.navigate(['publicVideoView', this.videoObject.id]);
        break;
      case "popup":
        // load content then open popup
        this.loadVideoInPopup();
        break;
      case 'none':
        // do nothing, user probably wants to open a popup
        break;
    }
  }

  loadVideoInPopup() {
    const dialogRef: MatDialogRef<ProgressSpinnerDialogComponent> =
      this.dialog.open(ProgressSpinnerDialogComponent, {
        panelClass: 'transparent',
        disableClose: true,
      });
    // display alert if video can not be loaded or there is no processed file
    this.dataService.getURL<VideoView>(`user/videos/${this.videoObject.id}/`, {
      observe: 'body',
      responseType: 'json'
    }).subscribe((res: VideoView) => {
      dialogRef.close();
      if (res.processed_file) {
        // ok
        this.dialog.open(VideoDialog, {
          data: res,
          hasBackdrop: true,
          closeOnNavigation: true,
          disableClose: false,
          panelClass: 'topic-dialog-container'
        });
      } else {
        window.alert(this.translateService.instant('This Clypp is still processing'));
      }
    }, (err: HttpErrorResponse) => {
      dialogRef.close();
      if (err.status == 429) {
        window.alert(err.error.detail);
      } else {
        window.alert(err.error);
      }
    });
  }
}


// this is a text to speech dialog
// it asks for text and voice and returns the data to calling component
@Component({
  selector: 'video-dialog',
  templateUrl: 'video-dialog.html',
})
export class VideoDialog {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: VideoView,
    public dialogRef: MatDialogRef<VideoDialog>,
  ) {
  }
}
