<!--title-->
<h1 mat-dialog-title>
  {{data[0]}}
</h1>

<!--subtitle-->
<div mat-dialog-content [innerHTML]="data[1]">
</div>

<!--optional toggle-->
<mat-slide-toggle class="mt-2" [(ngModel)]="toggle_value" *ngIf="data[2]">{{ data[3] }}</mat-slide-toggle>

<!--yes/no buttons-->
<div mat-dialog-actions class="justify-content-between">
  <button mat-button (click)="closeDialog(false);" class="border-radius-20">{{ 'Nein' | translate }}</button>
  <button mat-button (click)="closeDialog(true);" class="border-radius-20" cdkFocusInitial>{{ 'Ja' | translate }}</button>
</div>
