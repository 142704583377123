import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  OnDestroy,
} from '@angular/core';
import {Subscription, forkJoin} from 'rxjs';
import {environment} from '../../environments/environment';
import {
  MiniDetails,
  TagWiseContent,
  VideoCard,
  VideoHistoryCard,
  VideoView,
  TopicCard
} from '../models/video/video.interface';
import {AuthService} from '../services/auth.service';
import {DataService} from '../services/data.service';
import {TranslateService} from '@ngx-translate/core';
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {Friend} from "../dashboard/global-administration/partner-organizations/partner-organizations.component";
import {UserActivitiesOverview, RecentCreator} from "../models/activity.interface";
import {ProgressSpinnerDialogComponent} from "../shared/progress-spinner-dialog/progress-spinner-dialog.component";
import {NavbarService} from "../services/navbar.service";
import {Chart} from 'chart.js';
import {FeedbackDialogComponent} from '../shared/feedback-dialog/feedback-dialog.component';
import {HttpErrorResponse} from "@angular/common/http";
import {VideoDialog} from "../video-card/video-card.component";
import {ActivatedRoute, Router} from "@angular/router";
import {Location} from "@angular/common";


interface MixedCard {
  type: 'video' | 'topic';
  data: VideoCard | TopicCard;
}

@Component({
  selector: 'internal-video',
  templateUrl: './internal-video.component.html',
  styleUrls: ['./internal-video.component.scss']
})
export class InternalVideoComponent implements OnInit, OnDestroy {
  latest_videos: VideoCard[] = [];
  backendUrl: string;
  userContentCase = false;
  searchQuery: string = "";
  options = {observe: 'body', responseType: 'json'};
  chart_button_toggle: string = 'all';
  recent_users: RecentCreator[] = [];
  tags: MiniDetails[] = [];
  showSpinner: boolean = false;
  partner_companies: Friend[] = [];
  greetings_text: string = "";
  active_days_label: string[] = [];  // ['Mo', 'Tu'…] last seven days, including today
  seven_days_label: string[] = [];  // labels when user was active
  userActivityResp: UserActivitiesOverview = null;
  is_chart_ready: boolean = false;
  increasePercentage: number = 0;
  history_videos: VideoHistoryCard[] = [];
  @ViewChild('scroll') scroll: ElementRef;
  chart1: any;
  chart2: any;
  all_topics: TopicCard[] = [];
  mixed_tags_content: MixedCard[] = [];
  mixed_featured_content: MixedCard[] = [];
  view_case: 'internal' | 'user' | 'search' | 'tag' | 'friend' | 'empty' = "internal";
  user_id: number = 0;
  tag_id: number = 0;
  partnership_id: number = 0;

  constructor(private dataService: DataService,
              private translateService: TranslateService,
              public navbarService: NavbarService,
              public authService: AuthService,
              private location: Location,
              private dialog: MatDialog,) {
  }

  ngOnInit(): void {
    this.backendUrl = environment.backendURL;
    this.navbarService.getUserProfileDetails();

    // get user activities
    this.getUserActivityDetails();

    // get features videos and history
    this.getAllBlockData();

    // find hour of the day to show greetings
    const d = new Date();
    const hour = d.getHours();
    if (hour <= 11) {
      this.greetings_text = this.translateService.instant("Good morning");
    } else if (hour <= 16) {
      this.greetings_text = this.translateService.instant("Good afternoon");
    } else {
      this.greetings_text = this.translateService.instant("Good evening");
    }
  }

  loadCharts() {
    Chart.defaults.font.family = "nunito";
    Chart.defaults.font.size = 16;
    const canvas1 = document.getElementById('canvas1') as HTMLCanvasElement;
    const canvas2 = document.getElementById('canvas2') as HTMLCanvasElement;

    this.chart1 = new Chart(canvas1, {
      type: 'doughnut',
      data: {
        labels: [
          'Clypps',
          'Playlists',
          this.translateService.instant('Pages'),
        ],
        datasets: [
          {
            data: [
              this.userActivityResp.n_all_videos,
              this.userActivityResp.n_all_playlists,
              this.userActivityResp.n_all_topics
            ],
            backgroundColor: ['#5B93FF', '#FF8F6B', '#FFD66B'],
          },
        ]
      },
      options: {
        scales: {
          x: {
            display: false,
          },
          y: {
            display: false
          }
        },
        plugins: {
          legend: {
            display: true,
            onClick: null,
            position: 'right',
            labels: {
              usePointStyle: true,
              color: 'black',
            },
          },
          tooltip: {
            enabled: true
          }
        },
        aspectRatio: 1.9,
        cutout: '80%',

        maintainAspectRatio: true
      },
    });

    this.chart2 = new Chart(canvas2, {
      type: 'doughnut',
      data: {
        labels: [
          'Clypps',
          'Playlists',
          this.translateService.instant('Pages'),
        ],
        datasets: [
          {
            data: [
              this.userActivityResp.n_my_videos,
              this.userActivityResp.n_my_playlists,
              this.userActivityResp.n_my_topics
            ],
            backgroundColor: ['#5B93FF', '#FF8F6B', '#FFD66B'],
          },
        ]
      },
      options: {
        scales: {
          x: {
            display: false,
          },
          y: {
            display: false
          }
        },
        plugins: {
          legend: {
            display: true,
            onClick: null,
            position: 'right',
            labels: {
              usePointStyle: true,
              color: 'black',
            },
          },
          tooltip: {
            enabled: true
          }
        },
        aspectRatio: 1.9,
        cutout: '80%',
        responsive: true,
        maintainAspectRatio: true
      },
    });

    this.is_chart_ready = true;
  }

  // navigate to user content page
  userSelected(user: RecentCreator) {
    // load user wise data
    this.user_id = user.id;
    this.view_case = 'user';
  }

  onChartChange() {
    const chart = (this.chart_button_toggle === 'mine') ? this.chart2 : this.chart1;
    chart.options.animation.duration = 2000;
    chart.reset();
    chart.update();
  }


  chartInterval() {
    const interval = setInterval(() => {
      // load after 1 sec, as html may not be ready
      this.loadCharts();
      clearInterval(interval);
    }, 500);
  }

  partnerSelected(partner: Friend) {
    this.partnership_id = partner.id;
    this.view_case = 'friend';
  }

  searchContent() {
    if (this.searchQuery == '') {
      this.view_case = 'internal';
      return;
    }
    this.view_case = 'empty';
    // after a while, open search, because on change of search text, we need to re-create the search component
    setTimeout(() => {
      this.view_case = "search";
    }, 200);
  }

  goBack() {
    this.view_case = 'internal';
    this.searchQuery = '';
    this.chartInterval();
  }

  playVideo(video_id: string) {
    const dialogRef: MatDialogRef<ProgressSpinnerDialogComponent> =
      this.dialog.open(ProgressSpinnerDialogComponent, {
        panelClass: 'transparent',
        disableClose: true,
      });
    this.dataService.getURL<VideoView>(`user/videos/${video_id}/`, this.options).subscribe((res: VideoView) => {
      dialogRef.close();
      this.dialog.open(VideoDialog, {
        data: res,
        hasBackdrop: true,
        closeOnNavigation: true,
        disableClose: false,
        panelClass: 'topic-dialog-container'
      });
    }, (err) => {
      dialogRef.close();
      window.alert(err.error);
    });
  }

  openFeedbackDialog() {
    this.dialog.open(FeedbackDialogComponent, {
      width: "40%",
      minWidth: "400px",
      autoFocus: false,
      disableClose: false,
      hasBackdrop: true
    });
  }

  tagSelected(tag: MiniDetails) {
    this.tag_id = tag.id;
    this.view_case = 'tag';
  }

  getUserActivityDetails() {
    this.dataService.getURL('user/activities/overview/', this.options)
      .subscribe((res: UserActivitiesOverview) => {
        this.userActivityResp = res;
        this.recent_users = this.userActivityResp.recent_creators;
        const initial = res.n_14_days - res.n_7_days;
        const final = res.n_7_days;
        // formula: (final-initial)/initial
        if (initial != 0) {
          this.increasePercentage = Math.round(((final - initial) / initial) * 100);
        } // else, do not show

        // prepare last 7 active days
        for (let i of this.userActivityResp.active_date_7_days) {
          this.active_days_label.push(new Date(i).toDateString().slice(0, 2));
        }
        let i = 6;
        let seven_days: Date[] = [];
        while (i >= 0) {
          const today = new Date();
          seven_days.push(new Date(today.setDate(today.getDate() - i)));
          i--;
        }
        for (let i of seven_days) {
          this.seven_days_label.push(i.toDateString().slice(0, 2));
        }

        // not undefined
        this.chartInterval();
      }, (err) => {
        window.alert(err.error.detail);
      });
  }

  getAllBlockData() {
    this.showSpinner = true;

    const featuredVideos = this.dataService.getURL('user/videos/?is_featured=true&sort_by=added_on', this.options);
    const continueWatching = this.dataService.getURL('user/videos/history/?page=1', this.options);
    const newestClypp = this.dataService.getURL('user/videos/?sort_by=added_on&page=1', this.options);
    const newestTopics = this.dataService.getURL('user/topics/?page=1&sort_by=added_on', this.options);
    const featuredTopics = this.dataService.getURL('user/topics/?page=1&is_featured=true', this.options);
    const featuredTags = this.dataService.getURL('user/featured-tags/', this.options);
    const partnerCompanies = this.dataService.getURL('user/friends/', this.options);
    forkJoin([featuredVideos, continueWatching, newestClypp, newestTopics, featuredTopics, featuredTags, partnerCompanies]).subscribe(
      (responses) => {
        this.tags = responses[5] as MiniDetails[];
        if (this.authService.company?.is_partner_content_shown) {
          this.partner_companies = responses[6] as Friend[];
        }

        this.history_videos = responses[1]['data'] as VideoHistoryCard[];
        this.latest_videos = responses[2]['data'] as VideoCard[];
        this.all_topics = responses[3]['data'] as TopicCard[];
        // load the mixed cards
        for (let i of responses[0]['data'] as VideoCard[]) {
          this.mixed_featured_content.push({type: "video", data: i});
        }
        for (let i of responses[4]['data'] as TopicCard[]) {
          this.mixed_featured_content.push({type: "topic", data: i});
        }
        // now sort them
        this.mixed_featured_content.sort((a, b) =>
          new Date(b.data.added_on).getTime() - new Date(a.data.added_on).getTime());

        this.showSpinner = false;
      },
      (error) => {
        console.error(error);
        this.showSpinner = false;
      }
    );
  }

  scrollToLeftRight(id: string, left: boolean = false) {
    const element = document.getElementById(id);
    // element has 2+x children, first and last children are buttons
    // scroll distance = width of each child * number of children visible
    const child_width = element.firstElementChild.clientWidth;
    const viewport_width = element.clientWidth;
    const number_of_visible_children = Math.max(Math.floor(viewport_width / child_width), 1);
    // above number may become 0 for very large zoom levels, hence using max

    let scroll_distance = (child_width + 20) * number_of_visible_children;
    // margin-right is added
    if (left) {
      scroll_distance = -scroll_distance;
    }

    element.scrollBy({
      behavior: "smooth",
      left: scroll_distance,
      top: 0
    });
  }

  //scrolled to the top of the view
  scrollTop() {
    this.scroll.nativeElement.scrollIntoView({behavior: 'smooth'});
  }

  ngOnDestroy() {
    this.chart1.destroy();
    this.chart2.destroy();
  }
}
