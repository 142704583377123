import {
  Component,
  OnInit,
  Input,
  Inject, OnChanges, SimpleChanges,
} from '@angular/core';
import {Question, Answer, Option} from "../../models/question_answer_option";
import {MatListOption} from "@angular/material/list";
import * as mime from 'mime';
import {DataService} from "../../services/data.service";
import {TranslateService} from "@ngx-translate/core";
import {MatSnackBar} from "@angular/material/snack-bar";
import {HttpErrorResponse} from "@angular/common/http";
import {AuthService} from "../../services/auth.service";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {ConfirmDialogComponent} from "../../shared/confirm-dialog/confirm-dialog.component";


@Component({
  selector: 'view-question',
  templateUrl: './view-question.component.html',
  styleUrls: ['./view-question.component.scss']
})
export class ViewQuestionComponent implements OnInit, OnChanges {
  @Input() isTopicCreator: boolean;
  @Input() ques: Question;

  answers: Answer[] = [];
  options: Option[] = [];

  userSSValue: number = 0;  // SS answer by user
  userStLtValue: string = '';
  userSupportingFile: File = null;

  constructor(private dataService: DataService,
              private translateService: TranslateService,
              private snackBar: MatSnackBar,
              private authService: AuthService,
              private dialog: MatDialog) {
  }

  ngOnInit(): void {

  }

  ngOnChanges(changes: SimpleChanges): void {
    this.loadOptions();
    this.loadAnswers();
  }

  getMimeType(url: string): string {
    let attachmentUrl = this.getAttachedFilename(url);
    const mimeType = mime.getType(attachmentUrl);

    if (mimeType == null) {
      // can be null if not found anything
      return 'other';
    } else if (mimeType.includes('image')) {
      return 'image';
    } else {
      return 'other';
    }
  }

  getAttachedFilename(url) {
    let attachFileName = url.split('/').pop();
    return attachFileName.split("?")[0];
  }

  // for html, disable options, if user has answered it already
  hasAnsweredAlready(): boolean {
    return this.answers.findIndex(e => e.uploader == this.authService.userDetails.user) > -1;
  }

  getMyAnswer(): Answer {
    return this.answers.find(e => e.uploader == this.authService.userDetails.user);
  }

  answerSSQuestion() {
    const id = this.userSSValue;
    // id must be non zero
    if (id) {
      const body = {
        id: id,
      };
      this.answerQuestion(body);
    } else {
      return;
    }
  }

  answerQuestion(body: any) {
    // send call and fetch options again
    this.dataService.postURL(`user/${this.ques.id}/answers/`, body, {
      observe: 'body',
      responseType: 'json'
    }).subscribe((res: Answer) => {
      this.answers.push(res);
      // remove snackbar
      // this.snackBar.open(this.translateService.instant('You successfully completed this question'), '', {duration: 2500});
      // load options again, even if answer is wrong
      this.loadOptions();
      this.userSupportingFile = null;
    }, (err: HttpErrorResponse) => {
      console.error(err);
      window.alert(err.error);
    });
  }

  // ques must be SS or MS
  loadOptions(): void {
    // replace option if already there in list
    if (!['SS', 'MS'].includes(this.ques.type)) {
      // check for SS or MS
      return;
    }
    // load data
    this.dataService.getURL(`user/${this.ques.id}/options/`, {
      observe: 'body',
      responseType: 'json'
    }).subscribe((res: Option[]) => {
      this.options = res;
    });
  }

  // load all answers
  loadAnswers() {
    // 404 means no answer yet
    this.dataService.getURL(`user/${this.ques.id}/answers/`, {
      observe: 'body',
      responseType: 'json'
    }).subscribe((res: Answer[]) => {
      // there can be multiple answers per question
      this.answers = res;
      // todo: if user's answer is here, then update userSSValue

    });
  }

  getSubmitLabel(): string {
    let submit_button_label: string = this.translateService.instant('Submitted');
    let myAnswer = this.getMyAnswer();
    if (myAnswer) {
      this.userStLtValue = myAnswer.text.split('---')[0];
      // update submit button label
      if (['SS', 'MS'].includes(this.ques.type)) {
        // check if there is any correct answer
        const n_correct_options = this.options.filter(e => e.is_correct).length;
        if (n_correct_options > 0) {
          // this is not a survey question
          if (myAnswer.is_correct) {
            submit_button_label = this.translateService.instant("Correct");
          } else {
            submit_button_label = this.translateService.instant("Incorrect");
          }
        }
      }
    }
    return submit_button_label;
  }

  deleteAnswer(answer_id: number): void {
    // delete answer only works if you are a topic creator or retake is allowed
    if (this.isTopicCreator) {
      // ok
    } else if (this.ques.is_retake_allowed) {
      // ok
    } else {
      return;
    }

    // coming here would mean that either I am topic creator or retake is allowed
    const message = this.translateService.instant('Bist du sicher?');
    if (window.confirm(message)) {
      this.dataService.deleteURL(`user/${this.ques.id}/answers/?id=${answer_id}`).subscribe(res => {
        // 204
        // remove from answers array
        const answer_index: number = this.answers.findIndex(e => e.id == answer_id);
        this.answers.splice(answer_index, 1);
        // show snackbar
        this.snackBar.open(this.translateService.instant('Erfolgreich gelöscht'), '', {duration: 2500});
      });
    }
  }

  // based on question type, select one of the answer method
  submitResponse() {
    switch (this.ques.type) {
      case 'SS':
        if (this.userSSValue) {
          this.answerSSQuestion();
        }
        break;
      case 'MS':
        break;
      case 'LT':
      case 'ST':
        this.answerSTLTQuestion();
        break;
      case 'FU':
        if (this.userSupportingFile) {
          let formData = new FormData();
          let file_name = this.userSupportingFile.name.slice(-99);
          // add the data to the form
          formData.append("supporting_file", this.userSupportingFile, file_name);
          this.answerQuestion(formData);
        } else {
          // no file
          this.snackBar.open(this.translateService.instant('Please select an image'), '', {duration: 2000});
        }
        break;
    }
  }

  answerMSQuestion(selected_options: MatListOption[]) {
    let ids: number[] = [];
    for (let option of selected_options) {
      ids.push(option.value);
    }
    // ids must not be empty
    if (ids.length == 0) {
      return;
    } else {
      const body = {
        ids: ids,
      };
      this.answerQuestion(body);
    }
  }

  // answer a short or long text question
  answerSTLTQuestion() {
    const text = this.userStLtValue.trim();
    if (text.length == 0) {
      return;
    }
    // text may contain angular brackets or execute commands, which may trigger the WAF
    else {
      const body = {
        text: text,
      };
      this.answerQuestion(body);
    }
  }

  fileSelected(event) {
    this.userSupportingFile = event.target.files[0];
    if (this.userSupportingFile.size > 5000000) {
      const message = this.translateService.instant("Limit Exceeded: ") +
        `${Math.floor(this.userSupportingFile.size / 1000000)} MB / 5 MB`;
      this.snackBar.open(message, '', {duration: 3000});
      this.userSupportingFile = null;
      event.target.value = '';
    }
  }

  openResponses() {
    this.dialog.open(ViewResponses, {
      width: "60%",
      minWidth: 400,
      minHeight: 400,
      maxHeight: 800,
      disableClose: false,
      hasBackdrop: true,
      data: this.answers,
    });
  }
}


@Component({
  templateUrl: 'view-responses.html',
})
export class ViewResponses {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: Answer[],
    private dataService: DataService,
    private translateService: TranslateService,
    private snackBar: MatSnackBar,
    private authService: AuthService,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<ViewResponses>,
  ) {

  }

  getAnswerHtml(text: string): string {
    return text.split('---')[0].replace(/\n/g, "<br>");
  }

  // show using CL data who answered it
  getUploaderName(user_id: number): string {
    const user_object = this.authService.checklist_data.find(e => e.id == user_id && e.type == 'user');
    return user_object ? user_object.name : 'User not found';
  }

  getAttachedFilename(url) {
    let attachFileName = url.split('/').pop();
    return attachFileName.split("?")[0];
  }

  deleteAnswer(answer: Answer) {
    const title: string = this.translateService.instant('Bist du sicher?');
    const toggle_message: string = this.translateService.instant("Email senden");
    let send_email: boolean = true;

    // if user is deleting own answer, then do not ask for send_email
    if (this.authService.userDetails.user == answer.uploader) {
      send_email = false;
    }

    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: [title, '', send_email, toggle_message],
      minWidth: 300,
    });

    confirmDialog.afterClosed().subscribe((res: [boolean, boolean]) => {
      if (res == undefined) {
        // backdrop clicked
        return;
      } else if (res[0]) {
        // true
        let delete_url = `user/${answer.question}/answers/?id=${answer.id}`;
        if (send_email) {
          // not my own answer
          if (res[1]) {
            // topic creator selected yes
            delete_url += '&send_email';
          }
        }
        this.dataService.deleteURL(delete_url).subscribe(res => {
          // 204, remove from answers array
          const answer_index: number = this.data.findIndex(e => e.id == answer.id);
          this.data.splice(answer_index, 1);
          // show snackbar
          this.snackBar.open(this.translateService.instant('Erfolgreich gelöscht'), '', {duration: 2500});

          if (this.data.length == 0) {
            // close it
            this.dialogRef.close();
          }
        });
      }
    });
  }

  step = 0;

  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }



}
