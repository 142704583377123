import {Component, Inject, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {TopicCard} from "../models/video/video.interface";
import {UtilityService} from "../services/utility.service";
import {AuthService} from '../services/auth.service';
import {TranslateService} from '@ngx-translate/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-topic-card',
  templateUrl: './topic-card.component.html',
  styleUrls: ['./topic-card.component.scss']
})
export class TopicCardComponent implements OnInit {
  @Input('topic_object') topic_object: TopicCard;
  @Input('mode') mode: 'view' | 'edit' | 'none' | 'popup' = 'view';
  show_language_label: boolean = false;

  constructor(private router: Router,
              public utilityService: UtilityService,
              private translateService: TranslateService,
              private dialog: MatDialog,
              private authService: AuthService) {
  }

  ngOnInit(): void {
    // show language label or not
    if (this.authService.userDetails) {
      // logged in case
      if (this.authService.userDetails.email_language?.slice(0, 2) != this.topic_object.language?.slice(0, 2)) {
        this.show_language_label = true;
      }
    } else {
      // public case
      if (this.translateService.currentLang != this.topic_object.language?.slice(0, 2)) {
        this.show_language_label = true;
      }
    }
  }

  viewTopic() {
    // go to either topic view or edit page
    switch (this.mode) {
      case 'edit':
        this.router.navigate(['pages', 'edit', this.topic_object.id]);
        break;
      case "view":
        this.router.navigate(['pages', 'view', this.topic_object.id]);
        break;
      case "popup":
        // load content then open popup
        this.loadTopicInPopup();
        break;
      case 'none':
        // do nothing, user probably wants to open a popup
        break;
    }
  }

  loadTopicInPopup() {
    this.dialog.open(TopicDialog, {
      data: this.topic_object.id,
      hasBackdrop: true,
      // enterAnimationDuration: '1s',
      // exitAnimationDuration: '500ms',
      closeOnNavigation: true,
      disableClose: false,
      panelClass: 'topic-dialog-container'
    })
  }

}

// this is a text to speech dialog
// it asks for text and voice and returns the data to calling component
@Component({
  selector: 'topic-dialog',
  templateUrl: 'topic-dialog.html',
})
export class TopicDialog {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: string,
    public dialogRef: MatDialogRef<TopicDialog>,
  ) {
  }
}
