import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, Validators} from '@angular/forms';
import {NavigationExtras, Router} from '@angular/router';
import {DataService} from '../services/data.service';
import {MatSnackBar} from "@angular/material/snack-bar";
import {TranslateService} from "@ngx-translate/core";
import {LoginService} from '../login/login.service';
import {HttpErrorResponse} from "@angular/common/http";

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss'],
})
export class SignUpComponent implements OnInit {
  email: any = '';
  otp: any = '';
  fontcolor: string;
  isEditable = false;
  showPass: boolean;
  doneButton = false;
  closeButton = true;
  otpForm = this.formBuilder.group({
    otp: ['', Validators.required]
  });

  mailIDForm = this.formBuilder.group({
    email: ['', [Validators.required, Validators.email]],
    is_privacy_accepted: [false, [Validators.required]]
  });

  regForm = this.formBuilder.group({
    username: ['', Validators.required],
    password: ['', [
      Validators.required,
      Validators.minLength(10),
      Validators.maxLength(30),
      Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^a-zA-Z0-9]).*$/)]
    ],
  });
  hide = true;

  constructor(private formBuilder: UntypedFormBuilder,
              private loginService: LoginService,
              private snackBar: MatSnackBar,
              private router: Router,
              public dataService: DataService,
              public translateService: TranslateService
  ) {
    this.otpForm.valueChanges.subscribe(() => {
      if (this.otpForm.value.otp.length >= 4) {
        this.otpFormClicked();
      }
    });

    // check if coming from other page
    const navigation = this.router.getCurrentNavigation();
    const stateValue = navigation?.extras.state;
    if (stateValue) {
      this.regForm.get('username').setValue(stateValue['email']);
      this.mailIDForm.get('email').setValue(stateValue['email']);
      this.mailIDForm.get('is_privacy_accepted').setValue(true);
    }
  }

  ngOnInit(): void {
  }

  registered(): void {
    this.regForm.get('username').setValue(this.mailIDForm.value.email);
    if (this.regForm.valid) {
      const post_body = {
        ...this.mailIDForm.value,
        ...this.otpForm.value,
        ...this.regForm.value
      }
      this.dataService.postURL('register/', post_body, {
        observe: 'body', responseType: 'json', headers: {
          'Accept-Language': this.translateService.currentLang
        }
      }).subscribe((res) => {
        if (res['reason']) {
          this.snackBar.open(res['reason'], '', {duration: 2500})
        } else {
          //login as well
          this.loginService.login('user', this.regForm.get('username').value,
            this.regForm.get('password').value,
            this.registerCallback
          );
        }
      });
    }
  }

  registerCallback = () => {
    this.snackBar.open(this.translateService.instant('Erfolgreich'), '', {duration: 2500});
  }

  mailFormClicked(): void {
    this.mailIDForm.get('is_privacy_accepted').setValue(true);
    if (this.mailIDForm.status == 'VALID' && this.mailIDForm.get('is_privacy_accepted').value) {
      var next_button = document.getElementById('next');
      next_button.setAttribute('disabled', 'true');
      this.dataService.postURL('request-otp/', this.mailIDForm.value, {
        observe: 'body', responseType: 'json', headers: {
          'Accept-Language': this.translateService.currentLang
        }
      }).subscribe((res) => {
        next_button.removeAttribute('disabled');
        if (res['reason']) {
          if (res['reason'] == 'no company found') {
            let message1 = this.translateService.instant("Your email address could not be affiliated with an existing workspace on Clypp.");
            let message2 = this.translateService.instant("Do you want to create your own workspace?");
            // ask if user wants to sign up a new company
            if (window.confirm(message1 + '\n\n' + message2)) {
              const navigationExtras: NavigationExtras = {
                state: {
                  email: this.mailIDForm.value.email,
                  privacy: true
                }
              }
              this.router.navigate(['companyRegistration'], navigationExtras);
            }
          } else if (res['reason'] == 'email already registered') {
            window.alert(this.translateService.instant("Diese Email-Adresse ist bereits bei uns registriert."));
          } else {
            window.alert(res['reason']);
          }
        } else {
          this.email = this.mailIDForm.value.email;
          this.isEditable = false;
        }
      }, (error) => {
        next_button.removeAttribute('disabled');
      });
    }
  }

  otpFormClicked(): void {
    if (this.otpForm.status == 'VALID') {
      // tslint:disable-next-line:radix
      this.otpForm.value.otp = parseInt(this.otpForm.value.otp);
      this.dataService.postURL('verify-otp/', {
        ...this.mailIDForm.value,
        ...this.otpForm.value
      }).subscribe(() => {
        this.otp = this.otpForm.value.otp;
        this.showPass = true;
        this.isEditable = true;
        this.doneButton = true;
        this.closeButton = false;
      }, (err: HttpErrorResponse) => {
        this.closeButton = true;
        this.doneButton = false;
        this.showPass = false;
        this.isEditable = false;
        if (err.status === 429) {
          window.alert(err.error.detail);
        } else {
          window.alert(err.error);
        }
      });
    }
  }

  mailFormBackClicked(): void {
    this.mailIDForm.value.email = '';
    this.email = '';
    window.history.back();
    // this.router.navigate(['start']);
  }

  otpFormBackClicked(): void {
    this.otpForm.get('otp').setValue('');
    this.regForm.get('password').setValue('');
    this.regForm.get('username').setValue('');
    this.otp = '';
    this.email = '';
    this.showPass = false;
    this.doneButton = false;
    this.closeButton = false;
    this.isEditable = false;
  }

  sendOTPClicked(): void {
    var next_button = document.getElementById('otp');
    next_button.setAttribute('disabled', 'true');

    this.dataService.postURL('request-otp/', this.mailIDForm.value, {
      observe: 'body', responseType: 'json', headers: {
        'Accept-Language': this.translateService.currentLang
      }
    }).subscribe((res) => {
      next_button.setAttribute('disabled', 'false');
      if (res['reason']) {
        window.alert(res['reason']);
      } else {
        this.snackBar.open(this.translateService.instant('Eine neue PIN wurde gesendet'), '', {duration: 2500});
      }
    });

  }
}
