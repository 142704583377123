<div [formGroup]="questionForm" class="d-flex justify-content-between mb-1" mat-dialog-title>
  <div class="d-flex align-items-center">
    <mat-icon class="material-icons-outlined mr-2">
      edit
    </mat-icon>
    <span class="fontStyle mr-3" translate>Edit question</span>
  </div>

  <div class="d-flex align-items-center">
    <!--delete-->
    <button (click)="deleteQuestion()"
            class="mr-2"
            mat-icon-button matTooltip="{{'Löschen'|translate}}">
      <mat-icon>delete_outline</mat-icon>
    </button>

    <!--all options-->
    <button [matMenuTriggerFor]="moreMenu" class="mr-2"
            mat-icon-button matTooltip="{{'Mehr'|translate}}"
            matTooltipPosition="below">
      <mat-icon class="material-icons-outlined">
        more_vert
      </mat-icon>
    </button>

    <!--close button (same as save button)-->
    <button cdkFocusInitial mat-icon-button>
      <mat-icon (click)="saveAndClose();">close</mat-icon>
    </button>

    <!-- more menu -->
    <mat-menu #moreMenu="matMenu" yPosition="below">
      <button (click)="selectMenu($event,'setRequired')" mat-menu-item>
        <span translate>Mark question required</span>
        <mat-icon [hidden]="!questionForm.get('is_required').value" class="ml-2">check</mat-icon>
      </button>
      <button (click)="selectMenu($event,'retake')" mat-menu-item>
        <span translate>Enable question retake</span>
        <mat-icon [hidden]="!questionForm.get('is_retake_allowed').value" class="ml-2">check</mat-icon>
      </button>
      <button (click)="selectMenu($event,'adddesc')" mat-menu-item>
        <span translate>Add description</span>
        <mat-icon [hidden]='!showDescription' class="ml-2">check</mat-icon>
      </button>
      <button (click)="selectMenu($event,'survey-quiz')"
              [disabled]="['ST', 'LT', 'FU'].includes(data.type)" mat-menu-item>
        <span translate>Make it Quiz</span>
        <mat-icon *ngIf="correct_option_id != 0" class="ml-2">check</mat-icon>
      </button>
    </mat-menu>
  </div>
</div>

<!-- question editor -->
<div class="mx-2" mat-dialog-content>
  <!--title & desc-->
  <div [formGroup]="questionForm" class="w-100 d-flex flex-wrap">
    <mat-form-field style="width: 90%;">
      <mat-label translate>Question</mat-label>
      <input class="text-nunito" formControlName="title"
             matInput maxlength="100"
             placeholder="{{'Add a question here' | translate}}" required>
    </mat-form-field>

    <!--file upload-->
    <div *ngIf="!data.supporting_file" class="d-flex justify-content-end align-items-end">
      <label class="cursor-pointer" for="ques-file-upload">
        <mat-icon class="mr-1 material-icons-outlined">image</mat-icon>
      </label>
      <input (change)="attachFile($event)"
             accept="image/*" hidden id="ques-file-upload"
             type="file"/>
    </div>

    <mat-form-field *ngIf="showDescription" class="w-100 mt-2">
      <mat-label translate>Beschreibung</mat-label>
      <textarea class="text-nunito" formControlName="desc"
                matInput placeholder="{{'Please write your question clearly'|translate}}">
        </textarea>
    </mat-form-field>
  </div>

  <!--ques image & delete-->
  <div *ngIf="data.supporting_file" [style.background-image]="'url(' + data.supporting_file + ')'" class="my-1"
       style="width: 300px; height: 300px; background-size: cover; background-repeat: no-repeat;">
    <button (click)="removeQuestionFile();" class="m-1 bg-white" mat-icon-button>
      <mat-icon>delete_outline</mat-icon>
    </button>
  </div>

  <!--options-->
  <div *ngIf="['SS', 'MS'].includes(data.type)" class="mt-4">
    <mat-card-subtitle *ngIf="correct_option_id" class="text-nunito">{{ 'Select correct answers' | translate }}
    </mat-card-subtitle>

    <!--SS case: radio buttons-->
    <ng-container *ngIf="data.type == 'SS'">
      <mat-radio-group [(ngModel)]="correct_option_id" class="d-flex flex-column mb-3">
        <div *ngFor="let option of allOptions; let i = index;" class="w-100 my-2d-flex flex-wrap align-items-baseline">
          <!--click-->
          <mat-radio-button [value]="option.id"></mat-radio-button>

          <!--title-->
          <mat-form-field style="width: 83%;">
            <mat-label>Option</mat-label>
            <input (change)="option.changed = true;"
                   [(ngModel)]="option.title" class="text-nunito"
                   matInput
                   maxlength="100"
                   placeholder="{{'Please provide a title' | translate}}" required>
          </mat-form-field>

          <!--add image, delete option-->
          <label [hidden]="option.supporting_file" class="cursor-pointer mr-2" for="option{{i}}">
            <mat-icon class="material-icons-outlined" style="top: 0.3em;
                      position: relative;">
              image
            </mat-icon>
          </label>

          <input (change)="attachFile($event, option.id)"
                 accept="image/*" hidden
                 id="option{{i}}"
                 name="optionFile"
                 type="file"/>

          <!--show hide desc-->
          <button (click)="showHideDesc(option.id)" class="mr-2" mat-icon-button>
            <mat-icon *ngIf="option.show_desc" class="material-icons-outlined">speaker_notes_off</mat-icon>
            <mat-icon *ngIf="!option.show_desc" class="material-icons-outlined">speaker_notes</mat-icon>
          </button>
          <button (click)="deleteOption(option.id)" [disabled]="allOptions.length <= 2" mat-icon-button>
            <mat-icon>close</mat-icon>
          </button>

          <!--reason-->
          <mat-form-field [hidden]="!option.show_desc" class="mt-2 ml-4" style="width: 83%;">
            <mat-label translate>Beschreibung</mat-label>
            <input (change)="option.changed = true;"
                   [(ngModel)]="option.reason" class="text-nunito"
                   matInput
                   maxlength="100"
                   placeholder="{{'Please write your question clearly' | translate}}">
          </mat-form-field>

          <!--image & delete image button-->
          <div *ngIf="option.supporting_file" [style.background-image]="'url(' + option.supporting_file + ')'"
               class="my-1 ml-4"
               style="width: 200px; height: 200px; background-size: cover; background-repeat: no-repeat;">
            <button (click)="removeOptionFile(option.id);" class="m-1 bg-white" mat-icon-button>
              <mat-icon>delete_outline</mat-icon>
            </button>
          </div>
        </div>
      </mat-radio-group>
    </ng-container>

    <!--MS case: checkboxes-->
    <ng-container *ngIf="data.type == 'MS'">
      <div *ngFor="let option of allOptions; let i = index;" class="my-2 w-100 d-flex flex-wrap align-items-baseline">
        <!--click-->
        <mat-checkbox (change)="option.changed = true;" [(ngModel)]="option.is_correct"></mat-checkbox>

        <!--title-->
        <mat-form-field class="ml-2" style="width: 83%;">
          <mat-label>Option</mat-label>
          <input (change)="option.changed = true;"
                 [(ngModel)]="option.title" class="text-nunito"
                 matInput
                 maxlength="100"
                 placeholder="{{'Please provide a title' | translate}}" required>
        </mat-form-field>

        <!--add image, delete option-->
        <label [hidden]="option.supporting_file" class="cursor-pointer mr-2" for="option{{i}}">
            <mat-icon class="material-icons-outlined" style="top: 0.3em;
                      position: relative;">
              image
            </mat-icon>
          </label>
        <input (change)="attachFile($event, option.id)" accept="image/*"
               hidden
               id="option{{i}}"
               name="optionFile"
               type="file"/>

        <!--show hide desc-->
        <button (click)="showHideDesc(option.id)" mat-icon-button>
          <mat-icon *ngIf="option.show_desc" class="material-icons-outlined">speaker_notes_off</mat-icon>
          <mat-icon *ngIf="!option.show_desc" class="material-icons-outlined">speaker_notes</mat-icon>
        </button>

        <!--delete option supporting file-->
        <button (click)="deleteOption(option.id)" [disabled]="allOptions.length <= 2" mat-icon-button>
          <mat-icon>close</mat-icon>
        </button>

        <!--reason-->
        <mat-form-field [hidden]="!option.show_desc" class="mt-2 ml-4" style="width: 83%;">
          <mat-label translate>Beschreibung</mat-label>
          <input (change)="option.changed = true;"
                 [(ngModel)]="option.reason" class="text-nunito"
                 matInput
                 maxlength="100"
                 placeholder="{{'Please write your question clearly' | translate}}">
        </mat-form-field>

        <!--image & delete image button-->
        <div *ngIf="option.supporting_file" [style.background-image]="'url(' + option.supporting_file + ')'"
             class="my-1 ml-4"
             style="width: 200px; height: 200px; background-size: contain; background-repeat: no-repeat;">
          <button (click)="removeOptionFile(option.id);" class="m-1 bg-white" mat-icon-button>
            <mat-icon>delete_outline</mat-icon>
          </button>
        </div>

      </div>
    </ng-container>

    <button (click)="addOption()" class="border-radius-20" mat-stroked-button>
      {{ 'Add option' | translate }}
    </button>
  </div>
</div>

<!-- save button and type selector-->
<mat-dialog-actions class="d-flex justify-content-between align-items-baseline m-2"
                    style="border-top: 1px solid lightgray;">
  <!--type dropdown-->
  <mat-form-field appearance="outline" class="text-nunito">
    <mat-select (selectionChange)="changeQuestionType();" [(ngModel)]="questionType">
      <mat-option value="SS">{{ 'Single select' | translate }}</mat-option>
      <mat-option value="MS">{{ 'Multiple select' | translate }}</mat-option>
      <mat-option value="ST">{{ 'Short answer' | translate }}</mat-option>
      <mat-option value="LT">{{ 'Long answer' | translate }}</mat-option>
      <mat-option value="FU">{{ 'File upload' | translate }}</mat-option>
    </mat-select>
  </mat-form-field>
  <!--save button-->
  <button (click)='saveAndClose();' [disabled]="!questionForm.valid || titleEmpty()" class="border-radius-20"
          mat-stroked-button>
    <span class="text-nunito" translate>Speichern</span>
  </button>

  <!--showing progress-->
  <mat-progress-bar *ngIf="showSpinner" mode="indeterminate"></mat-progress-bar>
</mat-dialog-actions>
