import { Component, OnInit, ViewChild } from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DataService } from 'src/app/services/data.service';
import { TranslateService } from '@ngx-translate/core';
import {AuthService} from "../../services/auth.service";
import { VideoManagementPopupComponent } from './video-managment-popup/video-management-popup.component';
import {VideoCardManager} from "../../models/video/video.interface";
import { UploaderChangeDialogComponent } from '../uploader-change-dialog/uploader-change-dialog.component';
import {SelectionModel} from "@angular/cdk/collections";
import {TopicBottomSheetComponent} from "../../shared/topic-bottom-sheet/topic-bottom-sheet.component";
import {MatBottomSheet} from "@angular/material/bottom-sheet";

@Component({
  selector: 'app-video-management',
  templateUrl: './video-management.component.html',
  styleUrls: ['./video-management.component.scss']
})
export class VideoManagementComponent implements OnInit {
  dataSource: MatTableDataSource<VideoCardManager>;
  selection = new SelectionModel<any>(true, []);
  displayedColumns: string[] = ['select', 'restrictions', 'title', 'full_name', 'views', 'up_votes', 'is_held_for_review'];
  search: string = "";
  filter_option: string = "published";  // can be published or draft
  authorised: boolean = false;
  showSpinner: boolean = false;
  all_videos: VideoCardManager[] = [];
  quality_admin_tooltip: string = "";

  @ViewChild(MatSort) sort: MatSort;

  constructor(private dialog: MatDialog,
              public authService: AuthService,
              private bottomSheet: MatBottomSheet,
              private dataService: DataService,
              private translateService: TranslateService,
              private snackBar: MatSnackBar) {

  }

  // filter function to search in video title
  customFilterPredicate() {
    const myFilterPredicate = (data: VideoCardManager, filter: string): boolean => {
      let searchString = filter.trim().toLowerCase();
      // search in title or email
      return data.title.toLowerCase().indexOf(searchString) != -1 ||
        data.uploader.email.toLowerCase().indexOf(searchString) != -1;
    }
    return myFilterPredicate;
  }

  loadVideos(){
    this.showSpinner = true;

    this.dataService.getURL<any>('manager/videos/').subscribe((res: VideoCardManager[]) => {
      this.all_videos = res;
      this.updateFilter();  // this will load the table data source, default to published
      this.showSpinner = false;
    }, (error) =>{
      this.showSpinner = false;
    });
  }


  ngOnInit(): void {
    // if user has rights, then load videos
    let u = this.authService.userDetails;
    this.authorised = u.is_global_manager || u.is_company_manager || u.is_department_manager ||
      u.is_team_manager || u.is_quality_manager;
    if(this.authorised){
      this.loadVideos();
    }
    this.quality_admin_tooltip = this.translateService.instant("You need Video Management rights to edit this");
  }


  auditVideo(video: VideoCardManager) {
    // always open popup
    this.dialog.open(VideoManagementPopupComponent, {
      minWidth: '50vw',
      disableClose: false,
      data: video,
    }).afterClosed().subscribe(res => {
      if (res) {
        this.loadVideos();
      }
    });
  }

  applyVideoAction(id: string = null) {
    let data: string[] = [];
    if (id) {
      // individual action
      data = [id];
    } else {
      // bulk action
      data = this.selection.selected.map(obj => obj.id);
    }
    this.bottomSheet.open(TopicBottomSheetComponent, {
      data: {
        ids: data,
        case: 'videos'
      }
    }).afterDismissed().subscribe(res => {
      if (res) {
        this.selection.clear();
        this.loadVideos();
      }
    });
  }

  applyFilter(): void {
    this.dataSource.filter = this.search.trim().toLowerCase();
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }
    this.selection.select(...this.dataSource.data);
  }

  // show either draft or published
  updateFilter(): void {
    let filtered_videos: VideoCardManager[] = [];
    if (this.filter_option === 'published') {
      filtered_videos = this.all_videos.filter(ele => !ele.is_draft);
    } else if (this.filter_option === 'drafts') {
      filtered_videos = this.all_videos.filter(ele => ele.is_draft);
    } else if (this.filter_option === 'external') {
      filtered_videos = this.all_videos.filter(ele => ele.is_external);
    } else if (this.filter_option === 'restricted') {
      filtered_videos = this.all_videos.filter(ele => ele.is_restricted);
    } else if (this.filter_option === 'featured') {
      filtered_videos = this.all_videos.filter(ele => ele.is_featured);
    } else {
      // empty
    }
    this.dataSource = new MatTableDataSource(filtered_videos);
    this.dataSource.filterPredicate = this.customFilterPredicate();
    this.dataSource.sort = this.sort;
    this.applyFilter();  // keep prev search
  }

  // change the uploader of a video
  // opens a dialog where checklist data is fetched
  // user must be a quality admin
  changeUploader(video: VideoCardManager) {
    if (!this.authService.userDetails.is_quality_manager) {
      const message = this.translateService.instant("You need Video Management rights to edit this");
      this.snackBar.open(message, '', {duration: 2500});
      return;
    }
    const dialogRef = this.dialog.open(UploaderChangeDialogComponent, {
      minWidth: '400px',
      width: '50vw',
      maxWidth: '600px',
      data: video,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // confirm and save
        if (result != video.uploader.id) {
          // change to video uploader after confirmation
          let message = this.translateService.instant("The previous and new creator");
          message += " ";
          message += this.translateService.instant("will be informed about this.");
          message += '\n\n';
          message += this.translateService.instant("Would you like to continue?");
          if (window.confirm(message)) {
            this.dataService.patchURL(`manager/videos/${video.id}/`, {'uploader': result})
              .subscribe((res) => {
                this.snackBar.open(this.translateService.instant('Erfolgreich'), '', {duration: 2500});
                this.loadVideos();
              }, (err) => {
                window.alert(err.error);
              });
          }
        }
      }
    });
  }
}
