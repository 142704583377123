<div (scrolled)="onScrollDown()"
     [fromRoot]="true" [infiniteScrollContainer]="'mat-sidenav-content'" [infiniteScrollDistance]="1"
     [infiniteScrollThrottle]="500" [scrollWindow]="false"
     class="app-friend-organisation-view px-5" infiniteScroll>

  <div class="d-flex justify-content-between m-3" style="flex-flow: wrap;">
    <div class="d-flex align-items-center" style="flex-flow: wrap; line-height: 1.5;">
      <mat-icon class="mr-1" color="primary">
        play_circle_outline
      </mat-icon>
      <span class="fontStyle mr-4" style="font-size: x-large;">
        {{'All Clypps' | translate}} {{'von' | translate}} {{sharing_company}}
      </span>
      <button (click)="backTo();" class="border-radius-20" mat-stroked-button *ngIf="mode == 'view'">
        <mat-icon class="mr-1">
          chevron_left
        </mat-icon>
        {{ "Back" | translate }}
      </button>
    </div>

    <div class="d-flex" style="flex-flow: wrap;">
      <mat-form-field appearance="outline" class="mr-2">
        <mat-label translate>Sort</mat-label>
        <mat-select (selectionChange)="sortedBy($event)" class="sort-input" value="added_on">
          <mat-option value="added_on"><span translate>Neueste Clypps</span></mat-option>
          <mat-option value="up_votes"><span translate>Beliebteste Clypps</span></mat-option>
          <mat-option value="views"><span translate>Most viewed</span></mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="outline" style="width:inherit;">
        <mat-label translate>Search for…</mat-label>
        <input #input (keyup.enter)="applyFilter()" [(ngModel)]="search_text" autocomplete="off"
               matInput placeholder="{{'Aufnahme' | translate}}">
        <button (click)="applyFilter()" class="pointer" mat-icon-button matSuffix style="color: gray">
          <mat-icon>search</mat-icon>
        </button>
      </mat-form-field>
    </div>
  </div>

  <div *ngIf="!friendVideos.length" class="no-video-msg fontNormal" translate>
    This organisation has either no published videos or all videos are restricted internally.
  </div>

  <mat-grid-list #grid (window:resize)="onResize($event)" [cols]="cols" [gutterSize]="'1rem'" class="mt-2"
                 rowHeight="320px">
    <mat-grid-tile *ngFor="let video of friendVideos">
      <app-video-card [videoObject]="video" [mode]="mode" class="VideoCardStyle"></app-video-card>
    </mat-grid-tile>
  </mat-grid-list>

  <div *ngIf="showSpinner" class="d-flex justify-content-center">
      <mat-spinner></mat-spinner>
  </div>
</div>
