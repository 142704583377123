<mat-card class="border-radius-10 text-nunito bg-quiz" style="border: 1px solid lightgray;">
  <mat-card-header>
    <mat-icon class="border-0 material-icons-outlined" color="primary"
              mat-card-avatar
              style="text-align: center;bottom: 0.1em;right:0.2em;position: relative;">
      quiz
    </mat-icon>
    <mat-card-title>{{ ques.title }}</mat-card-title>
    <mat-card-subtitle>{{ ques.desc }}</mat-card-subtitle>
  </mat-card-header>

  <!--show supporting file-->
  <ng-container *ngIf="ques.supporting_file" [ngSwitch]="getMimeType(ques.supporting_file)">
    <!-- image & links-->
    <a [href]="ques.supporting_file" target="_blank">
      <span class="" *ngSwitchCase="'other'">
        {{ getAttachedFilename(ques.supporting_file) }}
      </span>
      <img alt="supporting file for question" *ngSwitchCase="'image'" [src]="ques.supporting_file"
           class="border-radius-10 max-ques-image-dimensions">
    </a>
  </ng-container>

  <!-- show options, input, file select-->
  <mat-card-content>
    <!--show options or answer input-->
    <ng-container [ngSwitch]="ques.type">
      <!--SS-->
      <ng-container *ngSwitchCase="'SS'">
        <mat-radio-group [disabled]="hasAnsweredAlready()" [(ngModel)]="userSSValue">
          <div *ngFor="let option of options" class="d-flex flex-column my-4">
            <div class="d-flex align-items-start">
              <mat-radio-button [value]="option.id" class="text-nunito">
                {{ option.title }}
              </mat-radio-button>

              <mat-icon *ngIf="hasAnsweredAlready() && !ques.is_retake_allowed"
                        [ngStyle]="option.is_correct ? {color: 'green'} : {color: 'red'}"
                        class="ml-2 float-right" mat-list-icon>
                {{ option.is_correct ? 'check' : 'clear' }}
              </mat-icon>
            </div>

            <mat-card-subtitle *ngIf="hasAnsweredAlready()" class="ml-4">
              {{ option.reason }}
            </mat-card-subtitle>

            <!--show supporting file-->
            <ng-container *ngIf="option.supporting_file" [ngSwitch]="getMimeType(option.supporting_file)">
              <!-- image & links-->
              <a [href]="option.supporting_file" target="_blank" class="ml-4">
                <span class="" *ngSwitchCase="'other'">
                  {{ getAttachedFilename(option.supporting_file) }}
                </span>
                <img alt="supporting file for question" *ngSwitchCase="'image'" [src]="option.supporting_file"
                     class="border-radius-10 max-option-image-dimensions">
              </a>
            </ng-container>
          </div>
        </mat-radio-group>
      </ng-container>

      <!--MS-->
      <ng-container *ngSwitchCase="'MS'">
        <mat-selection-list #checkboxGroup [disabled]="hasAnsweredAlready()">
          <div *ngFor="let option of options" class="my-4 d-flex flex-column">
            <div class="d-flex align-items-start">
              <mat-list-option [checkboxPosition]="'before'"
                               [value]="option.id" class="text-nunito">
                {{ option.title }}
                <mat-icon *ngIf="hasAnsweredAlready() && !ques.is_retake_allowed"
                          [ngStyle]="option.is_correct ? {color: 'green'} : {color: 'red'}"
                          class="ml-2 float-right" mat-list-icon>
                  {{ option.is_correct ? 'check' : 'clear' }}
                </mat-icon>
              </mat-list-option>
            </div>
            <mat-card-subtitle *ngIf="hasAnsweredAlready()" class="ml-4">
              {{ option.reason }}
            </mat-card-subtitle>

            <!--show supporting file-->
            <ng-container *ngIf="option.supporting_file" [ngSwitch]="getMimeType(option.supporting_file)">
              <!-- image & links-->
              <a [href]="option.supporting_file" target="_blank" class="ml-4">
                <span class="" *ngSwitchCase="'other'">
                  {{ getAttachedFilename(option.supporting_file) }}
                </span>
                <img alt="supporting file for question" *ngSwitchCase="'image'" [src]="option.supporting_file"
                     class="border-radius-10 max-ques-image-dimensions">
              </a>
            </ng-container>
          </div>
        </mat-selection-list>

        <button (click)="answerMSQuestion(checkboxGroup.selectedOptions.selected)" class="mr-2 border-radius-20"
                [hidden]="hasAnsweredAlready()"
                mat-stroked-button>
          <span translate="Submit"></span>
        </button>
      </ng-container>

      <!--ST-->
      <ng-container *ngSwitchCase="'ST'">
        <mat-form-field class="w-100 p-2">
          <mat-label translate>Response</mat-label>
          <input [disabled]="hasAnsweredAlready()" [value]="getMyAnswer()?.text"
                 [(ngModel)]="userStLtValue"
                 matInput maxlength="100" minlength="1"
                 placeholder="{{'Start writing…'|translate}}" type="text">
        </mat-form-field>
      </ng-container>

      <!--LT-->
      <ng-container *ngSwitchCase="'LT'">
        <mat-form-field class="w-100 p-2">
          <mat-label translate>Response</mat-label>
          <textarea [disabled]="hasAnsweredAlready()" [value]="getMyAnswer()?.text"
                    matInput [(ngModel)]="userStLtValue"
                    placeholder="{{'Start writing…'|translate}}"
                    maxlength="1000" minlength="1"
                    type="text"></textarea>
        </mat-form-field>
      </ng-container>

      <!--FU-->
      <ng-container *ngSwitchCase="'FU'">
        <input (change)="fileSelected($event);" [hidden]="hasAnsweredAlready()" accept=".pdf,image/*" class="my-3"
               type="file"/>
      </ng-container>
    </ng-container>

    <div *ngIf="ques.is_required && !hasAnsweredAlready()" class="d-flex align-items-center mt-2">
      <mat-icon class="mr-2 text-danger material-icons-outlined" mat-list-icon>info</mat-icon>
      <span class="text-danger" translate>This is a required question</span>
    </div>
  </mat-card-content>

  <!--submit, correct/incorrect, retake, responses-->
  <mat-card-actions>
    <!--submit-->
    <ng-template #submitButton>
      <button (click)="submitResponse();" class="ml-2 border-radius-20" mat-stroked-button [hidden]="ques.type == 'MS'">
        <span translate="Submit"></span>
      </button>
    </ng-template>

    <!--Submitted-->
    <ng-container *ngIf="getMyAnswer() as myAnswer; else submitButton">
      <button [ngStyle]="myAnswer.is_correct ? {'color':'green','background-color:':'#ddf8dd'}:{'color':'red','background-color':'#FFD6D7'}"
              class="border-radius-20 ml-2" disabled mat-flat-button>
        <!--right-->
        <ng-container *ngIf="myAnswer.is_correct; else elseCase">
          <mat-icon class="mr-2" style="color: green;">check</mat-icon>
        </ng-container>
        <!--wrong-->
        <ng-template #elseCase>
          <mat-icon class="mr-2" style="color: red;">close</mat-icon>
        </ng-template>
        <span>{{ getSubmitLabel() }}</span>
      </button>

      <button (click)="deleteAnswer(myAnswer.id)" *ngIf="(ques.is_retake_allowed)"
              class="border-radius-20 ml-2" mat-stroked-button>
        <mat-icon class="mr-2">replay</mat-icon>
        <span translate>Retake</span>
      </button>
    </ng-container>

    <button *ngIf="isTopicCreator" [disabled]="answers.length == 0" [matBadgeHidden]="answers.length == 0"
            (click)="openResponses();" class="border-radius-20 ml-2"
            [matBadge]="answers.length" mat-button matBadgePosition="above after">
      <mat-icon class="mr-2">leaderboard</mat-icon>
      <span translate>Responses</span>
    </button>
  </mat-card-actions>

  <mat-card-footer *ngIf="getMyAnswer() as myAnswer" class="d-flex flex-column m-1">
    <ng-container *ngIf="['SS', 'MS'].includes(ques.type)">
      <mat-card-subtitle>{{ 'You answered:' | translate }}</mat-card-subtitle>
      <p>
        {{ myAnswer.text.split('---')[0] }}
      </p>
    </ng-container>
    <ng-container *ngIf="myAnswer.supporting_file">
      <!-- image & links-->
      <a [href]="myAnswer.supporting_file" target="_blank">
        <span>
          {{ getAttachedFilename(myAnswer.supporting_file) }}
        </span>
      </a>
    </ng-container>
  </mat-card-footer>
</mat-card>
